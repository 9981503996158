
import React, { Component } from 'react';
import {
    message,
    Table,
    Select,
    Drawer,
    Switch,
    Row,
    Button,
    Input,
    Form,
    Modal,
    Popconfirm,
    Tooltip,
    notification,
    Radio,
    Col,
    Divider,
    Card,
    Upload,
    Avatar,
    Typography,
    Tag,
    Slider,
    InputNumber,
    Badge,
    Skeleton,
    Checkbox

} from 'antd';

import { Link } from 'react-router-dom';

import {
    PlusOutlined,
    DeleteOutlined,
    ReloadOutlined,
    InfoOutlined,
    CheckOutlined,
    CloseOutlined,
    EyeOutlined,
    EyeInvisibleOutlined
} from '@ant-design/icons';


import { inject, observer } from 'mobx-react';

import moment from 'moment'

import axios from "axios"

import _ from "lodash"

import { withTranslation } from 'react-i18next';

import NavbarPanel from '../components/NavbarPanel';
import { LoadingGlobal } from '../components/LoadingGlobal';
import { toJS } from 'mobx';
import SystemSettings from './SystemSettings';


const queryString = require('query-string');

const { Search, TextArea } = Input;

const { Option } = Select;
const { Text } = Typography;



@inject('UserStore')
@observer
export default class CreateUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            userTypes: [
                { title: "Web Kullanıcı", value: 4 },
                { title: "Bayi", value: 3 },
                { title: "Admin", value: 2 },
                { title: "Süper Admin", value: 1 },
                { title: "Joker", value: 0 },
            ],
            workTypes: [
                { title: "Girişten", value: 0 },
                { title: "Kasadan", value: 1 },
            ],
            modalOpenCreate: false,
            credit: 0,
            percent: 0,
            workType: 1,
            page: 'list',
            userType: _.get(this.props.UserStore, "user.userType", 4) + 1,
            defaultCurrentSettings: true,
            systemSettings: null
        }
    }

    componentDidMount = async () => {

        const userType = _.get(this.props.UserStore, "user.userType", 4) + 1
        this.setState({ userType })


        const parsedHash = queryString.parse(location.search);
        const userId = _.get(parsedHash, "who")
        console.log("-------------------------------", { userId })

        let data = _.get(this.props.UserStore, "users", [])
        if (data.length == 0) {
            this.getItems()
        }

    }

    //#liste
    getItems = async () => {
        this.props.UserStore.getUsers();
    }

    resetState() {
        this.setState({
            _id: '',
            name: '',
            password: '',
            username: '',
            page: 'list',
            modalOpenCreate: false,
            defaultCurrentSettings: true
        })
    }

    reload = () => {
        this.getItems()
        this.resetState()
    }
    //#ekle
    handleCreate = async () => {

        const activeUserType = _.get(this.props.UserStore, "user.userType", 4)

        const { systemSettings } = this.props.UserStore

        let toServerData = this.state
        toServerData.systemSettings = activeUserType == 2 ? systemSettings : undefined

        try {
            await this.props.UserStore.register(toServerData)
            message.success()
            this.props.UserStore.getProfile()

            /*
            let userCredit = _.get(user, "credit", 0) - parseInt()
            this.props.UserStore.setUserLimit(80)
            */

            this.resetState()
            this.getItems()
        } catch (error) {
            console.log({ error })
            message.error(_.get(error, "response.data.message", ""))
        }

    }

    //#sil
    confirmDelete = async (item) => {
        console.log(item)
        try {
            const serverData = await this.props.ProductStore.deleteCategory(item._id)
            console.log(serverData)
            this.state.data = _.remove(this.state.data, (o) => {
                return o._id != item._id
            })
            //  console.log({items})
            //  const dd =[]
            //  dd.splice(1)
            this.setState({ data: this.state.data })

        } catch (error) {
            console.log(error)
        }

    }
    // confirmGetOut Sistemden Atmak

    confirmGetOut = async (item) => {
        try {
            const serverData = await this.props.UserStore.getOut(item._id)
            this.getItems()
            message.success()

        } catch (error) {
            console.log(error)
        }
    }

    //düzenle click
    clickEdit = (item) => {
        this.setState({
            ...item,
            page: 'edit'
        }, () => {
            this.setState({ modalOpenCreate: true })
        })
    }





    handleCancel() {
        this.resetState()
        /*
        this.setState({
          modalOpenCreate: false,
        })
      */
    }




    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    onChangeCredit = (e) => {
        this.setState({ credit: e })
    }



    onClickNetwork = (item) => {

        this.props.UserStore.getUsers(item._id).then(res => {
            console.log("onClickNetwork", { res })
            this.setState({ ...item, page: 'network' })
        });
        //  this.forceUpdate()


        /*
          const users = _.get(item, "users", [])
          if (users.length > 0) {
              this.setState({ page: 'network' })
              console.log("onClickNetwork", toJS(item))
          }
          */

    }

    onBlurPercent = (percent) => {
        if (percent > 100) {
            this.setState({
                percent: 100
            })
        }
        if (percent < 0) {
            this.setState({
                percent: 0
            })
        }

    }

    systemSettingsDidCo = (state) => {
        console.log("systemSettingsDidCo", { state })
        //    this.setState({ systemSettings: state })
    }

    render() {
        const { user } = this.props.UserStore

        const { workTypes, page } = this.state
        const { loading } = this.props.UserStore

        const { credit } = this.state;

        let userTypes = this.state.userTypes

        userTypes = _.filter(userTypes, (o) => {
            return o.value == _.get(user, "userType", 4) + 1
        })

        // kredi (online kullanıcı)
        let userCredit = _.get(user, "credit", 0)

        const activeUserType = _.get(user, "userType", 4)


        const handleFocus = (event) => event.target.select();

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16, offset: 0 },
            },
        };





        return (
            <div>
                <NavbarPanel page="createUser" />

                <Card
                    loading={loading}
                    title="Yeni Kullanıcı Ekle"
                >


                    <div>

                        <Form {...formItemLayout}>
                            <Form.Item
                                label="Kullanıcı adı"
                                required
                            >
                                <Input
                                    // readOnly={true}
                                    value={this.state.username}
                                    onChange={(e) => this.onChange(e)}
                                    name="username"
                                />
                            </Form.Item>

                            <Form.Item
                                label="Şifre"
                                required
                            >
                                <Input
                                    value={this.state.password}
                                    onChange={(e) => this.onChange(e)}
                                    type={this.state.passwordVisible ? "text" : "password"}
                                    name="password"
                                    suffix={<span>
                                        <Button size="small" shape="circle-outline"
                                            icon={this.state.passwordVisible ? < EyeOutlined /> : <EyeInvisibleOutlined />}
                                            onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}
                                        />
                                    </span>}
                                />
                            </Form.Item>



                            {
                                /*
                            
                                  <Form.Item
                                                        label="Kullanıcı Tipi"
                                                        required
                                                    >
                                                        <Select
                                                            value={this.state.userType}
                                                            onChange={(e) => this.setState({ userType: e })}
                                                            name="userType"
                                                        >
                                                            {
                                                                userTypes.map((v, i) => {
                                                                    return (
                                                                        <Option key={i} value={v.value}>{v.title}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                            
                                                        <Form.Item
                                                        label="İsim"
                                                    >
                                                        <Input
                                                            value={this.state.name}
                                                            onChange={(e) => this.onChange(e)}
                                                            placeholder={"Zorunlu değil"}
                                                            name="name"
                                                        />
                                                    </Form.Item>
                                                    */
                            }

                            {
                                (activeUserType === 2 || activeUserType === 1)
                                &&

                                <Form.Item
                                    label="Kredi"
                                //   help={`Kalan krediniz: ${userCredit - credit}`}
                                >
                                    <Row>
                                        <Col span={12}>
                                            <Slider
                                                min={0}
                                                max={activeUserType === 2 ? userCredit - 1 : undefined}
                                                onChange={(e) => this.onChangeCredit(e)}
                                                value={typeof credit === 'number' ? credit : 0}

                                            />
                                        </Col>
                                        <Col span={4}>
                                            <InputNumber
                                                min={0}
                                                //   max={userCredit}
                                                max={activeUserType === 2 ? userCredit - 1 : undefined}
                                                style={{ margin: '0 16px' }}
                                                value={credit}
                                                onChange={(e) => this.setState({ credit: e })}
                                                name="credit"
                                                onFocus={handleFocus}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            }

                            {
                                activeUserType != 1
                                &&

                                <Form.Item
                                    label="Bakiye"
                                    help="Kullanıcının bahis yapabilmesi için, başlangıç limiti"
                                >
                                    <Input
                                        onFocus={handleFocus}
                                        value={this.state.balance}
                                        onChange={(e) => this.onChange(e)}
                                        type="number"
                                        name="balance"
                                        suffix={<span>₺</span>}
                                    />
                                </Form.Item>
                            }

                            {
                                activeUserType === 2
                                &&
                                <Form.Item
                                    label="Çalışma Şekli"
                                >
                                    <Row>
                                        <Col span={12}>
                                            <Input
                                                value={this.state.percent}
                                                onFocus={handleFocus}
                                                onChange={(e) => this.onChange(e)}
                                                name="percent"
                                                type="number"
                                                max={100}
                                                onBlur={(e) => this.onBlurPercent(e.target.value)}
                                                min={0}
                                                prefix={<span>%</span>}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Select
                                                value={this.state.workType}
                                                onChange={(e) => this.setState({ workType: e })}
                                                name="workType"
                                            >
                                                {
                                                    workTypes.map((v, i) => {
                                                        return (
                                                            <Option key={i} value={v.value}>{v.title}</Option>
                                                        )
                                                    })
                                                }
                                            </Select></Col>
                                    </Row>
                                </Form.Item>
                            }

                            {
                                activeUserType === 2
                                &&

                                <Form.Item
                                    label="Sistem Ayarları"
                                >
                                    <Checkbox
                                        onClick={() => this.setState({
                                            defaultCurrentSettings: !this.state.defaultCurrentSettings
                                        })}
                                        checked={this.state.defaultCurrentSettings}
                                    // value={"1"}
                                    >Geçerli ayarları kullan</Checkbox>
                                </Form.Item>
                            }



                            {
                                !this.state.defaultCurrentSettings
                                &&
                                <SystemSettings
                                    //  systemSettingsDid={(state) => this.systemSettingsDidCo(state)}
                                    page="createUser" />
                            }



                        </Form>
                        <div
                            style={{
                                right: 0,
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={(e) => this.handleCancel(e)} style={{ marginRight: 8 }}>
                                Temizle
                </Button>
                            {
                                this.state.page == "list"
                                &&
                                <Button onClick={() => this.handleCreate()} type="primary">
                                    Kaydet
                </Button>
                            }
                        </div>

                    </div>

                </Card>



            </div >
        )
    }
}
