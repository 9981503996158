import React,{Component} from 'react'
import {Link} from 'react-router-dom'; // Link,
import Header from '../components/Header';

import {Row,Col} from "antd"


export default class Policy extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    
    render(){
        return(
            <div>

<Row style={{paddingLeft:24,paddingTop:14,paddingBottom:10,   backgroundColor: '#075E54',}}>
                          <Col span={16}>
                          <Link to="/">
                            <img style={{ height: '26px', width: 'auto', float: 'left', marginRight: '18px' }}
                                   src="https://www.piyanos.com/assets/imgs/front/piyanwbotlogo.png"
                                  alt="Logo" />
                          </Link>
               
                          </Col>

                          <Col span={8} style={{}}>
                          </Col>
              </Row>

                 <div className="row" style={{padding:"10px 50px "}}>
        <div id="soz" className="col-md-12">
          <h1 />
          <ol style={{paddingLeft: 0}}>
            <li>
              <span className="underline">Bilgilerin Kullanılması ve Korunması:</span>
              <ol>
                <li>KEVOK Internet Hizmetleri Bilişim Teknolojileri adı altında varlık gösteren https://www.piyanos.com/ isimli web sitesi, müşterilerine daha iyi hizmet verebilmek amacıyla bazı anonim bilgilerinizi kayıt altına alır. Sizi tanımamız, sizi korumamız ve kendi güvenliğimizi idame etmemiz için kullandığınız tarayıcı (browser) teknolojisi ve İZNİNİZ dahilinde ve ÖZEL OLMAYAN  bilgi/cookie/tarayıcı yeteneklerini/IP adresini belirli bir süre hıfz ediyoruz.
                  <ul>
                    <li><span className="underline">IP Adresiniz:</span> 5651 Sayılı İnternet Yasası Gereğince alınmaktadır. 123.123.123.123 gibi sayılar içerir. Kısa bir zaman sonra IP Adresi başkası tarafınca da (telekominikasyon şirketinizin politikaları ile ilgilidir, servislerimizle ilgi değilidir.) kullanılabilir ve özel gerçek anlamda bir özel bilgi değildir.</li>
                    <li><span className="underline">Tarayıcı üst bilgisi:</span> İsteseniz de istemeseniz de her girdiğiniz siteye teknik olarak ilettiğiniz bilgidir, bir sürü manasız, ilgi çekmeyen ve ÖZEL OLMAYAN teknik bilgiler içerir. Örneğin; kullandığınız tarayıcının Chrome’ mu, Explorer mı olduğunu anlamak için kullanılır, bu sayede ses yayına girebilir veya giremezsiniz. Bu da özel bilgi değildir.</li>
                    <li><span className="underline">Cookie / Çerez:</span> Sitemizi daha önce ziyaret edip etmediğinizi anlamımıza yarayan teknolojik bir yetenektir. Bunu da aynı şekilde Kullanıcı Giriş’i olan her türlü siteyle istemezseniz bile paylaşılırsınız. Ayrıca bu da özel bilgi değildir ve özel bilgilerinizi içermez.</li>
                  </ul>
                </li>
                <li>Toplanan bu kişisel bilgilerden elde edilebilecek istatistik veriler (tarayıcı tipi, coğrafi konum, yaş, cinsiyet, v.b.), dönemsel kampanya çalışmaları, e-bülten çalışmaları, müşteri profillerine yönelik özel promosyon faaliyetlerinin kurgulanması ve istenmeyen e-postaların iletilmemesine yönelik müşteri "sınıflandırma" çalışmalarında KEVOK Internet Hizmetleri Bilişim Teknolojileri adı altında varlık gösteren diğer web siteler bünyesinde kullanılabilir. (piyanwbot kurulduğu günden beri henüz böyle bi çalışma yapmamıştır. güncelleme: 29.01.2017)</li>
                <li>KEVOK Internet Hizmetleri Bilişim Teknolojileri adı altında varlık gösteren diğer web siteler, üyelik veya iletişim formlarından topladığı bilgileri, söz konusu üyenin haberi ya da aksi bir talimatı olmaksızın, üçüncü şahıslarla kesinlikle <b>paylaşmamakta</b> kullanmamakta  ve de <b>satmamaktadır</b>.</li>
                <li>KEVOK Internet Hizmetleri Bilişim Teknolojileri adı altında varlık gösteren diğer web siteler, e-posta adresleri ve üyelik formlarında istediği kişisel bilgilerin haricinde site kullanımı sırasında izlediği, ziyaretçi hareket ve tercihlerini analiz ederek yorumlamaktadır. Kişisel bilgiler içermeyen bu istatistiksel veriler, misafirlere daha özel ve etkin bir rezervasyon deneyimi yaşatmak amacıyla kullanılmaktadır.</li>
                <li>Kişi bilgileri, ancak resmi makamlarca usulü dairesinde bu bilgilerin talep edilmesi halinde ve yürürlükteki emredici mevzuat hükümleri gereğince resmi makamlara açıklama yapmak zorunda olduğu durumlarda resmi makamlara açıklanabilecektir. </li>
                <li>Müşterinin sisteme girdiği tüm bilgilere sadece Müşteri ulaşabilmekte ve bu bilgileri sadece Müşteri değiştirebilmektedir. Bir başkasının bu bilgilere ulaşması ve bunları değiştirmesi mümkün değildir. </li>
                <li>Ödeme sayfasında istenen kredi kartı bilgileriniz, siteden alışveriş yapan misafirlerimizin güvenliğini en üst seviyede tutmak amacıyla 3D Secure uygulaması ile alınmaktadır. Bu şekilde ödemeye yönelik tüm işlemlerin sitemiz ara yüzü üzerinden banka ve bilgisayarınız arasında gerçekleşmesi sağlanmaktadır. Ancak buna rağmen güvenlik ile ilgili konularda bir garanti verememektedir.</li>
                <li>İnternetin yapısı gereği bilgiler internet üzerinde yeterli güvenlik önlemleri olmasına rağmen dolaşabilir ve yetkili olmayan kişiler tarafından alınıp kullanılabilir. Bu kullanım ve kullanımdan doğacak zarar KEVOK Internet Hizmetleri Bilişim Teknolojileri adı altında varlık gösteren diğer web sitelerin sorumluluğunda değildir.</li>
                <li>Toplanan bilgiler, genel kullanıma açık olmayan güvenli bir ortamda hıfz edilir.</li>
                <li>piyanwbot'nin gerekli bilgi güvenliği önlemlerini almasına karşın, https://www.piyanos.com/ sitesine ve sisteme yapılan siber / fiziksel / sanal saldırılar sonucunda gizli bilgilerin zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda bu site (https://www.piyanos.com/), piyanwbot ve KEVOK Internet Hizmetleri Bilişim Teknolojileri adı altında varlık gösteren diğer web sitelerin herhangi bir sorumluluğu olmayacaktır.</li>
                <li>piyanwbot kullanıcısı, site ile iletişiminde kullandığı tüm metinlerde (e-Posta, fax, mektup, telefon, web adresi, web formu, iş ve ev adresi) kredi kartı bilgilerini asla yazmamalıdır. piyanwbot, bu yollarla aktarılan bilgilerin güvenliğini hiçbir koşulda garanti edemez.</li>
              </ol>
            </li>
            <li>
              <span className="underline">Farklı Web Site Bağlantıları Hakkında:</span>
              <ol>
                <li>KEVOK Internet Hizmetleri Bilişim Teknolojileri adı altında varlık gösteren diğer web siteler, web sitesindeki bağlantılar aracılığıyla ulaşacağınız üçüncü kişilere ait web sitelerinin gizlilik ilkeleri hakkında bir garanti vermez, bu nedenle kişisel olarak belirlenebilir herhangi bir bilgi vermeden önce, gittiğiniz sitelerin gizlilik yaklaşımlarını değerlendirmenizi öneririz.</li>
              </ol>
            </li>
            <li>
              <span className="underline">Genel Bilgilendirmeler:</span>
              <ol>
                <li>piyanwbot, https (SSL - 256/2048 bit şifreleme) protokolünü kullanır ve teknik altyapı olarak bir bankanın Internet sitesiyle eşit güvenlidir.</li>
                <li>piyanwbot, güvenlik konusunda son derece titiz çalışmaktadır, güvenlik uzmanlarımız tarafından düzenli olarak kontrol edilmekte ve geliştirilmektedir.</li>
                <li>piyanwbot Müşterisi, üyeliğini kendi başına sonlandırabilir / kapatabilir.</li>
                <li>piyanwbot Müşterisi, dilediği zaman profil bilgilerini değiştirebilir.</li>
                <li>piyanwbot Müşterisi, kendisinin belirlediği bir "Rumuz" ve "Şifre"ye sahip olur. (rumuz, telefon numarası veya e-posta adresidir)</li>
                <li>Rumuz, kişiye özeldir ve aynı Rumuz iki farklı üyeye verilmez.</li>
                <li>piyanwbot Müşterisi, dilediği zaman şifresini değiştirebilir.</li>
                <li>Şifre, sadece Müşteri tarafından bilinir.</li>
                <li>piyanwbot, şifre kullanımından doğacak problemlerden sorumlu değildir.</li>
                <li>Müşteri, siteye giriş yaparken kullandığı şifrenin güvenliğini sağlamakla ve siteden ayrılırken "Güvenli Çıkış" yapmakla yükümlüdür. Üyenin bu konudaki ihmalinden veya şifresini başkalarıyla paylaşmasından doğabilecek zararlardan piyanwbot sorumlu tutulamaz.</li>
                <li>Şifrenin seçimi ve korunması tamamıyla Müşterinin sorumluluğundadır.</li>
                <li>Kullanıcının, piyanwbot üyeliği gerektiren servislere bağlanabilmesi için Kullanıcı Adı ve Şifresini ilgili form kutularına yazması gereklidir; bu işlem "Müşteri Girişi" yapmak şeklinde tanımlanır.</li>
                <li>piyanwbot sitesinde iletişime geçtiğiniz, mesaj, sms, email, ileti ve her türlü işlem hareketlerinizde IP ADRESİNİZ ilerde oluşabilecek herhangi bir sorun veya adli makamların iletişimi için tarafımızdan işlem saati ile birlikte kayıt altına alınmaktadır. IP adresiniz, adli/resmi makamlar dışındaki kişiler ve kurumlarla asla paylaşılmaz.</li>
              </ol>
            </li>
            <li>
              <span className="underline">Süre:</span>
              <ol>
                <li>İş bu sözleşme kapsamında elde edilen tüm dijital anonim veriler, aksi yönde izin/talep/gereklilik olmadıkça yasal sürelerin sonunda tarafımızdan otomatik olarak tamamen silinmektedir.</li>
                <li>piyanwbot, İnternetin yapısı ve genel anlamanda İnternet ortamında asla "GÜVENLİ" kelimesinin geçerli olmadığını bildiği için, servislerinin de her zaman güvenli ve gizlilikleri sağlayacak şekilde çalışacağını teknik olarak taahhüt etmez, ama bu gizliliği elinden geldiğince gayret içerisinde sağlayamaya çalışacağını taahhüt eder. Açıklanan sebeplerle gizlilik ihlali oluşması sonucunda iş bu sözleşmenin bir hükmü olmayacağını açıkça sayfayı ziyaret eden ve bu maddeleri okumakla mükellef olan kullanıcılarına dijital ortamda yazılı olarak bildirmiştir, bu şartları kabul etmeyen kullanıcılar piyanwbot ve ona bağlı servisleri kullanmayabilirler.</li>
              </ol>
            </li>
            <li>
              <span className="underline">İletişim:</span>
              <ol>Gizlilik Sözleşmesi'yle ilgili sorularınız için aşağıdaki iletişim bilgilerini kullanarak bize ulaşabilirsiniz. 
                <li><span className="underline">e-Posta:</span> info@piyanos.com</li>
              </ol>
            </li>
          </ol>
          <hr />
        </div>
      </div>
            </div>
        )
    }
}