import React, { Component } from "react";
import { Tooltip, Row, Col, message, Table, Popconfirm, Divider, Upload, Switch, Button } from 'antd'
import { inject, observer } from 'mobx-react';
import moment from 'moment'
import _ from "lodash"
import PageHeader from "../components/PageHeader";
import { defaultImages } from "../config";
import { Link } from "react-router-dom";
import { toJS } from "mobx";





@inject('AdminStore', 'UserStore', 'RoomStore')
@observer
export default class Rooms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roomId: '6307a035bd586733d4aef8e0',// "60977d646d2f188d390d3071"
            typing:''
        };
    }
    componentDidMount = () => {
        const roomId = _.get(this.props, "match.params.id")
        const password = _.get(this.props, "match.query.password")
        console.log("roomId", roomId)
        console.log("socket", this.props.socket)

        const socket =  this.props.socket

        socket.on('room-chat message', (arg) => {
            console.log("SOCKET===room-chat message!!", arg)
           // RoomStore.sendMessage({ typing: arg.message, user: arg.user, created: arg.created });
        })
        socket.on('room_chat_message_v1', (arg) => {
            console.log("SOCKET===room_chat_message_v1!!", arg)
        })
        

        //  alert(roomId)
    }


    getRoomInfo = async () => {
        try {
            const serverData = await this.props.RoomStore.getRoomItem(this.state.roomId, "1234")
            console.log('getRoomInfo::', serverData)
        } catch (error) {
            console.log("error", error)
        }

    }

    joinRoom = (roomId) => {
        const { socket } = this.props
        socket.emit('joinRoom', { room: roomId });
        console.log("joinRoom", roomId)
    }
    leaveRoom = () => {
        const { socket } = this.props
        socket.emit('leaveRoom');

    }


    render() {

        const { RoomStore } = this.props;

        const onlineUsers = _.get(RoomStore, "activeRoom.onlineUsers", []);
        const messages = _.get(RoomStore, "activeRoom.roomMessages", []);


        const micstate = this.props.RoomStore.micstate

        return (
            <div className="messenger" >
                <div>
                                    <h1>Test Page</h1>

                    <div>
                        <input value={this.state.roomId} onChange={(e) => this.setState({ roomId: e.target.value })} />
                        <div>

                            <button onClick={() => this.getRoomInfo()}>
                                getRoomInfo
                            </button>
                            <button onClick={() => this.joinRoom(this.state.roomId)}>
                                joinRoom
                            </button>

                            <button onClick={() => this.leaveRoom(this.state.roomId)}>
                                leaveRoom
                            </button>
                        </div>

                    </div>

                    <div>

                        <button onClick={() =>
                            this.props.socket.emit('get-currentUser')
                        }
                        >
                            get current User
                        </button>


                        <button onClick={() =>
                            this.props.socket.emit('auth', { token: this.props.UserStore.token })
                        }
                        >auth</button>

                        <button onClick={() =>
                            this.props.socket.emit('logout')
                        }
                        >logout</button>

                        <button onClick={() =>
                            this.props.socket.emit('mic_actions_get')
                        }
                        >mic_actions_get</button>


                        <button onClick={() =>
                            this.props.socket.emit('room_online_users_count')
                        }
                        >room_online_users_count</button>

                        <button onClick={() =>
                            this.props.socket.emit('online_users_in_room_get')
                        }
                        >online_users_in_room_get</button>


                    </div>


                    <div style={{
                        marginTop: 50
                    }}>

                        {
                            micstate.map((v, i) => {
                                return (<button key={i}
                                    onClick={() => {
                                        this.props.socket.emit('mic_actions', { index: v.index })
                                    }}
                                >
                                    {v.index}
                                </button>)
                            })
                        }



            </div>
        </div>


                <div>
                <div>
                    <h6>Messages</h6>
                    {messages.map((v,i)=>{
                        return(
                            <div key={i}>{JSON.stringify(v)}</div>
                        )
                    })}
                </div>

                <div>
                        <input value={this.state.typing} onChange={(e) => this.setState({ typing: e.target.value })} />
                        <div>
                            <button onClick={() => {
                                  this.props.socket.emit('room_chat_message_v1', { message: this.state.typing, created:new Date() })
                            }}>
                                send
                            </button>
                        </div>

                    </div>

                </div>

            </div >
        )
    }

}







