import axios from "axios";
import { API_URL, testtoken } from "../config";

export default class ChatServices {
    constructor(token = testtoken) {
        this.axiosConfig = { headers: { Authorization: token, 'Content-Type': 'application/json' } }
    }

    getConversations() {
        const url = `${API_URL}/me/conversation`
        return axios.get(url, this.axiosConfig)
    }

    listMessageInConversation(userId, limit, offset) {
        const url = `${API_URL}/me/conversation/messages/${userId}?limit=${limit}&offset=${offset}`
        return axios.get(url, this.axiosConfig)
    }

    createMessage(data) {
        const url = `${API_URL}/me/conversation/create-message`
        return axios.post(url, data, this.axiosConfig)
    }
    deleteMessage(id) {
        return new Promise((resolve, reject) => {
            const url = `${API_URL}/me/conversation/delete-message`
            axios.post(url, { _id: id }, this.axiosConfig).then(res => {
                return resolve(res.data)
            }).catch(err => {
                return reject(err)
            })
        })
    }

    deleteAllMessage(fromUserId) {
        return new Promise((resolve, reject) => {
            const url = `${API_URL}/me/conversation/delete-message`
            axios.post(url, { fromUserId, action: 'delete-all' }, this.axiosConfig).then(res => {
                return resolve(res.data)
            }).catch(err => {
                return reject(err)
            })
        })
    }



}