
import React, { Component } from 'react';
import {
    message,
    Table,
    Select,
    Drawer,
    Switch,
    Row,
    Button,
    Input,
    Form,
    Modal,
    Popconfirm,
    Tooltip,
    notification,
    Radio,
    Col,
    Divider,
    Card,
    Upload,
    Avatar,
    Typography,
    Tag,
    Slider,
    InputNumber,
    Badge,
    Skeleton,
    Descriptions,
    Pagination
} from 'antd';

import { Link } from 'react-router-dom';


import { inject, observer } from 'mobx-react';

import moment from 'moment'

import _ from "lodash"


import { toJS } from 'mobx';

import PageHeader from '../../components/PageHeader';
import { defaultImages } from '../../config';
import MyPageHeader from '../../components/MyPageHeader';


import { UserOutlined,AlertOutlined, SearchOutlined, UserAddOutlined, CloseCircleOutlined, CloseOutlined, MessageOutlined } from '@ant-design/icons';

const queryString = require('query-string');

const { Search, TextArea } = Input;

const { Option } = Select;
const { Text } = Typography;



@inject('UserStore', 'AdminStore')
@observer
export default class User extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            modalOpenCreate: false,
            page: 'list',
            visibilityBan: false,
            visibilityTokens: false,
            pageSize: 10,
            page: 1,
            publicid:null,
            getUserTokensData:[]
        }
    }


    componentDidMount = async () => {
        const userId = _.get(this.props, "match.params.id");
        console.log("---------------", { userId })
        if (userId) {
            console.log({ userId })
        }
        else {
            this.getItems()
        }
        /*

        const params = this.props.match // this.props.match.params.redirectParam

        const parsedHash = queryString.parse(location.search);
        const userId = _.get(parsedHash, "who")
        console.log("-------------------------------", { params, userId })

        let data = _.get(this.props.UserStore, "users", [])
        if (data.length == 0) {
            this.getItems()
        }
*/
    }

    //#liste
    getItems = async () => {
        this.props.AdminStore.getUsers({ page:this.state.page, limit:this.state.pageSize,publicid:this.state.publicid});
    }

    resetState() {
        this.setState({
            _id: '',
            name: '',
            password: '',
            username: '',
            page: 'list',
            modalOpenCreate: false,
        })
    }

    reload = () => {
        this.getItems()
        this.resetState()
    }


    getUserTokens= async()=>{
        try {
            const { userDetail } = this.props.UserStore
            const tokens = await this.props.AdminStore.getUserTokens(userDetail._id)
            this.setState({getUserTokensData:tokens})
        } catch (error) {
            console.log("ERR",error)
        }
    }


    //#ekle
    handleCreate = async (action) => {
        if (action == "reset-password") {
            console.log("yeni şifre set et")
            const { password, _id } = this.state
            if (password) {
                const toData = {
                    password,
                    type: 'by-parent',
                    _id
                }
                try {
                    const sData = await this.props.UserStore.resetPassword(toData)
                    console.log({ sData })
                    message.success()
                    this.resetState()
                } catch (error) {
                    console.log({ error })
                    message.error(_.get(error, "response.data.message", ""))
                }
            }


        }
        else if ("create-user") {
            try {
                const sData = await this.props.UserStore.register(this.state)
                message.success()
                this.resetState()
                this.getItems()
            } catch (error) {
                console.log({ error })
                message.error(_.get(error, "response.data.error", ""))
            }
        }
    }

    //#sil
    confirmDelete = async (item) => {
        console.log(item)
        try {
            const serverData = await this.props.ProductStore.deleteCategory(item._id)
            console.log(serverData)
            this.state.data = _.remove(this.state.data, (o) => {
                return o._id != item._id
            })
            //  console.log({items})
            //  const dd =[]
            //  dd.splice(1)
            this.setState({ data: this.state.data })

        } catch (error) {
            console.log(error)
        }

    }
    // confirmGetOut Sistemden Atmak

    confirmGetOut = async (item) => {
        try {
            const serverData = await this.props.UserStore.getOut(item._id)
            this.getItems()
            message.success()

        } catch (error) {
            console.log(error)
        }
    }

    //düzenle click
    clickEdit = (item) => {
        this.setState({
            ...item,
            //   page: 'edit'
        }, () => {
            this.setState({ modalOpenCreate: true })
        })
    }





    handleCancel() {
        this.setState({
            modalOpenCreate: false,
            otherOperation: false,
            getUserTokensData:[],
            visibilityBan:false,
            visibilityTokens:false
        })
    }




    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeSwitchList = async (checked, item) => {
        console.log(checked);
        console.log({ item: toJS(item) });
        message.destroy()
        try {
            const serverData = await this.props.UserStore.setActive(item._id, checked)
            // checked ? message.success() : message.error()
            console.log(serverData)

        } catch (error) {
            console.log(error)
            message.warn()
        }
    }


    onClickUser = async (item) => {
        await this.props.UserStore.getUserProfile(item)
        this.setState({ modalOpenCreate: true })
    }

    onClickBanOperation = async (item) => {
        if (this.state.visibilityBan) {
            this.setState({ visibilityBan: false })
        } else {
            await this.props.UserStore.getUserBanOperations(item)
            this.setState({ visibilityBan: true })
        }

    }

    setBan = async (item, status = true) => {
        await this.props.UserStore.setUserBanOperations(item, status)
        this.setState({ visibilityBan: false })
    }

    onClickOther = async (action) => {
        const { userDetail } = this.props.UserStore
        let postData = {
            id: userDetail._id
        }
        if (action == "email") {
            //mail adresi set et
            const email = this.state.newMail
            postData.email = email
            postData.type = "email"
        }
        else if (action == "password") {
            postData.password = this.state.newPassword;
            postData.type = "password"
        }
        else if (action == "publicId") {
            postData.publicId = this.state.newPublicId;
            postData.type = "publicId"
        }
        else if (action == "gold_balance") {
            postData.gold_balance = this.state.gold_balance;
            postData.type = "gold_balance"
        }
        else if (action == "diamond_balance") {
            postData.diamond_balance = this.state.diamond_balance;
            postData.type = "diamond_balance"
        }
        else if (action == "availableRoomCount") {
            postData.availableRoomCount = this.state.availableRoomCount;
            postData.type = "availableRoomCount"
        }

        else {
            return;
        }

        try {
            const serverData = await this.props.UserStore.updateUserFromAdmin(postData)
            console.log(serverData)
            message.success()

        } catch (error) {
            console.log()
        }

    }


    render() {
        const { user } = this.props.UserStore
        const { userDetail } = this.props.UserStore


        const { loading } = this.props.AdminStore


        const handleFocus = (event) => event.target.select();


        const columns = [
            {
                title: 'ID',
                dataIndex: 'publicId',
                key: 'publicId',
            },
            {
                title: 'İsim',
                key: 'name',
                render: (text, record) => <span>
                    <Link to={`/user/${_.get(record, "_id")}`}>
                        <span>
                            <img style={{ height: 20, width: 20, borderRadius: 10 }} src={_.get(record, "avatar") ? _.get(record, "avatar") : defaultImages.userAvatar} alt={_.get(record, "username")} />
                            <strong title="isim">
                                {_.get(record, "name")}
                            </strong>
                        </span>
                    </Link>
                    {` `}
                    <Badge status={record.online ? "success" : undefined}>
                        (<span title="kullanıcı adı">{record.username}</span>)
                    </Badge>
                </span>
            },
            {
                title: 'Son Görülme',
                key: 'lastOnline',
                sorter: (a, b) => new Date(a.lastOnline) - new Date(b.lastOnline),
                render: (text, record) => <span>
                    <Tooltip title={new Date(record.lastOnline).toLocaleString()}>
                        {
                            record.lastOnline
                                ?
                                moment(record.lastOnline).fromNow()
                                :
                                <div />
                        }
                    </Tooltip>

                </span>
            },
            {
                title: 'Mail',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Oluşturma',
                key: 'created',
                sorter: (a, b) => new Date(a.created) - new Date(b.created),
                //  sorter: (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime(),
                render: (text, record) => <Tooltip title={moment(record.created).fromNow()}>
                    {new Date(record.created).toLocaleDateString()}
                </Tooltip>
            },
            {
                title: "",
                key: "action",
                render: (text, record) => {
                    return (
                        <div>
                            <Button onClick={() => this.onClickUser(record)} shape="circle" icon={<UserOutlined />} />
                            {
                                // <Link to={`/users/${record._id}`}>
                                //     <Button shape="circle" icon={<UserOutlined />} />
                                // </Link>
                            }
                        </div>
                    )
                }
            }
        ];

        return (
            <div>



                {
                    //  <div>
                    //      <div className="content1">
                    //          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    //      </div>
                    //      <div blur style={{
                    //          backgroundImage: "url(https://pistatic.piyanos.com/uploads/chatx/useravatars/2ef59823dd234afefb6e298d987198a4.jpg)",
                    //          backgroundRepeat: 'no-repeat',
                    //          backgroundSize: 'cover',
                    //          backgroundPosition: 'center',
                    //          padding: "12px",
                    //          height: '100%',
                    //          filter: 'blur(10px)',
                    //      }}>
                    //          <div style={{ textAlign: 'center' }}>
                    //              <img style={{ width: 200, height: 200, borderRadius: 100, border: "2px solid green" }} src="https://pistatic.piyanos.com/uploads/chatx/useravatars/2ef59823dd234afefb6e298d987198a4.jpg"
                    //              />
                    //              <h2 style={{ fontWeight: 400, fontSize: 24 }}>Ferdi Özer</h2>
                    //          </div>
                    //      </div>
                    //  </div>
                }

              
                    <PageHeader subTitle={this.props.AdminStore.usersListTotalCount} title="Kullanıcı Listesi" 
                    extra={
                        <Input
                        value={this.state.publicid}
                        prefix={
                            this.state.publicid && this.state.publicid.length > 0 && <Button shape="circle" onClick={() => this.setState({ publicid: null },()=>this.getItems())}>
                            <CloseOutlined />
                          </Button>
                        }
                        onPressEnter={() => this.getItems()}
                        placeholder="Kullanıcı ID"
                        onChange={e => this.setState({ publicid: e.target.value })}
                        suffix={<Button
                          onClick={() => this.getItems()}
                          shape="circle">
                          <SearchOutlined />
                        </Button>}
                      />
                    }
                    />
                    {
                        // <Button onClick={() => { this.setState({ show: !show }) }} style={{ alignSelf: 'center' }}>Yeni</Button>
                    }
             
                <Skeleton loading={false}>
                    <Table
                        bordered={true}
                        rowKey={'_id'}
                        columns={columns}
                        pagination={false}
                        size="small"
                        dataSource={this.props.AdminStore.usersList}
                        loading={loading}
                    />
                </Skeleton>

                <Row justify="end" type="flex" style={{ marginTop: 10 }}>
                    <Pagination onChange={(page, pageSize) => {
                        this.setState({page,pageSize},()=>{
                            this.getItems()
                        })
                    }}
                        current={this.state.page} 
                        defaultPageSize={this.state.pageSize}
                        total={this.props.AdminStore.usersListTotalCount}
                         />
                </Row>

                <Drawer
                    title={"Kullanıcı Bilgileri"}
                    placement="left"
                    width={720}
                    // style={{ minWidth: 400 }}
                    visible={this.state.modalOpenCreate}
                    onClose={() => this.handleCancel()}
                    bodyStyle={{ paddingBottom: 80 }}
                >


                    <div>

                        <div className="card hovercard">
                            <div className="card-background">
                                {userDetail.avatar && <img className="card-bkimg" src={userDetail.avatar} />}
                            </div>
                            <div className="useravatar">
                                {userDetail.avatar && <img style={{ border: userDetail.online ? '5px solid green' : undefined }} src={userDetail.avatar} />}
                            </div>
                            <div className="card-info">
                                <span className="card-title">{userDetail.name}</span>
                            </div>
                        </div>

                        <Descriptions>

                            <Descriptions.Item label={"İsim"}>{_.get(userDetail, "name")}</Descriptions.Item>
                            <Descriptions.Item label={"Altın Bakiyesi"}>{_.get(userDetail, "gold_balance")}</Descriptions.Item>
                            <Descriptions.Item label={"Elmas Bakiyesi"}>{_.get(userDetail, "diamond_balance")}</Descriptions.Item>
                            <Descriptions.Item label={"Kullanıcı adı"}>{_.get(userDetail, "username")}</Descriptions.Item>

                            <Descriptions.Item label={"Email"}>{_.get(userDetail, "email")}</Descriptions.Item>
                            <Descriptions.Item label={"ID"}>{_.get(userDetail, "publicId")}</Descriptions.Item>
                            <Descriptions.Item label={"Kimlik"}>{_.get(userDetail, "_id")}</Descriptions.Item>
                            <Descriptions.Item label={"Üyelik Tarihi"}>{new Date(_.get(user, "created")).toLocaleString()}</Descriptions.Item>
                            <Descriptions.Item label={"Son Görülme"}>{new Date(_.get(user, "lastOnline")).toLocaleString()}</Descriptions.Item>
                           
                        </Descriptions>
                        <Row>
                            <Button onClick={() => this.setState({ otherOperation: !this.state.otherOperation })}>
                                    Diğer İşlemler
                            </Button>
                            <Button onClick={() => this.setState({ visibilityTokens: !this.state.visibilityTokens },()=>{
                                if(this.state.visibilityTokens){
                                    this.getUserTokens()
                                }else{
                                    this.setState({ getUserTokensData:[] })
                                }
                               
                            })}>
                                    Aktif Oturum Listesi
                            </Button>
                        </Row>
                        {
                            this.state.otherOperation
                            &&
                            <div>

                                <Row className='mb-1'>
                                    <Col span={12}>
                                        <Input defaultValue={_.get(userDetail, "email")} onChange={(e) => this.onChange(e)} name="newMail" />
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={() => this.onClickOther("email")}>Mail adresi Değiştir</Button>
                                    </Col>
                                </Row>

                                <Row className='mb-1'>
                                    <Col span={12}>
                                        <Input onChange={(e) => this.onChange(e)} name="newPassword" />
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={() => this.onClickOther("password")}>Şifre Değiştir</Button>
                                    </Col>
                                </Row>

                                <Row className='mb-1'>
                                    <Col span={12}>
                                        <Input defaultValue={_.get(userDetail, "publicId")} onChange={(e) => this.onChange(e)} name="newPublicId" />
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={() => this.onClickOther("publicId")}>ID Değiştir</Button>
                                    </Col>
                                </Row>

                                <Row className='mb-1'>
                                    <Col span={12}>
                                        <Input defaultValue={_.get(userDetail, "gold_balance")} onChange={(e) => this.onChange(e)} name="gold_balance" />
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={() => this.onClickOther("gold_balance")}>Altın Bakiyesi Değiştir</Button>
                                    </Col>
                                </Row>

                                <Row className='mb-1'>
                                    <Col span={12}>
                                        <Input defaultValue={_.get(userDetail, "diamond_balance")} onChange={(e) => this.onChange(e)} name="diamond_balance" />
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={() => this.onClickOther("diamond_balance")}>Elmas Bakiyesi Değiştir</Button>
                                    </Col>
                                </Row>

                                <Row className='mb-1'>
                                    <Col span={12}>
                                        <Input defaultValue={_.get(userDetail, "availableRoomCount", 1)} onChange={(e) => this.onChange(e)} name="availableRoomCount" />
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={() => this.onClickOther("availableRoomCount")}>Oda Oluşturma Hakkı</Button>
                                    </Col>
                                </Row>

                                <Row>
                                    <Button type={this.state.visibilityBan ? "danger" : undefined} onClick={() => this.onClickBanOperation(userDetail)}>
                                        Ban İşlemleri
                                    </Button>
                                </Row>
                            </div>
                        }


                        {
                            this.state.visibilityBan
                            &&
                            <div>
                                <MyPageHeader title={"Ban İşlemleri"} noback={true} />
                                {
                                    _.get(userDetail, "bans", []).map((v, i) => {
                                        return (
                                            <Row key={i}>
                                                <Col span={12}>
                                                    <Text>{v.systemName}</Text>
                                                    {` `}
                                                    <Text type="secondary">{v.systemVersion}</Text>
                                                    {` `}
                                                    <Text type="success">{moment(v.created).fromNow()}</Text>
                                                </Col>
                                                <Col span={8}>
                                                    <p>
                                                        {v.ip}-
                                                        {v.deviceId}
                                                    </p>
                                                </Col>
                                                <Col span={4}>
                                                    {
                                                        v.ban
                                                            ?
                                                            <div>
                                                                <Text type="warning">Banlanmış</Text>
                                                                {` `}
                                                                <Button onClick={() => this.setBan(v, false)} size="small" icon={<AlertOutlined />} shape="circle" title="Banı Kaldır" />
                                                            </div>
                                                            :
                                                            <Button onClick={() => this.setBan(v)}>Banla</Button>
                                                    }
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                                <Row>
                                    <Col span={12}></Col>
                                    <Col span={8}></Col>
                                    <Col span={4}></Col>
                                </Row>
                            </div>
                        }




{
                            this.state.visibilityTokens
                            &&
                            <div>
                                <PageHeader title={"Oturum Listesi"} subTitle={this.state.getUserTokensData.length} noback extra={
                                     <Button type="danger" onClick={async() =>{
                                        try {
                                            await this.props.AdminStore.removeUserAllTokens(userDetail._id)
                                            this.setState({
                                                getUserTokensData:[]
                                            })
                                        } catch (error) {
                                            console.log("ERR",error)
                                        }
                                    }}>Tümünü Sil</Button>
                                }/>
                                {
                                    this.state.getUserTokensData.map((v, i) => {
                                        return (
                                            <Row key={i} style={{marginBottom:10}}>
                                                <Col span={12}>
                                                    <Text>{v._id}</Text>
                                                    {` `}
                                                    <Text type="success">{moment(v.created).fromNow()}</Text>
                                                </Col>
                                                <Col span={4}>
                                                <Button type="danger" onClick={async() =>{
                                                    try {
                                                        await this.props.AdminStore.removeUserTokens(v._id)
                                                        this.setState({
                                                            getUserTokensData:this.state.getUserTokensData.filter(ve=>ve._id!=v._id)
                                                        })
                                                    } catch (error) {
                                                        console.log("ERR",error)
                                                    }
                                                }}>Sil</Button>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </div>
                        }



                    </div>

                </Drawer>

            </div >
        )
    }
}
