import { PageHeader } from "antd";
import React from "react";

export default ({ title, subTitle, noback }) => {
    return (
        <PageHeader
            style={{ paddingLeft: 0, }}
            onBack={noback ? undefined : () => window.history.back()}
            title={title}
            subTitle={subTitle}
        />
    )
}