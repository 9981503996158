
import { observable, action, computed, toJS, autorun } from 'mobx';

const storageKey = "token"
export default class Auth {

    constructor() {
        this.getToken();
    }
    @observable token = null
    @action async saveToken(value) {
        try {
            await localStorage.setItem(storageKey, value)
        } catch (e) {
            // saving error
        }
    }

    @action async removeToken() {
        try {
            await localStorage.removeItem(storageKey)
        } catch (e) {
            // error reading value
        }
    }

    @action async getToken() {
        if (!this.token) {
            try {
                const value = await localStorage.getItem(storageKey)
                if (value !== null) {
                    this.token = value
                }
            } catch (e) {
                console.log("Auth getToken::", e)
            }
        }
    }

    @action async myToken() {
        return localStorage.getItem(storageKey)
    }

    @computed get returnToken() {
        return this.token
    }

}


