

import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { inject, observer } from 'mobx-react';

import Header from "./Header";
import Footer from "./Footer"

import Loading from "./Loading"

import { ConfigProvider } from 'antd';
import { Layout, Menu, Dropdown, message, Button, BackTop } from 'antd';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';

import { getDefaultLanguage, primaryColor, siteInfo } from "../config";

import localeEN from 'antd/es/locale/en_US'
import localeTR from 'antd/es/locale/tr_TR'
import { toJS } from "mobx";
import { goPage } from "../historyConfig";



const { SubMenu } = Menu;
const { Content, Sider } = Layout;



@inject('UserStore', 'AdminStore')
@observer
class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            logged: false,
            collapsed: false,
            authOk: true
        }
    }

    componentDidMount = () => {
        console.log("cmd Container")

        const currentUser = this.props.UserStore.user;

        console.log({ gg: toJS(currentUser) })

        if (_.get(currentUser, "userType") == 2) {
            this.props.UserStore.getUsers();
            this.props.AdminStore.init();
        }
        else {
            this.setState({ authOk: false })
            // window.opener = null;
            // window.open("", "_self", "");
            // window.close();
        }
        this.getProfile()
    }


    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };


    // TODO !!
    getProfile = async () => {
        try {
            this.setState({ loading: true })
            let profile = await this.props.UserStore.getProfile()
            /////////// odaya gir
            const apikey = _.get(profile, "data.user.apikey")
            const token = _.get(profile, "data._id")
            //soket odaya koy
            //   joinRoom(token,apikey)
            this.setState({ logged: true })
        } catch (error) {
            console.log({ error })
            this.props.UserStore.removeToken()
            goPage("login")
            this.setState({ logged: false })
        }
        finally {
            this.setState({ loading: false })
        }
    }


    render() {
        const mainLocale = getDefaultLanguage() == "en" ? localeEN : localeTR
        const { loading } = this.state
        const token = this.props.UserStore.token

        const { children, user } = this.props
        const { collapsed } = this.state;

        if (loading) {
            return (<Loading />)
        }
        else {
            return (
                <ConfigProvider locale={mainLocale} >
                    <Layout className="main-layout">
                        {
                            // (!token || !this.state.authOk) && <Redirect to="/login" />
                            !token && <Redirect to="/login" />
                        }


                        <Sider
                            //    width={256}
                            width={220}
                            onBreakpoint={broken => {
                                console.log(broken);
                            }}
                            onCollapse={(collapsed, type) => {
                                console.log(collapsed, type);
                            }}
                            trigger={null}
                            collapsible
                            collapsed={collapsed}

                            style={{
                                overflow: 'auto',
                                // backgroundColor: primaryColor
                            }}

                        >
                            <div
                                style={{ backgroundColor: 'rgba(255,255,255,0)', alignItems: 'center', paddingTop: 20, marginBottom: 10 }} className="brand">
                                <div className={'logo'}>
                                    <img style={{ height: 40, }} className={'logoMaskot'} alt={siteInfo.siteName} src={collapsed ? siteInfo.logoUrl : siteInfo.logoWhiteUrl} />
                                </div>
                            </div>

                            <Menu style={{
                                // backgroundColor: primaryColor
                            }}
                                theme="dark"
                                mode="inline"
                                defaultSelectedKeys={['home']}>
                                <Menu.Item key="home">
                                    <Link to="/admin/panel">
                                        <span className="nav-text">Kontrol Paneli</span>
                                    </Link>
                                </Menu.Item>


                                <Menu.Item key="users">
                                    <Link to="/admin/users">
                                        <span className="nav-text">Kullanıcılar</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="search">
                                    <Link to="/search">
                                        <span className="nav-text">Kullanıcı Ara</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="rooms">
                                    <Link to="/admin/rooms">
                                        <span className="nav-text">Odalar</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="gifts">
                                    <Link to="/admin/gifts">
                                        <span className="nav-text">Hediyeler</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="slider">
                                    <Link to="/admin/slider">
                                        Slider
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="feedback">
                                    <Link to="/admin/feedback">
                                        Geri Bildirimler
                                    </Link>
                                </Menu.Item>

                                {
                                    // <Menu.Item key="push-notification">
                                    //     <Link to="/admin/push-notification">
                                    //         Bildirim Gönder
                                    //     </Link>
                                    // </Menu.Item>
                                }

                                <Menu.Item key="financial">
                                    <Link to="/admin/financial">
                                        Finansal
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="profile">
                                    <Link to="/admin/profile">
                                        Profil
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="settings">
                                    <Link to="/admin/settings">
                                        Ayarlar
                                    </Link>
                                </Menu.Item>

                                {
                                    // <Menu.Item style={{ backgroundColor: 'rgba(255,255,255,0.1)' }} key="messenger">
                                    //     <Link to="/messenger">
                                    //         <span style={{ fontWeight: 'bold' }} className="nav-text">CHAT</span>
                                    //     </Link>
                                    // </Menu.Item>
                                }

                            </Menu>
                        </Sider>
                        <Layout>
                            <div style={{ width: '100%' }}><Header fixed={this.props.headType || undefined} /></div>

                            <Content
                                className="site-layout-background"
                                style={{
                                    // margin: '24px 16px',
                                    padding: 24,
                                    // minHeight: 360,
                                    minHeight: 560,
                                }}
                            >
                                <div style={{ padding: 24, background: '#fff' }}>
                                    {children}
                                </div>
                            </Content>
                            <Footer />

                        </Layout>
                    </Layout>
                </ConfigProvider >
            )
        }
    }
}

export default withTranslation()(Container)
