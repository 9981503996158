import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export function LoadingGlobal({ loading = false }) {
    const antIcon = <LoadingOutlined
        style={{ fontSize: 34 }}
        spin />;
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
                position: 'fixed',
                background: 'rgba(0,0,0,.6)',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 10000,
                display: loading ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Spin indicator={antIcon} />
        </div>
    )
}
