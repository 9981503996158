
import io from 'socket.io-client'

import { apiBaseUrl } from "./config"

// socket bağlantı işlemleri
var React = require('react');
var createReactClass = require('create-react-class');

export default createReactClass({
  componentWillMount() {
    // var socket =io.connect(apiBaseUrl,{timeout:0});  // ESKİ
    //  var socket =io.connect(apiBaseUrl,{upgrade:false});
    //["polling", "websocket"]
    const socketConfig = {
      upgrade: false,
      // transports: ["websocket"]
    }
    var socket = io.connect(apiBaseUrl, socketConfig);
    this.setState({ socket: socket });
  },

  render() {
    return (
      <div>
        {
          React.cloneElement(this.props.children, { socket: this.state.socket })
        }
      </div>
    )
  }
})