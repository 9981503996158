import React, { Component } from "react";
import {
    message,
    Table,
    Select,
    Drawer,
    Switch,
    Row,
    Button,
    Input,
    Form,
    Modal,
    Popconfirm,
    Tooltip,
    notification,
    Radio,
    Col,
    Divider,
    Card,
    Upload,
    Avatar,
    Typography,
    Tag,
    Slider,
    InputNumber,
    Badge,
    Skeleton,
    Descriptions,
    Pagination

} from 'antd'
import { inject, observer } from 'mobx-react';
import moment from 'moment'
import _ from "lodash"
import PageHeader from "../../components/PageHeader";
import { cdnConfig, defaultImages, siteInfo } from "../../config";
import { Link } from "react-router-dom";
import { toJS } from "mobx";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import {
    PlusOutlined,
    DeleteOutlined,
    ReloadOutlined,
    UploadOutlined,
    MenuOutlined,
    MoreOutlined,
    LinkOutlined,
    ArrowRightOutlined,
    InfoOutlined,
    UpOutlined
}
    from '@ant-design/icons';
import SearchUsers from "./components/SearchUsers";

const { Option } = Select;

@inject('AdminStore', 'UserStore', 'RoomStore')
@observer
export default class Rooms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: null,
            modalOpenCreate: false,
            visibilityBan: false,
            showRoomBg: false,
            blockedTimes: [
                { title: "5 Dakika", endTime: moment(new Date()).add(5, 'm').toDate() },
                { title: "1 Saat", endTime: moment(new Date()).add(1, 'h').toDate() },
                { title: "24 Saat", endTime: moment(new Date()).add(24, 'h').toDate() },
                { title: "7 gün", endTime: moment(new Date()).add(7, 'd').toDate() },
                { title: "Kalıcı Olarak Engelle (Süresiz)", endTime: moment(new Date()).add(5, 'y').toDate() },
            ],
            selectedBlockedTimesIndex: 0,
            roompassword: '',
            pageSize: 10,
            page: 1
        };
    }
    componentDidMount = () => {
        this.itemList();
        //  document.title = "WEB PANEL!!"
    }


    itemList = () => {
        this.props.AdminStore.getRooms(this.state.page, this.state.pageSize)

        //etiketleri getir
        if (this.props.AdminStore.tagList.length == 0) {
            this.props.AdminStore.getTagList()
        }


        // setTimeout(() => {
        //     ///// TOPLU ISLEM  --  /////////////////
        //     const data = _.get(this.props, "AdminStore.rooms", [])
        //     data.map(v => {
        //         this.props.AdminStore.updateRoom(v._id, { action: "active", value: true })
        //     })
        //     ///////////////////////    /////////////
        // }, 5000)
    }

    getRoomItem = async (id) => {
        try {
            const item = await this.props.RoomStore.getRoomDetail(id)
            item.bg_full_url = `${cdnConfig.files_url}/roombg/${item?.bg_image?.name}`
            this.setState({
                modalOpenCreate: true,
                item
            })
        } catch (error) {
            console.log("[ERROR]", error)
        }

    }


    onClickOther = async (action) => {
        console.log("onClickOther:action", action)
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleCancel() {
        this.setState({
            modalOpenCreate: false,
            roompassword: ''
        })
    }


    delete = (item) => {
        this.props.AdminStore.deleteRoom(item._id)
    }



    onChangeSwitchList = (value, id, key) => {
        this.props.AdminStore.updateRoom(id, { action: key, value })
    }

    plusMinusMember = async (memberId) => {
        try {
            const response = (await this.props.AdminStore.updateRoom(this.state.item?._id, { action: "plusMinusMember", value: memberId })).data

            // console.log("response::", response) // currentMembers
            // const activeRoom = this.state.item
            const members = response.currentMembers || []
            // activeRoom.members = members
            // this.setState({ item: this.state.item })
            // 
            this.getRoomItem(this.state.item?._id)
            this.props.AdminStore.updateRoomMembers(this.state.item?._id, members)

        } catch (error) {
            console.log("[ERROR]", error)
        }

        /*
        updateRoom

          let currentMembers = currentRoom.members || []
                    const fIndex = currentMembers.findIndex(v => `${v}` == `${memberObjId}`)
                    if (fIndex == -1) {
                        currentMembers.push(memberObjId)
                    }
                    else {
                        currentMembers = currentMembers.splice(fIndex, 1)
                    }
                    console.log("currentMembers", currentMembers)
                    */
    }


    plusMinusAdmins = async (userId) => {
        try {
            const response = (await this.props.AdminStore.updateRoom(this.state.item?._id, { action: "plusMinusAdmin", value: userId })).data
            this.getRoomItem(this.state.item?._id)
        } catch (error) {
            console.log("[ERROR]", error)
        }
    }

    plusMinusBlockedUsers = async (userId) => {

        Modal.confirm({
            title: 'Emin misiniz?',
            content: <span>
                {
                    this.state.blockedTimes.map((v, i) =>
                        <Button block key={i} onClick={() => this.setState({ selectedBlockedTimesIndex: i })}
                            type={this.state.selectedBlockedTimesIndex == i ? 'primary' : undefined}
                        >
                            {v.title}
                        </Button>)
                }
            </span>,
            okText: 'Evet',
            cancelText: 'İptal',
            onOk: async () => {
                console.log("!!!")

                try {
                    const myTime = this.state.blockedTimes[this.state.selectedBlockedTimesIndex]?.endTime
                    const trClockFormated = moment(myTime).add(3, 'h').toDate()
                    const updateData = {
                        action: "plusMinusBlockedUsers",
                        value: userId,
                        endTime: trClockFormated
                    }
                    const response = (await this.props.AdminStore.updateRoom(this.state.item?._id, updateData)).data
                    this.getRoomItem(this.state.item?._id)
                } catch (error) {
                    console.log("[ERROR]", error)
                }

            },
            onCancel: () => { }
        });



    }

    plusMinusTagsFromServer = async (tagId) => {
        try {
            const response = (await this.props.AdminStore.updateRoom(this.state.item?._id, { action: "plusMinusTags", value: tagId })).data
            console.log("response:plusMinusTags:", response)
        } catch (error) {
            console.log("[ERROR]", error)
        }
    }
    clearTagsFromServer = async () => {
        try {
            const response = (await this.props.AdminStore.updateRoom(this.state.item?._id, { action: "clearTags", value: [] })).data
            console.log("response:plusMinusTags:", response)
        } catch (error) {
            console.log("[ERROR]", error)
        }
    }
    setMicCountFromServer = async (count) => {
        try {
            const response = (await this.props.AdminStore.updateRoom(this.state.item?._id, { action: "setMicstateCount", value: count })).data
            console.log("response:setMicCountFromServer:", response)
            this.getRoomItem(this.state.item?._id)
        } catch (error) {
            console.log("[ERROR]", error)
        }
    }

    setMicstateBlockedFromServer = async (value) => {
        try {
            const response = (await this.props.AdminStore.updateRoom(this.state.item?._id, { action: "setMicstateBlocked", value })).data
            console.log("response:setMicstateBlockedFromServer:", response)
            this.getRoomItem(this.state.item?._id)
        } catch (error) {
            console.log("[ERROR]", error)
        }
    }


    setMicCount = (value) => {
        console.log("setMicCount:::", value)
        this.setMicCountFromServer(value)
    }

    onSavePrivate = async () => {
        try {
            const postData = {
                action: "setIsPrivate",
                value: this.state.isPrivate,
                roompassword: this.state.roompassword
            }
            // console.log('postData:', postData)
            // return;
            if (postData.value && !postData.roompassword) {
                message.error("Şifre girişi olmayan özel oda olamaz!. Şifre girip tekrar deneyiniz")
                return;
            }
            const response = (await this.props.AdminStore.updateRoom(this.state.item?._id, postData)).data
            console.log("response:onSavePrivate:", response)
            this.getRoomItem(this.state.item?._id)
        } catch (error) {
            console.log("[ERROR]", error)
        }
    }





    render() {

        const data = _.get(this.props, "AdminStore.rooms", [])
        const tagList = _.get(this.props, "AdminStore.tagList", [])

        const tagListDom = [];
        tagList.map((v, i) => {
            //console.log('VVV', toJS(v))
            tagListDom.push(<Option value={v._id} key={i}>{v.title}</Option>);
        })


        const { loading } = this.props.AdminStore
        const { item } = this.state

        const members = _.get(this.state.item, 'members', []);
        const followers = _.get(this.state.item, 'followers', []);
        const admins = _.get(this.state.item, 'admins', []);
        const blockedUsersObj = _.get(this.state.item, 'blockedUsersObj', []);



        const columnsUsers = (type) => [
            {
                title: '',
                key: 'name',
                render: (text, record) =>
                    <span>
                        <Avatar src={record.avatar ? record.avatar : defaultImages.userAvatar} />
                    </span>,
            },
            {
                title: '',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '',
                key: 'operation',
                render: (text, record) =>
                    <span>
                        {type == "members" && <Button onClick={() => this.plusMinusMember(record._id)} type="danger" size="small">Çıkar</Button>}
                        {type == "admins" && <Button onClick={() => this.plusMinusAdmins(record._id)} type="danger" size="small">Adminliği Al</Button>}
                        {type == "bannedUsers" && <Button onClick={() => this.plusMinusBannedUsers(record._id)} type="danger" size="small">Engeli Kaldır</Button>}

                    </span>,
            },

        ]




        const columns = [
            {
                title: 'Başlık',
                key: 'title',
                sorter: (a, b, c) => {
                    if (c == "ascend") {
                        return a.avatar ? true : false
                    }
                    else if (c == "descend") {
                        return a.avatar ? true : false
                    }
                    else {
                        return a.avatar ? false : true
                    }

                },
                render: (text, record) =>
                    <span onClick={() => this.getRoomItem(record._id)} className="cursor-p">
                        <span style={{ marginRight: 8 }}>
                            <img style={{ height: 60, width: 60, borderRadius: 30 }} src={record.avatar ? record.avatar : defaultImages.roomAvatar} alt={_.get(record, "user.username")} />
                        </span>
                        {_.get(record, "title", "")}
                    </span>,
            },
            {
                title: 'Açıklama',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Kullanıcı',
                key: 'user',
                render: (text, record) =>
                    <span>
                        <Link to={`/user/${_.get(record, "user._id")}`}>
                            <span>
                                <img style={{ height: 20, width: 20, borderRadius: 10 }} src={_.get(record, "user.avatar") ? _.get(record, "user.avatar") : defaultImages.userAvatar} alt={_.get(record, "user.username")} />
                                {_.get(record, "user.name")}
                            </span>
                        </Link>
                    </span>,
            },
            {
                title: 'Üyeler',
                key: 'members',
                render: (text, record) =>
                    <span>
                        {record.members?.length}
                    </span>,
            },
            {
                title: 'Oluşturma',
                key: 'created',
                sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
                render: (text, record) => <Tooltip title={moment(record.created).fromNow()}>
                    {new Date(record.created).toLocaleDateString()}
                </Tooltip>
            },
            {
                title: 'Anasayfa',
                key: 'inhome',
                sorter: (a, b) => a.inhome || b.inhome,
                render: (text, record) =>
                    <span>
                        <Switch defaultChecked={record.inhome} onChange={(checked) => this.onChangeSwitchList(checked, record._id, "inhome")} checkedChildren="Aktif" unCheckedChildren="Pasif" />
                    </span>
            },
            {
                title: 'Favori',
                key: 'infavorite',
                sorter: (a, b) => a.infavorite || b.infavorite,
                render: (text, record) =>
                    <span>
                        <Switch defaultChecked={record.infavorite} onChange={(checked) => this.onChangeSwitchList(checked, record._id, "infavorite")} checkedChildren="Aktif" unCheckedChildren="Pasif" />
                    </span>
            },
            {
                title: 'Aktif',
                key: 'active',
                sorter: (a, b) => a.active || b.active,
                render: (text, record) =>
                    <span>
                        <Switch defaultChecked={record.active} onChange={(checked) => this.onChangeSwitchList(checked, record._id, "active")} />
                    </span>
            },
            {
                title: 'İşlem',
                key: '3',

                render: (text, record) =>
                    <span>

                        <Popconfirm okText="Evet" cancelText="Hayır" title="Silmek istediğinize emin misiniz ?" onConfirm={() => this.delete(record)}>
                            <Button size='small' type='danger'>
                                Sil
                            </Button>
                        </Popconfirm>
                        <Divider type="vertical" />

                        <Button onClick={() => this.getRoomItem(record._id)}
                            size='small' icon={<InfoOutlined />} />

                        {
                            // <Link to={`/rooms/${record._id}`}>
                            //     <Button size='small' icon={<ArrowRightOutlined />} />
                            // </Link>
                        }

                    </span>
                ,
            },
        ];

        return (
            <div>
                <PageHeader subTitle={this.props.AdminStore.roomListTotalCount} title="Oda Listesi" />
                <Table
                    rowKey={'_id'}
                    pagination={false}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                /**  scroll={{ x: 1300 }} */
                />



                <Row justify="end" type="flex" style={{ marginTop: 10 }}>
                    <Pagination onChange={(page, pageSize) => {
                        this.setState({ page, pageSize }, () => {
                            this.props.AdminStore.getRooms(page, pageSize);
                        })
                    }}
                        current={this.state.page}
                        defaultPageSize={this.state.pageSize}
                        total={this.props.AdminStore.roomListTotalCount}
                    />
                </Row>




                <Drawer
                    title={"Oda Detayı"}
                    placement="left"
                    width={720}
                    // style={{ minWidth: 400 }}
                    visible={this.state.modalOpenCreate}
                    onClose={() => this.handleCancel()}
                    bodyStyle={{ paddingBottom: 80 }}
                >


                    <div>

                        <div className="card hovercard">
                            <div className="card-background">
                                {item?.avatar && <img className="card-bkimg" src={item.avatar} />}
                            </div>
                            <div className="useravatar">
                                {item?.user?.avatar && <img onClick={() => this.setState({ lightboxImage: item?.user?.avatar, isOpen: true })} src={item?.user?.avatar} />}
                            </div>

                            <div className="card-info">
                                <span className="card-title">{item?.user?.name}</span> |
                                <span className="card-description">Oda Sahibi</span>
                            </div>
                        </div>

                        <Descriptions bordered layout="vertical">
                            <Descriptions.Item label={"Oda Adı"}>
                                {item?.title}
                            </Descriptions.Item>
                            <Descriptions.Item label={"Oda Açıklaması"}>
                                {item?.description}
                            </Descriptions.Item>
                            <Descriptions.Item label={"ID"}>{_.get(item, "publicId")}</Descriptions.Item>
                            <Descriptions.Item label={"Arkaplan"}>
                                <img className="pointer" onClick={() => this.setState({ lightboxImage: item?.bg_full_url, isOpen: true })} style={{ height: 50 }} srcSet={item?.bg_full_url} />
                            </Descriptions.Item>
                            <Descriptions.Item label={"Kapak"}>
                                <img className="pointer" onClick={() => this.setState({ lightboxImage: item?.avatar, isOpen: true })} style={{ height: 50 }} srcSet={item?.avatar} />
                            </Descriptions.Item>
                            <Descriptions.Item label={"Online Sayısı"}>{_.get(item, "onlineUserCount")}</Descriptions.Item>
                            <Descriptions.Item label={"Kimlik"}>{_.get(item, "_id")}</Descriptions.Item>
                            <Descriptions.Item label={"Gizlilik"}>{item?.isPrivate ? 'Şifreli Oda' : 'Herkese Açık Oda'}</Descriptions.Item>

                        </Descriptions>

                        <div>

                            <Row gutter={[16, 16]}>
                                <Col span={24} >
                                    <PageHeader title="Etiketler" noback />
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Etiket seç"
                                        defaultValue={this.state?.item?.tags?.map(v => v._id) || []}
                                        //defaultValue={['a10', 'c12']}
                                        // onChange={(value) => this.handleChangeSelect(value)}
                                        onSelect={(value) => {
                                            console.log("Select:onSelect", value)
                                            this.plusMinusTagsFromServer(value)
                                        }}
                                        onDeselect={(value) => {
                                            console.log("Select:onDeselect", value)
                                            this.plusMinusTagsFromServer(value)
                                        }}
                                        onClear={() => {
                                            this.clearTagsFromServer()
                                        }}
                                    >
                                        {tagListDom}
                                    </Select>
                                </Col>
                            </Row>


                            <Row gutter={[16, 16]} >
                                <Col span={24} >
                                    <PageHeader
                                        subTitle={"Şifreli giriş"}
                                        title="Gizlilik Ayarı"
                                        noback
                                        extra={<>
                                            <Switch
                                                defaultChecked={item?.isPrivate}
                                                checkedChildren="Gizli"
                                                unCheckedChildren="Açık"
                                                onChange={(checked) => this.setState({ isPrivate: checked })}
                                            />
                                        </>}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Input value={this.state.roompassword} name="roompassword" onChange={(e) => this.onChange(e)}
                                        placeholder="Oda giriş şifresini buraya girin" />
                                    {
                                        item?.isPrivate
                                        &&
                                        <small>
                                            Geçerli şifre: {` ${item?.password}`}
                                        </small>
                                    }

                                </Col>
                                <Col span={12}>
                                    <Button
                                        onClick={() => this.onSavePrivate()}
                                    >Kaydet</Button>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} >
                                <Col span={24} >
                                    <PageHeader title="Mikrofon" noback />
                                </Col>
                                <Col span={12} >

                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Mikrofon Sayısı"
                                        defaultValue={this.state?.item?.micstate?.length || 1}
                                        onChange={(value) => {
                                            this.setMicCount(value)
                                        }}
                                    >
                                        {
                                            new Array(10).fill(10).map((v, i) => <Option value={i + 1} key={i}>{i + 1}</Option>)
                                        }
                                    </Select>
                                </Col>
                                <Col span={12}>
                                    {
                                        this.state?.item?.micstate?.map((v, i) =>
                                            <Tooltip key={i} title={v.isBlocked ? 'Kilitli mikrofon' : 'Açık mikrofon'}>
                                                <Button type={v.isBlocked ? 'danger' : 'primary'}
                                                    onClick={() => {
                                                        this.setMicstateBlockedFromServer(v.index)
                                                    }}
                                                    style={{
                                                        marginRight: 10,
                                                        marginBottom: 10,
                                                    }}>
                                                    <UpOutlined color={'FFF'} />
                                                </Button>
                                            </Tooltip>
                                        )

                                    }
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} >
                                <Col span={12} style={{ paddingLeft: 5 }}>
                                    <PageHeader title="Oda Üyeleri" noback subTitle={members.length} />
                                    <Table
                                        showHeader={false}
                                        rowKey={'_id'}
                                        columns={columnsUsers("members")}
                                        pagination={false}
                                        size="small"
                                        dataSource={members}
                                    />
                                </Col>
                                <Col span={12} style={{ paddingLeft: 5 }}>
                                    <PageHeader
                                        title="Oda Takipçileri"
                                        subTitle={followers.length}
                                        noback

                                    />
                                    <Table
                                        showHeader={false}
                                        rowKey={'_id'}
                                        columns={columnsUsers("followers")}
                                        pagination={false}
                                        size="small"
                                        dataSource={followers}
                                    />
                                </Col>

                                <Col span={12} style={{ paddingLeft: 5 }}>
                                    <PageHeader title="Oda Adminleri" noback subTitle={admins.length} />
                                    <Table
                                        showHeader={false}
                                        rowKey={'_id'}
                                        columns={columnsUsers("admins")}
                                        pagination={false}
                                        size="small"
                                        dataSource={admins}
                                    />
                                </Col>

                                <Col span={12} style={{ paddingLeft: 5 }}>
                                    <PageHeader title="Engellenen Kullanıcılar" noback subTitle={blockedUsersObj.length} />
                                    <Table
                                        showHeader={false}
                                        rowKey={'_id'}
                                        columns={columnsUsers("bannedUsers")}
                                        pagination={false}
                                        size="small"
                                        dataSource={blockedUsersObj}
                                    />
                                </Col>

                                <Col span={24} style={{ paddingRight: 5 }}>
                                    <SearchUsers
                                        removeBorder
                                        //  noDisplayIsEmpty
                                        placeholder="Kullanıcı ara.."
                                        roomItem={item}
                                        plusMinusMember={(id) => this.plusMinusMember(id)}
                                        plusMinusAdmins={(id) => this.plusMinusAdmins(id)}
                                        plusMinusBlockedUsers={(id) => this.plusMinusBlockedUsers(id)}
                                    />
                                </Col>
                            </Row>

                        </div>



                    </div>

                </Drawer>



                {this.state.isOpen && (
                    <Lightbox
                        // mainSrc={images[photoIndex]}
                        // mainSrc={item?.bg_full_url}
                        mainSrc={this.state.lightboxImage}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}


            </div>
        )
    }

}







