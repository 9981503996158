import React from 'react';
import { siteInfo } from '../../../config';

import './style.css';

export default function NotSelectConversation({ title }) {
    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                margin: 'auto',
                // position: 'fixed',
                background: 'rgba(0,0,0,.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <div style={{ textAlign: 'center' }}>
                <img style={{ height: 128 }} src={siteInfo.iconUrl} />
                <h3>{siteInfo.siteName}</h3>
                <div style={{ height: 200 }} />
                <p style={{ color: '#FFF' }}>{title}</p>
            </div>
        </div>
    );
}