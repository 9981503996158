
import { observable, action, computed, toJS } from 'mobx';


import _ from "lodash"


import axios from 'axios';

import { API_URL } from '../config'



import { dateFormatedYMD } from "../helper";

class General {

    constructor() {
        this.init()
    }

    /*
    constructor(){
        this.getToken();
        autorun(() => {
        })
    }
*/

    //   @observable demo = { firstName: "1212", lastName: "46465465" }
    @observable token = null
    @observable loading = false
    @observable initAfterLoad = false
    @observable races = []
    @observable raceIn = []

    //state
    //   @observable selectedArea = this.races[0]
    @observable selectedRaceIn = null
    @observable selectedKosu = null





    //ilk kontroller
    @action init() {
        if (this.races == 0) {
            this.getRaceList()
        }
        /*
        if (this.raceIn == 0) {
            this.getRaces()
        }
        */
    }

    //ilk kontroller (sayfa ilk yüklenme sonrası default seçimleri belirle)
    @action async initAfterLoadData(raceArea = null) {
        if (raceArea) {
            //races
        }
        else {
            const activeKey = _.get(this.races[0], "YER")
            const selectedRaceIn = _.find(this.raceIn, (o) => {
                return _.get(o, "hava.HIPODROMYERI") == activeKey
            })
            //  this.selectedRaceIn = selectedRaceIn
            ///  this.selectedKosu = selectedRaceIn.kosular[0]
            this.setSelectedRaceIn(selectedRaceIn)
            console.log("//////////////////// -- initAfterLoadData -- /////////////////////////////", {
                activeKey,
                raceIn: toJS(this.raceIn),
                selectedRaceIn
            })
        }
    }

    //yarış bölgeleri tetiklendiğinde
    @action async setSelectedRaceIn(item = null) {
        this.selectedRaceIn = item
        if (item) {
            this.selectedKosu = item.kosular[0]
        }

        return
    }
    //yarış koşu tetiklendiğinde
    @action async setSelectedKosu(item = null) {
        this.selectedKosu = item
        return
    }

    // 1 - yarış listesi  DATA
    @action async getRaceList(date = null) {
        /*
        this.loading = true
        const myDate = dateFormatedYMD(Date.now())
        let url = `${API_URL}/public/get-races/${myDate}`;
        try {
            const serverData = await axios.get(url);
            console.log({ serverData })
            this.races = _.get(serverData, "data", [])

        } catch (error) {
            console.log({ error })
        }
        finally {
            this.getRaces()
        }
*/
    }

    // 2 - yarışları getir  DATA
    @action getRaces() {
        ///public/get-race-in/:date/:KEY
        const myDate = dateFormatedYMD(Date.now())
        this.races.map(async (v, i) => {
            let url = `${API_URL}/public/get-race-in/${myDate}/${v.KEY}`;
            try {
                const serverData = await axios.get(url);
                console.log({ serverData })
                // this.raceIn = this.raceIn.concat(_.get(serverData,"data",[]))
                this.raceIn.push(_.get(serverData, "data", []))
                this.raceIn = this.raceIn
                /*
                if (this.races.length == i+1) {
                    this.raceIn = this.raceIn
                }
                */
            } catch (error) {
                console.log({ error })
            }
            finally {
                if (this.races.length == i + 1) {
                    this.loading = false
                    this.initAfterLoad = true
                    // this.initAfterLoadData()
                    this.setSelectedRaceIn(this.raceIn[0])
                    console.log("------------------getRaces---------------", { i, le: this.races.length })
                }


            }
        })


    }


    /*
                const activeKey = _.get(this.races[0], "YER")
            const selectedRaceIn = _.find(this.raceIn, (o) => {
                return _.get(o, "hava.HIPODROMYERI") == activeKey
            })
            this.setSelectedRaceIn(selectedRaceIn)

            */

    //logları listele
    @action async getLogs(date = null) {
        let url = `${API_URL}/logs${date ? `?date=${date}` : ""}`;
        return axios.get(url, this.getHeader());
    }

    //dashboard için logları getir
    //
    @action async getLogsForDashboard() {
        let url = `${API_URL}/logs?type=dashboard`;
        return axios.get(url, this.getHeader());
    }

    //dashboard chart
    @action async getDashboardChart() {
        const url = `${API_URL}/dashboard/chart`
        return axios.get(url, this.getHeader());
    }


    //dashboard kısayol sayıları
    @action async getDashboardCount() {
        const url = `${API_URL}/dashboard/count`
        return axios.get(url, this.getHeader());
    }






    //#masa
    //masa bölge ekle
    @action async insertDeskArea(data) {
        const url = `${API_URL}/desk-area`
        const headers = { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
        return axios.post(url, data, headers);
    }
    //masa bölge sil
    @action async deleteDeskArea(data) {
        const url = `${API_URL}/desk-area/delete`
        return axios.post(url, data, this.getHeader());
    }
    //masaları listele
    @action async getDesks() {
        let url = `${API_URL}/desk`;
        return axios.get(url, this.getHeader());
    }
    //masaları bölgeleri listele
    @action async getDeskArea() {
        let url = `${API_URL}/desk-area`;
        return axios.get(url, this.getHeader());
    }
    //masa ekle
    @action async insertDesk(data) {
        const url = `${API_URL}/desk`
        return axios.post(url, data, this.getHeader());
    }
    //masa sil
    @action async deleteDesk(data) {
        const url = `${API_URL}/desk/delete`
        return axios.post(url, data, this.getHeader());
    }
    //masa güncelle
    @action async updateDesk(data) {
        const url = `${API_URL}/desk/update`
        return axios.post(url, data, this.getHeader());
    }




    @action async getToken() {
        const token = localStorage.getItem('token');
        this.token = token;
        return token;

    }

    getHeader() {
        this.getToken();
        return { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
    }



}
export default General;
