
import GeneralState from './General';
import PrivateMessageState from './PrivateMessage';
import UserState from './User';
import ProductState from './Product';
import ChatState from './Chat';
import OrderState from './Order'
import SocketOp from './SocketOp'
import Admin from './Admin'
import Room from './Room'

const stores = {
  GeneralStore: new GeneralState(),
  OrderStore: new OrderState(),
  privateMessage: new PrivateMessageState(),
  UserStore: new UserState,
  ProductStore: new ProductState(),
  ChatStore: new ChatState(),
  SocketStore: new SocketOp(),
  AdminStore: new Admin(),
  RoomStore: new Room(),

};

export default stores;