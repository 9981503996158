
import React,{ Component } from "react";


import { Avatar, Layout,Menu, Dropdown,Row, Col, Button, message, Badge,Modal,Checkbox,Input,Carousel ,notification, ConfigProvider,List, Card } from 'antd';

import { setDefaultLanguage, getDefaultLanguage } from "../config";

import {GlobalOutlined} from '@ant-design/icons';

import i18n from 'i18next'
import { withTranslation } from "react-i18next";




class LanguageSelector extends Component{
  constructor(props){
    super(props)
    this.state={
      lang: getDefaultLanguage()
    }
  }

  onClickLang=(key)=>{
    console.log(key)
    setDefaultLanguage(key)
    i18n.changeLanguage(key)
    this.setState({lang:key})
  }
  render(){

    const menuLang = ()=><Menu
     onClick={(e)=>this.onClickLang(e.key)}>
      <Menu.Item key="en">
      {t("en")}
      </Menu.Item>
      <Menu.Item key="tr">
      {t("tr")}
      </Menu.Item>
    </Menu>

    const { t } = this.props;
    return (
      <div>
         <Dropdown overlay={menuLang}>
        <Button size="small" shape="round" >
        <GlobalOutlined /> {t(this.state.lang)}
        </Button>
         </Dropdown>
      </div>
    )
  }


}


export default  withTranslation()(LanguageSelector);


/*

              <Menu.Item key="logout" onClick={(e)=>this.onClickUserMenu(e.key)}>
                  <div>
                  <LogoutOutlined /> {t('logout')}
                  </div>
                
              </Menu.Item>


            <SubMenu title={<span>
                <GlobalOutlined /> {t(getDefaultLanguage())}
              </span>}>
              <Menu.Item key="en" onClick={(e)=>this.onClickUserMenu(e.key,"lang")}>
                      {t("en")}
              </Menu.Item>
              <Menu.Item key="tr" onClick={(e)=>this.onClickUserMenu(e.key,"lang")}>
                      {t("tr")}
              </Menu.Item>
              <Menu.Item key="pt" onClick={(e)=>this.onClickUserMenu(e.key,"lang")}>
                      {t("pt")}
              </Menu.Item>
            </SubMenu>
            */

















/*import React,{Component} from 'react'

import i18n from 'i18next'
import { Translation } from 'react-i18next';

import { useTranslation, withTranslation,  } from 'react-i18next'

 //const { t, i18n } = useTranslation()

//import { withI18n } from "react-i18next";

 // const { t, i18n } = useTranslation()


 class LanguageSelector extends Component{


 changeLanguage = (event) => {
   console.log({event})
   console.log({value: event.target.value,getDataByLanguage:i18n.getDataByLanguage("tr")})
   
   i18n.changeLanguage(event.target.value)
   
   //  const { t, i18n } = useTranslation()

// i18n.changeLanguage(event.target.value)
  }

  render(){

    const { t } = this.props;
    return (
      <div onChange={(e)=>this.changeLanguage(e)}>
        <h1>
{t('simpleContent')}
    </h1>

        <input type="radio" value="en" name="language" defaultChecked /> English
        <input type="radio" value="tr" name="language"/> Turkish
        <input type="radio" value="pt" name="language"/> Portuguese
      </div>
    )
  }

}


 export default  withTranslation()(LanguageSelector);
 //export default  LanguageSelector

 */