import React from 'react';
import './Toolbar.css';

import { Badge } from "antd";
import { defaultImages } from '../../../config';

export default function Toolbar({ leftItems, rightItems, user, title }) {
  return (
    <div className="toolbar">
      <div className="left-items">{leftItems}</div>
      {
        title
          ?
          title
          :
          <img className="conversation-photo" src={_.get(user, "avatar") ? _.get(user, "avatar") : defaultImages.userAvatar} alt="conversation" />
      }
      <Badge status={_.get(user, "online", false) ? "success" : undefined}>
        <h1 className="toolbar-title">{_.get(user, "name", "")}</h1>
      </Badge>
      <div className="right-items">{rightItems}</div>
    </div>
  );
}