
import { observable, action, computed, toJS } from 'mobx';


import _ from "lodash"


import axios from 'axios';

import { API_URL } from '../config'

import AdminServices from '../services/Admin';

const service = new AdminServices(localStorage.getItem('token'))

import { dateFormatedYMD } from "../helper";

class AdminStore {

    constructor() {
        //   this.init()
    }

    @observable loading = false
    @observable homeSliders = []
    @observable rooms = []
    @observable roomListTotalCount = 0
    @observable usersList=[]
    @observable usersListTotalCount=0
    @observable feedbackList = []
    @observable giftList = []
    @observable payments = []
    @observable products = []

    @observable tagList = []


    //ilk kontroller
    @action init() {
        if (this.homeSliders.length == 0) {
            this.getHomeSliders()
        }
        if (this.rooms.length == 0) {
            this.getRooms()
        }
        if (this.feedbackList == 0) {
            this.getFeedback()
        }
    }


    ///////////////////  TAGS  ///////////////////////////
    @action
    getTagList = async () => {
        return new Promise(async (resolve, reject) => {
            this.loading = true;
            try {
                const serverData = (await service.getTagList()).data;
                this.tagList = serverData
                return resolve(serverData)
            } catch (error) {
                return reject(error)
            } finally {
                this.loading = false;
            }
        })
    }
    setTag = async (postData) => {
        return new Promise(async (resolve, reject) => {
            this.loading = true;
            try {
                const serverData = (await service.setTag(postData)).data;
                return resolve(serverData)
            } catch (error) {
                return reject(error)
            } finally {
                this.loading = false;
            }
        })
    }
    updateTag = async (id, postData) => {
        return new Promise(async (resolve, reject) => {
            this.loading = true;
            try {
                const serverData = (await service.updateTag(id, postData)).data;
                return resolve(serverData)
            } catch (error) {
                return reject(error)
            } finally {
                this.loading = false;
            }
        })
    }
    deleteTag = async (id, postData) => {
        return new Promise(async (resolve, reject) => {
            this.loading = true;
            try {
                const serverData = (await service.deleteTag(id, postData)).data;
                return resolve(serverData)
            } catch (error) {
                return reject(error)
            } finally {
                this.loading = false;
            }
        })
    }


    ///////////////////  TAGS   end///////////////////////////

    @action getRooms = async (page,limit) => {
        this.loading = true;
        try {
            const serverData = (await service.getRooms(page,limit)).data;
            this.rooms =  serverData.paginatedResults
            this.roomListTotalCount = serverData.totalCount[0].count
        } catch (error) {
            console.log({ error })
        } finally {
            this.loading = false;
        }
    }

    @action
    updateRoomMembers = (id, members = []) => {
        const fIndex = this.rooms.findIndex(v => v._id == id)
        if (fIndex != -1) {
            this.rooms[fIndex] = {
                ... this.rooms[fIndex],
                members
            }
            this.rooms = JSON.parse(JSON.stringify(this.rooms))
        }
    }

    @action
    updateRoom = (id, data) => {
        return service.updateRoom(id, data)
    }


    /////////////////////USERS
    @action async getUsers(obj) {
        this.loading = true
        try {
          const serverData = (await service.getUsers(obj)).data;
          this.usersList = serverData.paginatedResults
          this.usersListTotalCount = serverData.totalCount[0].count
        } catch (error) {
          console.log({ error })
        } finally {
          this.loading = false;
        }
      }
    /////////////////////USERS END

    /////////////////////TOKENS
    @action async getUserTokens(userId) {
        return new Promise((resolve, reject) => {
            service.getUserTokens(userId).then(result => {
                return resolve(result.data)
            }).catch(err => {
                return reject(err)
            })
        })
      }
      @action async removeUserTokens(id) {
        return new Promise((resolve, reject) => {
            service.removeUserTokens(id).then(result => {
                return resolve(result.data)
            }).catch(err => {
                return reject(err)
            })
        })
      }
      @action async removeUserAllTokens(userId) {
        return new Promise((resolve, reject) => {
            service.removeUserAllTokens(userId).then(result => {
                return resolve(result.data)
            }).catch(err => {
                return reject(err)
            })
        })
      }
      
    /////////////////////TOKENS END

    


    ////////////////////////////// # GERİ BİLDİRİMLER  /////////////////////////////////////////
    @action getFeedback = async () => {
        this.loading = true;
        try {
            const serverData = (await service.getFeedbacklist()).data;
            console.log({ serverData })
            this.feedbackList = serverData
        } catch (error) {
            console.log({ error })
        } finally {
            this.loading = false;
        }
    }

    @action
    deleteFeedback = async (id) => {
        try {
            this.loading = true;
            service.deleteFeedback(id).then(() => {
                this.feedbackList = _.filter(this.feedbackList, (o) => {
                    return o._id != id
                })
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        } catch (error) {
            console.log(error)
        }
    }
    ////////////////////////////// # GERİ BİLDİRİMLER -end- /////////////////////////////////////////

    @action
    setHomeSliders = (data) => {
        if (!data) {
            data = this.homeSliders
        }
        this.homeSliders = JSON.parse(JSON.stringify(data))
    }
    @action
    uploadHomeSlider = async (file) => {
        try {
            this.loading = true;
            const upload = (await service.uploadFile(file, "slider")).data;
            console.log({ upload })
            const insertData = {
                img_url: upload.path,
            }
            const created = (await service.createHomeSlider(insertData)).data;
            console.log({ created })
            this.homeSliders.push(created);
            this.setHomeSliders()

        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }

    @action
    getHomeSliders = async () => {
        this.loading = true;
        try {
            const sliders = (await service.getHomeSliders()).data;
            this.homeSliders = sliders
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }
    ////////////////////////////// # HEDİYELER  /////////////////////////////////////////
    @action
    getGifts = async () => {
        this.loading = true;
        try {
            const dataList = (await service.getGifts()).data;
            console.log("!getGifts", {
                dataList
            })
            this.giftList = dataList
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }
    @action
    uploadGift = async (data) => {
        //data: public_id, title, price, img_url_file, animation_url_file 
        try {
            this.loading = true;
            const upload_img = (await service.uploadFile(data.img_url_file, "gifts-img")).data;
            const upload_animation = (await service.uploadFile(data.animation_url_file, "gifts-animation")).data;
            console.log({ upload_img, upload_animation })
            const insertData = {
                img_url: upload_img.path,
                animation_url: upload_animation.path,
                title: data.title,
                public_id: data.public_id,
                price: data.price,
            }
            const created = (await service.createGift(insertData)).data;
            console.log({ created })
            this.giftList = [...this.giftList, created];
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }
    @action
    updateGift = async (data) => {
        //data: public_id, title, price, img_url_file, animation_url_file 
        try {
            this.loading = true;
            const sendData = {
                _id: data._id,
                img_url: data.img_url,
                animation_url: data.animation_url,
                title: data.title,
                public_id: data.public_id,
                price: data.price,
                action: "update-item"
            }
            if (data.img_url_file) {
                const upload_img = (await service.uploadFile(data.img_url_file, "gifts-img")).data;
                sendData.img_url = upload_img.path;
            }
            if (data.animation_url_file) {
                const upload_animation = (await service.uploadFile(data.animation_url_file, "gifts-animation")).data;
                sendData.animation_url = upload_animation.path;
            }
            const updated = (await service.updateGifts(data._id, sendData)).data;
            const fIndex = this.giftList.findIndex(v => v._id == data._id)
            if (fIndex != -1) {
                this.giftList[fIndex] = sendData
                this.giftList = [...this.giftList];
            }
            // this.giftList = [...this.giftList, created];
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }

    @action
    updateGiftsStatus = (id, status) => {
        try {
            service.updateGifts(id, { action: "update-status", active: status })
        } catch (error) {
            console.log(error)
        }
    }

    @action
    deleteGift = async (id) => {
        try {
            this.loading = true;
            service.deleteGift(id).then(() => {
                this.giftList = _.filter(this.giftList, (o) => {
                    return o._id != id
                })
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        } catch (error) {
            console.log(error)
        }
    }

    @action
    getPayments = async () => {
        this.loading = true;
        return new Promise((resolve, reject) => {
            service.getPayments().then(result => {
                console.log("payments", { result })
                this.payments = result.data
                return resolve(result.data)
            }).catch(err => {
                console.log(err)
                return reject(err)
            }).finally(() => {
                this.loading = false;
            })

        })

    }
    ////////////////////////////// # HEDİYELER -end  /////////////////////////////////////////
    ////////////////////////////// # ÜRÜNLER   ///////////////////////////////////////////////
    @action
    getProducts = async () => {
        this.loading = true;
        return new Promise((resolve, reject) => {
            service.getProducts().then(result => {
                console.log("getProducts", { result })
                this.products = result.data
                return resolve(result.data)
            }).catch(err => {
                console.log(err)
                return reject(err)
            }).finally(() => {
                this.loading = false;
            })
        })
    }
    @action
    setProducts = async (data) => {
        this.loading = true;
        return new Promise((resolve, reject) => {
            service.setProducts(data).then(result => {
                console.log("setProducts", { result })
                this.products = [result.data, ... this.products]
                return resolve(result.data)
            }).catch(err => {
                console.log(err)
                return reject(err)
            }).finally(() => {
                this.loading = false;
            })
        })
    }
    @action
    deleteProducts = async (id) => {
        try {
            this.loading = true;
            service.deleteProducts(id).then(() => {
                this.products = _.filter(this.products, (o) => {
                    return o._id != id
                })
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        } catch (error) {
            console.log(error)
        }
    }
    @action
    updateProducts = async (data) => {
        try {
            this.loading = true;
            data.action = "update-item"
            const updated = (await service.updateProducts(data._id, data)).data;
            const fIndex = this.giftList.findIndex(v => v._id == data._id)
            if (fIndex != -1) {
                this.products[fIndex] = updated
                this.products = [...this.products];
            }
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }
    ////////////////////////////// # ÜRÜNLER  end--  //////////////////////////////////////////










    @action
    deleteHomeSlider = async (id) => {
        try {
            service.deleteHomeSlider(id).then(() => {
                this.homeSliders = _.remove(this.homeSliders, (o) => {
                    return o._id != id
                })
                this.loading = false;
            })
        } catch (error) {
            console.log(error)
        }

        // this.homeSliders = []
    }
    @action
    updateHomeSliderStatus = (id, status) => {
        try {
            service.updateHomeSliderStatus(id, { action: "update-status", active: status })
        } catch (error) {
            console.log(error)
        }
    }
    @action
    updateHomeSliderSort = async (itemIds) => {
        try {
            await service.updateHomeSliderSort({ action: "update-sort", itemIds })
            this.getHomeSliders()
        } catch (error) {
            console.log(error)
        }
    }
    @action deleteRoom = async (id) => {
        this.loading = true;
        try {
            await service.deleteRoom(id);
            this.rooms = _.remove(this.rooms, (o) => { return o._id != id })
        } catch (error) {
            console.log(error)

        } finally {
            this.loading = false;
        }
    }
    @action
    sendNotification = async (data) => {
        return service.sendPushNotification(data)
    }



    //logları listele
    @action async getLogs(date = null) {
        let url = `${API_URL}/logs${date ? `?date=${date}` : ""}`;
        return axios.get(url, this.getHeader());
    }

    //
    @action async getLogsForDashboard() {
        let url = `${API_URL}/logs?type=dashboard`;
        return axios.get(url, this.getHeader());
    }

    //dashboard chart
    @action async getDashboardChart() {
        const url = `${API_URL}/dashboard/chart`
        return axios.get(url, this.getHeader());
    }



    @action async getToken() {
        const token = localStorage.getItem('token');
        this.token = token;
        return token;

    }

    getHeader() {
        this.getToken();
        return { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
    }



}
export default AdminStore;
