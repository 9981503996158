
import React, { Component } from 'react';
import { Result, Button, Affix, Row, Col, Avatar, Descriptions, message, Input, Tooltip, Card, Upload } from 'antd';

import { Link, Redirect } from 'react-router-dom';


import { DeleteOutlined, ArrowLeftOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, MessageOutlined, EditOutlined, CheckOutlined, CameraOutlined, StopOutlined, CloseOutlined } from '@ant-design/icons';

import { inject, observer } from 'mobx-react';


import TextArea from 'antd/lib/input/TextArea';

import moment from 'moment'

//dinamik yap
/*
moment.locale=require('moment/locale/tr')
*/


import axios from "axios"
import { cdnUrl, defaultImages } from '../../config';

import { withTranslation } from 'react-i18next';


import { LoadingGlobal } from '../../components/LoadingGlobal';
import MyPageHeader from '../../components/MyPageHeader';

import { checkImageWidth, resizeImage } from '../../helper';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { toJS } from 'mobx';

// 
@inject('UserStore')
@observer
class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            avatar: "",
            name: '',
            _id: '',
            displayName: "",
            me: false,
            edit: false,
            report: false,
            editPassword: false,
            file: null,
            photoIndex: 0,
            isOpen: false,
            isErr: false
        }
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0)

        const userId = _.get(this.props, "match.params.id");
        console.log("-------profilePage--------", { userId })
        if (userId) {
            console.log({ userId })
            try {
                const user = await this.props.UserStore.getUserProfile({ _id: userId })
                console.log("componentDidMount user Profile!!!", { user: toJS(user) })
            } catch (error) {
                message.warning("Kullanıcı bulanamadı")
                this.setState({ isErr: true })
            }

        }
        else {
            console.log("userId!!!YOK")
        }

    }

    onClickResetPassword = async () => {
        if (!this.state.oldPassword || !this.state.password) {
            message.warning()
            return
        }
        //
        try {
            const res = await this.props.UserStore.resetPassword({ oldPassword: this.state.oldPassword, password: this.state.password })
            message.destroy()
            message.success("Başarılı")
            this.setState({ editPassword: false })
        } catch (error) {
            message.error(_.get(error, "response.data.message", ""))
        }
    }

    onSubmit = async () => {
        let data = {
            name: this.state.name,
            username: this.state.username,
            type: "profile"
        }
        try {
            const serverData = await this.props.UserStore.updateUser(data);
            console.log(serverData)
            message.success("Başarılı")
            this.setState({ edit: false })
        } catch (error) {
            console.log(error)
            const messageRes = _.get(error, "response.data.message", "")
            message.error(messageRes)
        }

    }


    submitReport = async () => {
        const text = this.state.reportText
        if (!text) return

        const { userDetail } = this.props.UserStore
        const sendData = {
            description: text,
            reportUser: userDetail._id,
            type: "report"
        }

        try {
            message.loading()
            await this.props.UserStore.reportUser(sendData)
            message.destroy()
            message.success("Şikayetinizi aldık. Geri bildiriminiz için teşekkürler.")
            this.setState({ reportText: '', report: false })
        } catch (error) {
            message.destroy()
            message.warning()
            console.log(error)
        }

    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }


    uploadImage = async (fileData) => {
        try {
            message.loading()
            await this.props.UserStore.updateUserAvatar(fileData.file.originFileObj)
            message.destroy()
            message.success()
        } catch (error) {
            console.log(error)
        }
    }

    handleUpload = async (img) => {
        try {
            const width = await checkImageWidth(img.file.originFileObj);
            if (width <= 600) {
                this.uploadImage(img);
            } else {
                const resizedImage = await resizeImage(img.file.originFileObj);
                const resizedFile = {
                    file: {
                        originFileObj: resizedImage
                    },
                };
                this.uploadImage(resizedFile);
            }
        } catch (error) {
            console.log(error)
        }

    };

    onClickEdit = (isEdit = false) => {
        if (isEdit) {
            const { userDetail, } = this.props.UserStore
            this.setState({ edit: true, name: userDetail.name, username: userDetail.username })
        }
        else {
            this.setState({ edit: false })
        }

    }
    render() {
        const { t } = this.props


        const { userDetail, user, loading } = this.props.UserStore

        const me = _.get(user, "_id") === _.get(userDetail, "_id")

        const edit = this.state.edit

        const { photoIndex, isOpen } = this.state;


        //  {userDetail.avatar && <img className="card-bkimg" src={userDetail.avatar} />}
        return (
            <div>
                {
                    //  loading && <LoadingTop /> <Loading />
                    loading && <LoadingGlobal loading />
                }

                {
                    this.state.isErr && <Redirect to="/" />
                }

                <MyPageHeader title={me ? "PROFİLİM" : "Profil"} subTitle={`${_.get(userDetail, "name")} kişisinin profili`} />

                <div>
                    <div className="card hovercard">
                        <div className="card-background">
                            <img className="card-bkimg" src={userDetail.avatar ? userDetail.avatar : defaultImages.userAvatar} />
                        </div>
                        <div className="useravatar">
                            <img onClick={() => this.setState({ isOpen: true })} style={{ border: userDetail.online ? '2px solid green' : undefined }} src={userDetail.avatar ? userDetail.avatar : defaultImages.userAvatar} />
                        </div>
                        <div className="card-info">
                            <span className="card-title">{userDetail.name}</span>
                        </div>
                        {
                            me
                            &&
                            <div style={{ position: 'absolute', right: 10, bottom: 10, backgroundColor: 'rgba(0,0,0,0.3)', padding: 5, borderRadius: 10 }}>
                                {
                                    !edit
                                        ?
                                        <EditOutlined style={{ fontSize: 16, color: '#FFF' }} onClick={() => this.onClickEdit(true)} />
                                        :
                                        <div>

                                            <CheckOutlined onClick={() => this.onSubmit()} style={{ fontSize: 16, color: '#FFF' }} />
                                            {`   `}
                                            <CloseOutlined onClick={() => this.onClickEdit(false)} style={{ fontSize: 16, color: '#FFF' }} />
                                        </div>
                                }
                            </div>
                        }
                    </div>

                    {
                        edit
                        &&
                        <div>
                            <Upload
                                customRequest={() => null}
                                accept="image/jpeg,image/png,image/jpg"
                                onChange={(file) => {
                                    this.handleUpload(file);
                                }}
                                showUploadList={false}
                            >
                                <Button><CameraOutlined /></Button>
                            </Upload>

                            <div style={{ height: 20 }} />

                            <Descriptions>
                                <Descriptions.Item label={"İsim"}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(e) => this.onChange(e)}
                                        name="name"
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item label={"Kullanıcı adı"}>
                                    <Input
                                        value={this.state.username}
                                        onChange={(e) => this.onChange(e)}
                                        name="username"
                                    /></Descriptions.Item>

                            </Descriptions>

                        </div>
                    }



                    <Descriptions>
                        <Descriptions.Item label={"İsim"}>{_.get(userDetail, "name")}</Descriptions.Item>
                        <Descriptions.Item label={"Kullanıcı adı"}>{_.get(userDetail, "username")}</Descriptions.Item>
                        {
                            me && <Descriptions.Item label={"Email"}>{_.get(userDetail, "email")}</Descriptions.Item>
                        }
                        <Descriptions.Item label={"ID"}>{_.get(userDetail, "publicId")}</Descriptions.Item>
                        <Descriptions.Item label={"Üyelik Tarihi"}>
                            <Tooltip title={new Date(_.get(userDetail, "created")).toLocaleString()}>
                                {moment(_.get(userDetail, "created")).fromNow()}
                            </Tooltip>
                        </Descriptions.Item>
                        <Descriptions.Item label={"Son Görülme"}>
                            <Tooltip title={new Date(_.get(userDetail, "lastOnline")).toLocaleString()}>
                                {moment(_.get(userDetail, "lastOnline")).fromNow()}
                            </Tooltip>
                        </Descriptions.Item>

                    </Descriptions>
                    {
                        edit
                        &&
                        <Button onClick={() => this.setState({ editPassword: !this.state.editPassword })}>
                            Şifre İşlemleri
                        </Button>
                    }


                </div>

                {
                    this.state.editPassword
                    &&
                    <Descriptions.Item>
                        <span>
                            <Row>
                                <Col span={12}>
                                    <Row>
                                        <Col span={12}>
                                            <Input type="password" onChange={(e) => this.onChange(e)} name="oldPassword" placeholder="Eski Şifreniz" />
                                        </Col>
                                        <Col span={12}>
                                            <Input type="password" onChange={(e) => this.onChange(e)} name="password" placeholder="Yeni Şifreniz" /></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ marginTop: 10 }} span={24}><Button block type="primary"
                                            onClick={() => this.onClickResetPassword()}>
                                            {t('resetPassword')}
                                        </Button></Col>
                                    </Row>
                                </Col>
                            </Row>

                        </span>

                    </Descriptions.Item>

                }

                {
                    !me
                    &&
                    <div>


                        <Link to={`/messenger/${_.get(userDetail, "_id")}`}>
                            <Button title="Mesaj Gönder">
                                <MessageOutlined />
                            </Button>
                        </Link>

                        <Button title="Bildir" onClick={() => this.setState({ report: !this.state.report })} >
                            <StopOutlined />
                        </Button>
                    </div>

                }
                {
                    this.state.report
                    &&
                    <div>
                        <Row>
                            <Col style={{ marginTop: 10 }} span={12}>
                                <TextArea placeholder="Profil hakkındaki şikayetinizi lütfen bize detaylandırın" value={_.get(this.state, "reportText", "")} name="reportText" onChange={(e) => this.onChange(e)} />

                                <Button onClick={() => this.submitReport()}>
                                    Gönder
                                </Button>
                            </Col>
                        </Row>

                    </div>
                }


                {isOpen && (
                    <Lightbox
                        // mainSrc={images[photoIndex]}
                        mainSrc={userDetail.avatar ? userDetail.avatar : defaultImages.userAvatar}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    // nextSrc={images[(photoIndex + 1) % images.length]}
                    // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    // onMovePrevRequest={() =>
                    //     this.setState({
                    //         photoIndex: (photoIndex + images.length - 1) % images.length,
                    //     })
                    // }
                    // onMoveNextRequest={() =>
                    //     this.setState({
                    //         photoIndex: (photoIndex + 1) % images.length,
                    //     })
                    // }
                    />
                )}


            </div >
        )
    }
}

export default withTranslation()(Profile)