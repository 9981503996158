import React, { Component } from "react";

import { Row, Col, message, Form, Table, Popconfirm, Divider, Upload, Switch, Button, Menu, Dropdown, Input } from 'antd'
import { inject, observer } from 'mobx-react';

import PageHeader from "../../components/PageHeader";


import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
    DeleteOutlined,
    ArrowLeftOutlined,
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
    MessageOutlined,
    EditOutlined,
    CheckOutlined,
    CameraOutlined,
    StopOutlined,
    CloseOutlined,
    MoreOutlined,
    MenuOutlined,
} from '@ant-design/icons';
import _ from "lodash";


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

@inject('AdminStore', 'UserStore')
@observer
export default class HomeSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            show: false,
            isShortable: false
        };
    }


    componentDidMount = () => {
        this.itemList();
    }


    itemList = () => {
        this.props.AdminStore.getGifts()
    }



    delete = (item) => {
        this.props.AdminStore.deleteGift(item._id)
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    handleChangeImage = info => {
        console.log("handleChangeImage:type:", info.file.type)
        console.log("handleChangeImage:name:", info.file.name)
        console.log("handleChangeImage:uri:", info.file.uri)
        console.log("handleChangeImage::", info.file.originFileObj)
        getBase64(info.file.originFileObj, imageUrl =>
            this.setState({
                imageUrl,
                loading: false,
                postImage: {
                    uri: "file://" + imageUrl,
                    name: info.file.name,
                    type: info.file.type
                }
                //  avatar:imageUrl  //SONRA
            }),
        );

    };

    submit = async (action) => {
        //sıralama güncelle
        if (action == "save-sort") {
            const sortableDataIds = this.state.dataSource.map(v => v._id);
            console.log({ sortableDataIds })
            this.props.AdminStore.updateHomeSliderSort(sortableDataIds)
            this.setState({
                isShortable: false
            })
        }
        // Kaydet
        if (action == "submit") {
            const { _id, public_id, title, price, img_url_file, animation_url_file } = this.state
            //GÜNCELLE
            if (_id) {
                if (!public_id || !title || !price) {
                    message.error("Lütfen tüm alanları doldurun!")
                    return;
                }

                try {
                    await this.props.AdminStore.updateGift(this.state)
                    this.resetState()
                } catch (error) {
                    console.log({ error })
                }
            }
            //YENİ
            else {
                if (!public_id || !title || !price || !img_url_file || !animation_url_file) {
                    message.error("Lütfen tüm alanları doldurun!")
                    return;
                }

                try {
                    await this.props.AdminStore.uploadGift(this.state)
                    this.resetState()
                } catch (error) {
                    console.log({ error })
                }
            }



        }

    }

    resetState = () => {
        this.setState({
            public_id: null,
            title: null,
            price: null,
            img_url_file: null,
            animation_url_file: null,
            show: false,
            _id: '',
            img_url: '',
        })
    }

    onChangeFile = (event, name) => {
        this.setState({
            [name]: event.target.files[0]
        })
    }

    onChangeSwitch = (status, item) => {
        console.log("!!!!!", status, item)
        this.props.AdminStore.updateGiftsStatus(item._id, status)
    }

    onSortItems = (items) => {
        console.log(items);
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { dataSource } = this.state;
        // const dataSource = _.get(this.props, "AdminStore.homeSliders", [])
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            console.log('Sorted items: ', newData);
            this.setState({ dataSource: newData });
        }
    };




    render() {
        const { show, selectedFile, isShortable } = this.state;

        const data = _.get(this.props, "AdminStore.giftList", [])
        const { loading } = this.props.AdminStore

        const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);


        const SortableItem = sortableElement(props => <tr {...props} />);
        const SortableContainer = sortableContainer(props => <tbody {...props} />);
        const DragableBodyRow = ({ index, className, style, ...restProps }) => (
            <SortableItem index={restProps['data-row-key']} {...restProps} />
        );
        const DraggableContainer = props => (
            <SortableContainer
                useDragHandle
                helperClass="row-dragging"
                onSortEnd={({ oldIndex, newIndex }) => this.onSortEnd({ oldIndex, newIndex })}
                {...props}
            />
        );

        const menuMore = (
            <Menu>
                <Menu.Item key="0"
                    onClick={() => { this.setState({ show: !show }) }}
                >
                    Yeni
                </Menu.Item>
                <Menu.Item key="1" onClick={() => {
                    this.setState({
                        isShortable: !isShortable,
                        dataSource: data.map((v, i) => {
                            return {
                                ...v,
                                index: _.parseInt(_.get(v, "index", i))
                            }
                        })
                    })
                }}>
                    Sırala
                </Menu.Item>
            </Menu>
        );



        const columns = [
            {
                title: '#ID',
                key: 'public_id',
                dataIndex: 'public_id',

            },
            {
                title: 'Görsel/Animasyon',
                key: 'img_url',
                render: (text, record) =>
                    <span>
                        <img style={{ height: 50 }} src={record.img_url} alt="" />
                        <img style={{ height: 50 }} src={record.animation_url} alt="" />
                    </span>,
            },
            {
                title: 'Fiyat',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'Başlık',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'İşlem',
                key: 'action',
                render: (text, record) =>
                    <span>
                        <Switch defaultChecked={record.active} onChange={(e) => this.onChangeSwitch(e, record)} checkedChildren="Aktif" unCheckedChildren="Pasif" />
                        <Divider type="vertical" />
                        <Popconfirm okText="Evet" cancelText="Hayır" title="Silmek istediğinize emin misiniz ?" onConfirm={() => this.delete(record)}>
                            <Button size='small' type='danger'>
                                Sil
                            </Button>
                        </Popconfirm>
                        <Divider type="vertical" />
                        <Button size='small'
                            onClick={() => this.setState({ show: true, ...record })}
                        >
                            Düzenle
                        </Button>
                    </span>
                ,
            },
        ];


        const columns1 = [
            {
                title: ' ',
                dataIndex: 'name',
                className: 'drag-visible',
                render: (text, record) =>
                    <span>
                        <img style={{ height: 50 }} src={record.img_url} alt="" />
                    </span>,
            },
            {
                title: '',
                dataIndex: 'sort',
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle />,
            },
        ];

        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
        };
        const buttonItemLayout = {
            wrapperCol: { span: 14, offset: 4 },
        };

        return (
            <div>

                <PageHeader
                    subTitle={data.length > 0 ? `Toplam ${data.length} hediye bulundu` : 'Hediye bulunmadı'}
                    title="Hediye Listesi"
                    extra={
                        <span>
                            {show
                                &&
                                <Button onClick={() => this.resetState()} type="danger">Kapat</Button>
                            }
                            <Dropdown trigger={"click"} overlay={menuMore}
                            >
                                <Button
                                    shape="circle-outline"
                                    style={{ marginLeft: 5 }}
                                    icon={<MoreOutlined />}
                                />
                            </Dropdown>
                        </span>

                    }
                />

                {
                    show
                    &&
                    <div style={{ paddingBottom: 20 }}>

                        <Form layout={"horizontal"}>

                            <Row>
                                <Col offset="4">
                                    <h4>
                                        {
                                            this.state._id ? "DÜZENLE" : "YENİ"
                                        }
                                    </h4>
                                </Col>
                            </Row>

                            <Form.Item help="(.png)" label="Görsel" {...formItemLayout}>
                                {
                                    this.state.img_url
                                    &&
                                    <img src={this.state.img_url} style={{ height: 80 }} />
                                }
                                <input type="file" onChange={(e) => this.onChangeFile(e, "img_url_file")} />
                            </Form.Item>

                            <Form.Item help="(.mpeg4,svga,gif)" label="Animasyon" {...formItemLayout}>
                                {
                                    this.state.animation_url
                                    &&
                                    <img src={this.state.animation_url} style={{ height: 80 }} />
                                }
                                <input type="file" onChange={(e) => this.onChangeFile(e, "animation_url_file")} />
                            </Form.Item>

                            <Form.Item label="İd" {...formItemLayout}>
                                <Input value={this.state.public_id} name="public_id" onChange={this.onChange} type="number" />
                            </Form.Item>

                            <Form.Item label="İsim" {...formItemLayout}>
                                <Input value={this.state.title} name="title" onChange={this.onChange} />
                            </Form.Item>

                            <Form.Item label="Fiyat" {...formItemLayout}>
                                <Input value={this.state.price} name="price" onChange={this.onChange} type="number" />
                            </Form.Item>

                            <Form.Item {...buttonItemLayout}>
                                <Button loading={loading} onClick={() => this.submit("submit")}>Kaydet</Button>
                                {show
                                    &&
                                    <Button onClick={() => this.resetState()} type="danger">Kapat</Button>
                                }
                            </Form.Item>

                        </Form>

                    </div>
                }

                {isShortable && <Button loading={loading} onClick={() => this.submit("save-sort")}>Sıralamayı Kaydet</Button>}


                <div style={{ height: 20 }} />
                {
                    isShortable
                        ?
                        <Table
                            pagination={false}
                            dataSource={this.state.dataSource}
                            columns={columns1}
                            rowKey="index"
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: DragableBodyRow,
                                },
                            }}
                        />
                        :
                        <Table
                            rowKey={'_id'}
                            pagination={false}
                            columns={columns}
                            dataSource={data}
                            loading={loading}
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: DragableBodyRow,
                                },
                            }}
                        />
                }



            </div>
        )
    }

}







