import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export function LoadingTop({ title }) {
    const antIcon = <LoadingOutlined
        style={{ fontSize: 34 }}
        spin />;
    return (
        <div
            style={{
                width: '100%',
                margin: 'auto',
                zIndex: 10000,
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: 10,
            }}>
            <Spin indicator={antIcon} />
            {title}
        </div>
    )
}
