import React, { Component } from 'react'
import { Form, Spin, Input, Button, message, Checkbox, Row } from 'antd';
import { ReactSVG } from 'react-svg'
import { siteInfo, facebookAppId, googleClientId } from '../config'

import LanguageSelector from "../components/LanguageSelector"

import { UserOutlined, LockOutlined, GoogleOutlined, FacebookFilled } from '@ant-design/icons';

import { inject } from 'mobx-react';

import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { Helmet } from "react-helmet";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';

const LoginWithTwitter = require('login-with-twitter')

const tw = new LoginWithTwitter({
  consumerKey: 'XOOpAjbFonX412tl5OiwlNWob',
  consumerSecret: 'rDDwVQ42IX8Js7erc5OclOAdcqrGvI5zDsoOvO7ImqpiFQSDfc',
  callbackUrl: 'https://www.sssosyal.com/twitter/callback'
})


// BENİ HATIRLA SONRA YAP
/*
rememberUser = async () => {
    try {
      await AsyncStorage.setItem('YOUR-KEY', this.state.username);
    } catch (error) {
      // Error saving data
    }
    };
    getRememberedUser = async () => {
    try {
      const username = await AsyncStorage.getItem('YOUR-KEY');
      if (username !== null) {
        // We have username!!
        return username;
      }
    } catch (error) {
      // Error retrieving data
    }
    };
    forgetUser = async () => {
      try {
        await AsyncStorage.removeItem('Longtail-User');
      } catch (error) {
       // Error removing
      }
    };
    */


@inject('UserStore')
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loading: false,
      formtype: "login"
    };
  }


  async componentDidMount() {
    const token = await this.props.UserStore.getToken();
    if (token) {
      // this.props.history.push("/");
      message.warning("Zaten giriş yaptınız.")
      console.log("VAR")
    }
    else {
      //  console.log("YOK")
    }
    // console.log("token",token)
  }


  responseFacebook = (response) => {
    console.log(response);
    if (response.status == "unknown") {
      return;
    }
    this.setState({
      loading: true
    })
    const data = {
      name: _.get(response, "name", ""),
      email: _.get(response, "email", ""),
      avatar: _.get(response, "picture.data.url", ""),
      FBid: _.get(response, "id", ""),
      FBaccessToken: _.get(response, "accessToken", ""),
      FBuserID: _.get(response, "userID", ""),
      fbData: response
    }
    const login = this.props.UserStore.login(data);
    login.then((res) => {
      console.log(res)
      const token = res.data._id;
      this.props.UserStore.saveToken(token);
      this.props.UserStore.saveUserId(_.get(res, "data.user._id"));
      setTimeout(() => {
        this.setState({ loading: false })
        this.props.history.push("/");
      }, 2000)

    }).catch((err) => {
      console.log("login facebook catch", err.response)
    })

    console.log(response);
  }

  responseGoogle = (response) => {
    console.log(response);
  }

  // Google ile başarılı giriş
  responseGoogleSuccess = (response) => {
    this.setState({ loading: true })

    const login = this.props.UserStore.login(response);
    login.then((res) => {
      console.log(res)
      const token = res.data._id;
      this.props.UserStore.saveToken(token);
      this.props.UserStore.saveUserId(_.get(res, "data.user._id"));
      setTimeout(() => {
        this.setState({ loading: false })
        this.props.history.push("/");
      }, 2000)

    }).catch((err) => {
      message.error()
      this.setState({ loading: false })
      console.log("login google catch", err.response)
    })

  }

  responseFailedGoogle = (response) => {
    // "popup_closed_by_user"  // izin verilmedi
    console.log(response);
  }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    //  this.props.init();
  }

  onSubmit = async () => {

    if (this.state.formtype == "login") {

      const data = {
        password: this.state.password,
        email: this.state.email
      }

      if (!this.state.password || !this.state.email) {
        message.warn("Gerekli alanları doldurunuz")
        this.setState({
          loading: false
        })
      }
      else {
        this.setState({
          loading: true
        })
        const login = this.props.UserStore.login(data);

        login.then((res) => {
          // Giriş başarılı
          console.log(res)
          const token = res.data._id;
          this.props.UserStore.saveToken(token);
          this.props.UserStore.saveUserId(_.get(res, "data.user._id"));
          message.loading("", 0)
          setTimeout(() => {
            this.setState({ loading: false })
            message.destroy()
            this.props.history.push("/");
            window.location.reload(false);
            //    joinRoom(token,res.data.user.apikey)
          }, 2000)

        })
          .catch((err) => {
            console.log(err)
            message.error("Giriş bilgileriniz doğrulanmıyor. Lütfen daha sonra tekrar deneyiniz")
            this.setState({ loading: false })
          })

        /*
        loading
        console.log(login)
        */
        ///    alert("sdfsaf")
        //  this.props.connectApiLogin(this.state.email,this.state.password)
        // this.connectApi();
      }
    }
    // şifre sıfırlama on click
    else {
      console.log("şifre sıfırlama on click")

      const { email } = this.state
      if (!email) {
        return
      }

      try {
        this.setState({
          loading: true
        })
        await this.props.UserStore.forgotPassword({email});
        message.success("Şifre sıfırlama linki mail adresine gönderildi.")

      } catch (error) {
        const messageRes = _.get(error, "response.data.message", "")
        message.error(messageRes)
        console.log(error)
      }
      finally {
        this.setState({
          loading: false,
          email: '',
        })
      }

    }

  }

  //linear-gradient(87deg,#11cdef,#1171ef)!important;
  render() {
    const { t } = this.props
    const BackgroundImageUrl = 'https://source.unsplash.com/random/900*700/?social,chat'
    return (
      <div
        style={{
          backgroundImage: `url(${BackgroundImageUrl})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Giriş</title>
          <meta name="description" content="ChatX e giriş yap" />
        </Helmet>

        <div style={{
          background: 'linear-gradient(87deg,rgba(17, 205, 239,0.5),rgba(17, 113, 239, 0.5))',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
        >
          <div style={
            {
              width: 400,
              padding: 20,
              backgroundColor: 'rgba(255,255,255,0)',
              borderRadius: 0
            }}>
            <div style={{ marginBottom: 10, alignItems: 'center', textAlign: 'center' }}>
              <div style={{ textAlign: 'center' }}>
                <img src={siteInfo.logoUrl} />
              </div>
            </div>




            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={() => this.onSubmit()}
            >

              <Input
                name="email"
                autoFocus={true}
                defaultValue={this.state.email}
                // placeholder={this.state.formtype == "login" ? t("usernameOrMail") : t("email")}
                placeholder={t("usernameOrMail")}
                onChange={(e) => this.onChange(e)}
                prefix={<UserOutlined />}
                style={{ marginBottom: 10 }}
              />

              {
                (this.state.formtype == "login")
                &&
                <Form.Item>
                  <Input
                    name="password"
                    defaultValue={this.state.password}
                    placeholder={t("password")}
                    type="password"
                    onChange={(e) => this.onChange(e)}
                    onPressEnter={() => this.onSubmit()}
                    style={{ marginBottom: 10 }}
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
              }

              <Form.Item>
                {
                  this.state.formtype == "login"
                  &&
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>{t("rememberMe")}</Checkbox>
                  </Form.Item>
                }


                <a className="login-form-forgot" onClick={() => this.setState({
                  formtype: this.state.formtype == "login" ? 'forgot' : 'login'
                })}>
                  {
                    this.state.formtype == "login"
                      ?
                      t("forgotPassword")
                      :
                      t("rememberPassword")
                  }
                </a>
              </Form.Item>

              <Form.Item>
                <Button
                  block={true}
                  type="primary"
                  onClick={() => this.onSubmit()}
                  disabled={this.state.loading}
                  loading={this.state.loading}
                >
                  {
                    this.state.formtype == "login"
                      ?
                      t("login")
                      :
                      t("resetPassword")
                  }
                </Button>
              </Form.Item>

              <Form.Item>
                {
                  // <Form.Item noStyle>
                  //   {t("or")} <Link to="/register"> {t("register")} </Link>
                  // </Form.Item>
                }
                <a style={{
                  //float: 'right' 
                }} target="_blank" className="login-form-forgot" href={siteInfo.privacyUrl}>
                  {t("privacyPolicy")}
                </a>
              </Form.Item>
            </Form>
            <div style={{ textAlign: 'center', color: 'rgba(0,0,0,0.6)', fontSize: 12, }}>
              {siteInfo.copyright}
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default withTranslation()(Login)