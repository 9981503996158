

import { decorate, observable, action, autorun } from "mobx";
import _ from "lodash";

import RoomService from "../services/Room";
//import { mySocket } from '../Socket';
import Auth from "./Auth";

export default class Room {
    constructor() {
        autorun(() => {
            console.log("autorun!")
        })
    }


    ///////////////////////////////////---STATIC----//////////////////
    /////--ROOM
    //   @observable activeRoom = { title: "A1ctive room name", publicId: "123", level: "6", onlineUsers: [testUser, testUser] }
    @observable activeRoom = null
    @observable loading = false

    @observable aAppId = '00d2cc2558c14efa9adaa7851cf5d190';//3c6c1e4010214f119a43cd49394a85d6 // c7e742d5df23478285a9dc4f4ff62407
    @observable typing = '';
    @observable volumeMute = false;
    @observable roomMessages = [];
    @observable micstate = [
        {
            index: 0,
            state: false, name: "mic1", user: {},
            isBlocked: false
        },
        {
            index: 1,
            state: false, name: "mic2", user: {},
            isBlocked: false
        },
        {
            index: 2,
            state: false, name: "mic3", user: {},
            isBlocked: false
        },
        {
            index: 3,
            state: false, name: "mic4", user: {},
            isBlocked: false
        },
        {
            index: 4,
            state: false, name: "mic5", user: {},
            isBlocked: true
        },
        {
            index: 5,
            state: false, name: "mic6", user: {},
            isBlocked: true
        },
        {
            index: 6,
            state: false, name: "mic6", user: {},
            isBlocked: true
        },
        {
            index: 7,
            state: false, name: "mic6", user: {},
            isBlocked: true
        },

    ];

    @observable messages = []
    //@observable gifts = [{ _id: "123123", name: 'Örnek hediye 1', coin: 10, image: "https://images.unsplash.com/photo-1584354767284-f07d28d19a8d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=380&q=80" },]
    @observable gifts = []


    @action
    setLoading = (loading = false) => {
        this.loading = loading
    }

    ////////////// oda aksiyonları//////////
    @action
    setActiveRoomItem = (item) => {
        this.activeRoom = item
        return;
    }
    @action
    setOnlineUsers = (onlineUsers = []) => {
        this.activeRoom.onlineUsers = onlineUsers
        return;
    }
    @action
    joinRoom = (item) => {
        this.activeRoom = item
        //#SOCKET
        // mySocket.emit("joinRoom", { room: item._id })
        return;
    }
    @action
    resetRoomMessages = () => {
        this.messages = []
        return;
    }

    ///oda detaay geir
    @action
    getRoomDetail = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const token = await new Auth().myToken();
                const service = new RoomService(token);
                const response = (await service.getRoomDetail(id)).data;
                return resolve(response)
            } catch (error) {
                return reject(error)
            }
        })
    }

    /////////// MİC
    @action
    setUserMicstateByMicName = (mic, user) => {
        const finded = this.micstate.find(v => v.name === mic.name)
        if (finded) {
            finded.user = user
            finded.state = true
        }
        this.micstate = JSON.parse(JSON.stringify(this.micstate))
        return;
    }
    @action
    setUserMicstate = (user) => {
        const filtered = this.micstate.filter(v => !v.state && !v.isBlocked)
        if (filtered.length > 0) {
            filtered[0].user = user
            filtered[0].state = true
        }
        this.micstate = JSON.parse(JSON.stringify(this.micstate))
        return;
    }
    @action
    leaveUserMicstate = (userId) => {
        const finded = this.micstate.find(v => v.user._id === userId)
        if (finded) {
            finded.user = {}
            finded.state = false
            // finded[0].user = {}
            // _.unset(finded, "user")
        }
        this.micstate = JSON.parse(JSON.stringify(this.micstate))
        return;
    }
    @action
    setMicUsers = (micstate) => {
        this.micstate = JSON.parse(JSON.stringify(micstate))
        return;
    }



    ///////////////////////////////////---STATIC END----//////////////////

    @action
    setVolumeMute = (status = false) => {
        this.volumeMute = status
    }
    @action
    toggleVolumeMute = () => {
        this.volumeMute = JSON.parse(JSON.stringify(!this.volumeMute))
        console.log('!!!toggleVolumeMute!', this.volumeMute)
        return
    }
    @action
    setTyping = (text = "") => {
        this.typing = text
    }
    @action
    sendMessage = ({ typing, user, created = new Date() }) => {
        const pushData = { user, message: typing, created }
        console.log({ pushData })
        this.messages.push(pushData)
        this.typing = ''
        return
    }

    @action
    createRoom = async (data) => {

        this.loading = true;
        try {
            const token = await new Auth().myToken();
            const service = new RoomService(token);
            //foto var
            if (_.get(data, "fileImage.name")) {
                const postImage = (await service.postRoomImage(data.fileImage)).data
                console.log("kapak foto var::", { postImage })
                _.unset(data, "fileImage");
                data.avatar = postImage.path
                const createRoom = (await service.createRoom(data)).data;
                console.log("createRoom:fotolu:", createRoom)
            }
            //foto yok
            else {
                try {
                    _.unset(data, "fileImage");
                    const createRoom = (await service.createRoom(data)).data;
                    console.log("createRoom::", createRoom)
                    console.log("kapak foto YOK!::", { data })

                } catch (error) {
                    console.log("error!!5", error)
                }
            }
        } catch (error) {
            console.log("createRoom Store:ERROR!!:", { error })
        } finally {
            this.loading = false
        }

        /*
        return new Promise((resolve, reject) => {
            console.log("createRoom. func")
            service.postRoomImage(data).then(res => {
                console.log('!createRoom!RES', res)
                return resolve(res)
            }).catch(err => {
                console.log('!!createRoom!ERR:', err)
                return reject(err)
            })
        })
        */
    }

    @action
    updateRoom = async (id, data) => {

        this.loading = true;
        try {
            const token = await new Auth().myToken();
            const service = new RoomService(token);

            //foto vatsa
            if (_.get(data, "fileImage.name")) {
                const postImage = (await service.postRoomImage(data.fileImage)).data
                _.unset(data, "fileImage");
                data.avatar = postImage.path
            }

            try {
                const serverData = (await service.updateRoom(id, data)).data;
                console.log("updateRoom::", serverData)
            } catch (error) {
                console.log("error!!:updateRoom:", error)
            }
        } catch (error) {
            console.log("createRoom Store:ERROR!!:", { error })
        } finally {
            this.loading = false
        }
    }

    @action
    getRoom = async (id) => {
        try {
            const token = await new Auth().myToken();
            const service = new RoomService(token);
            const serverData = (await service.getRoom(id)).data;
            return Promise.resolve(serverData)
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        }
    }

    @action
    getRoomItem = async (id, password) => {
        return new Promise(async (resolve, reject) => {
            try {
                const token = await new Auth().myToken();
                const service = new RoomService(token);
                const serverData = (await service.getRoomItem(id, password)).data;
                return resolve(serverData)
            } catch (error) {
                console.log(error)
                return reject(error)
            }
        })
    }








}
