import React, { Component } from "react";
import { Link, } from 'react-router-dom'; // Link,
import { Tabs, Layout, Menu, Row, Col, Button, Badge, Modal, Checkbox, Input, Spin, message, List, Card, Avatar, Divider } from 'antd';


import _ from 'lodash';


import {
    UserOutlined,
    SearchOutlined,
    UserAddOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    MessageOutlined
} from '@ant-design/icons';

import { inject, observer } from 'mobx-react';

import { goPage, goBack } from '../../../historyConfig'
import { defaultImages } from "../../../config";
import MyPageHeader from "../../../components/MyPageHeader";





@inject('UserStore')
@observer
export default class SearchUsers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            search: '',
            activeTabsKey: 'user',
            hasMore: true,
            limit: 20,
            offset: 0,
            initSearch: false,
            loading: false
        }
    }

    getItems = () => {
        //  this.setState({ loading: true })
        if (!this.state.initSearch) {
            this.setState({ initSearch: true })
        }
        const search = _.get(this.state, "search", "").trim()
        if (search.length < 3) {
            return
        }
        this.props.UserStore.search(search)
    }


    onClickMessage = (item) => {
        const url = `messenger/${item._id}`
        goPage(url)
    }





    onChangeTabs = (activeTabsKey) => {
        console.log(activeTabsKey)
        this.setState({
            activeTabsKey
        })
    }


    render() {
        const data = this.props.UserStore.searchUsers
        const { user } = this.props.UserStore
        const { loading } = this.props.UserStore

        const { roomItem } = this.props
        const placeholder = this.props.placeholder || 'Aramak istediğiniz kelimeyi buraya giriniz'
        const removeBorder = this.props.removeBorder || false
        return (
            <div>
                <Row>
                    <Col span={24}>
                        <Input
                            value={this.state.search}
                            prefix={
                                this.state.search.length > 0 && <Button shape="circle" onClick={() => this.setState({ search: '' })}>
                                    <CloseOutlined />
                                </Button>
                            }
                            onPressEnter={() => this.getItems()}
                            placeholder={placeholder}
                            onChange={e => this.setState({ search: e.target.value })}
                            autoFocus={true}
                            style={{
                                borderRadius: removeBorder ? 0 : 21
                            }}
                            suffix={<Button
                                onClick={() => this.getItems()}
                                shape="circle">
                                <SearchOutlined />
                            </Button>}
                        />
                    </Col>
                </Row>

                <Row
                    style={{
                        padding: '0 50px'
                    }}
                >

                </Row>


                <List
                    loading={loading}
                    dataSource={data}
                    renderItem={item => (
                        <List.Item key={item._id}>
                            <List.Item.Meta
                                avatar={
                                    <Link to={`/user/${item._id}`}>
                                        <Avatar src={item.avatar ? item.avatar : defaultImages.userAvatar} />
                                    </Link>
                                }
                                title={<Link to={`/user/${item._id}`}>{item.name}</Link>}
                                description={item.username}
                            />
                            {
                                roomItem?.members?.map(v => v._id).includes(item?._id)
                                    ?
                                    <Button onClick={() => this.props.plusMinusMember(item?._id)} type="danger" size="small">Çıkar</Button>
                                    :
                                    <Button onClick={() => this.props.plusMinusMember(item?._id)} type="primary" size="small">Ekle</Button>
                            }
                            <Divider type="vertical" />
                            {
                                roomItem?.admins?.map(v => v._id).includes(item?._id)
                                    ?
                                    <Button onClick={() => this.props.plusMinusAdmins(item?._id)} type="danger" size="small">Adminliği Al</Button>
                                    :
                                    <Button onClick={() => this.props.plusMinusAdmins(item?._id)} type="primary" size="small">Admin Yap</Button>
                            }

                            <Divider type="vertical" />
                            {
                                roomItem?.blockedUsers?.map(v => v.userId).includes(item?._id)
                                    ?
                                    <Button onClick={() => this.props.plusMinusBlockedUsers(item?._id)} type="danger" size="small">Engeli Kaldır</Button>
                                    :
                                    <Button onClick={() => this.props.plusMinusBlockedUsers(item?._id)} type="primary" size="small">Engelle</Button>
                            }


                        </List.Item>
                    )
                    }
                >
                </List >


            </div >
        )
    }

}