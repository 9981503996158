




const pack = require('../package.json')



const url = process.env.NODE_ENV !== "development" ? "https://chat10.piyanos.com" : "http://localhost:3131"
//const url = "https://chat10.piyanos.com"

export const testtoken = "123"

export const apiBaseUrl = url


export const cdnConfig = {
    api: "https://pistatic.piyanos.com",
    prefix: "/upload/publicdata",
    full_url: "https://pistatic.piyanos.com/upload/publicdata",
    token: "basitguvenliksonra-dinamk-34",
    key: "chatx",
}

export const cdnUrl = "https://pistatic.piyanos.com/upload/publicdata"

//facebook  //appp name : Piyan meet
export const facebookAppId = "1140265152979412";
export const facebookAppScreet = "1eee2c4d35044202193bae58c76fc0a3";

//google
export const googleClientId = "529245241781-r00lvd4c7o116t7ikjtk3t6r7klbueqi.apps.googleusercontent.com"
export const googlePrivateKey = "h8kECnD9Y7SK12_Ltln1Mxzv"


export const getToken = () => {
    return localStorage.getItem('token');
}


//dil ayarları

export const getDefaultLanguage = () => {
    const lang = localStorage.getItem('language');
    return lang ? lang : "tr";
}
export const setDefaultLanguage = (lang) => {
    localStorage.setItem('language', lang);
    return lang
    /*
    import stores from "./stores"
    const token = await stores.UserStore.getToken()
    if (token) {
        stores.UserStore.setLanguage(lang)
    }
    localStorage.setItem('language', lang);
    return lang
    */
}

//yazdırma ayarları

const defaultPrintData = {
    descriptionen: "Please enter the link below from your web browser and read the code",
    descriptiontr: "Lütfen web tarayıcınızdan aşağıdaki linke girip kodu okutun",
    qrreadurl: "menu.piyanrest.com",
    size: "middle"
}

export const getPrintConfig = () => {
    const printconfig = localStorage.getItem('printconfig');
    let data = null
    try {
        data = JSON.parse(printconfig)
    } catch (error) {
        data = defaultPrintData
    }
    return (data && data.descriptionen != "") ? data : defaultPrintData
}
export const setPrintConfig = (data) => {
    try {
        localStorage.setItem("printconfig", JSON.stringify(data))
    } catch (error) {
        data = defaultPrintData
        localStorage.setItem("printconfig", JSON.stringify(data))
    }

    return data
}

const apiPrefix = '/api';


/// ürün ismi
export const appName = "ChatX";

//exports

export const API_URL = `${apiBaseUrl}/api`;

export const primaryColor = "#374A5D"


export const defaultImages = {
    userAvatar: "https://pistatic.piyanos.com/uploads/chat10/defaults/user_avatar.png",
    roomAvatar: "https://pistatic.piyanos.com/uploads/chat10/defaults/room_avatar.png"
}

export const siteInfo = {
    siteName: appName,
    copyright: `© ${appName} ${new Date().getFullYear()} `,
    description: "by Piyanos Software",
    logoUrl: 'https://pistatic.piyanos.com/uploads/chatx/chatx-v1-logo.png',
    logoWhiteUrl: 'https://pistatic.piyanos.com/uploads/chatx/logo-white-demo.png',
    iconUrl: 'https://pistatic.piyanos.com/uploads/chatx/chatx-v1.png',
    privacyUrl: 'https://www.privacypolicygenerator.info/live.php?token=Ejzu1wlZte9PQbodEaeUUfQWazywDNKE'
}

