
import { observable, action, computed, toJS, autorun } from 'mobx';

import _ from "lodash"

import axios from 'axios';

import { API_URL } from '../config'

import Auth from "./Auth";
import UserServices from '../services/User';

const service = new UserServices(localStorage.getItem('token'))

export default class User {

  constructor() {
    this.getToken();
    //  this.init();
    autorun(() => {
      //#	console.log(`User token : ${this.token}  `);
    })
  }

  // @observable token = null
  @observable token = localStorage.getItem('token')
  @observable notifications = []
  @observable user = null
  @observable users = []
  @observable loading = false
  @observable userDetail = {}
  @observable searchUsers = []


  @action init() {
    if (this.users.length == 0) {
      this.getUsers()
    }
  }


  ////// ADMİN
  @action adminLogin = (data) => {
    return service.adminLogin(data)
  }

  //kullanıcı listesi
  @action async getUsers() {
    this.loading = true
    try {
      const users = (await service.getUsers()).data;
      console.log({ users })
      this.users = users;
    } catch (error) {
      console.log({ error })
    } finally {
      this.loading = false;
    }
  }
  @action
  getUserProfile = async (item) => {
    this.loading = true
    try {
      const user = (await service.getUserProfile(item._id)).data
      console.log("!getUserProfile!", { user })
      this.userDetail = user;
      return Promise.resolve(user)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.loading = false
    }


    // this.loading = true
    // service.getUserProfile(item._id).then(result => {
    //   console.log("HATA!!!!!!!!!!SIZ!recep", result)
    //   this.userDetail = result.data
    //   return Promise.resolve(result.data)
    // }).catch(err => {
    //   console.log("HATA!!!!!!!!!!!recep", err)
    //   return Promise.reject(err)
    // }).finally(() => {
    //   this.loading = false
    // })

  }
  //#admin
  @action
  getUserBanOperations = async (item) => {
    try {
      const bans = (await service.getUserBanOperations(item._id)).data
      this.userDetail.bans = bans;
      console.log('====================================');
      console.log(toJS(bans));
      console.log('====================================');
    } catch (error) {
      console.log(error)
    }

  }
  //#admin
  @action
  updateUserFromAdmin = async (postData) => {
    try {
      const serverData = (await service.updateUserFromAdmin(postData)).data
      return Promise.resolve(serverData)
    } catch (error) {
      return Promise.reject(error)
    }


  }

  @action
  setUserBanOperations = async (item, status) => {
    const bans = (await service.setUserBanOperations({ status, id: item._id })).data
    console.log('================setUserBanOperations====================');
  }



  // kullanıcı ara
  @action async search(search) {
    this.loading = true;
    try {
      const serverData = (await service.search(search)).data
      this.searchUsers = serverData;

    } catch (error) {

    } finally {
      this.loading = false;
    }

  }


  //kullanıcı güncelle
  @action updateUser = (sendData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const updatedUser = (await service.updateMyProfile(sendData)).data;
        console.log("updateUser::", updatedUser)
        this.userDetail = updatedUser;
        return resolve(updatedUser)
      } catch (error) {
        console.error("UserStore updateUser ERROR !!", error.response)
        return reject(error)
      }
    })
  }

  //kullanıcı profil foto güncelle
  @action updateUserAvatar = (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const postImage = (await service.postUserAvatarImage(file)).data
        const postData = {
          avatar: postImage.path,
          type: "avatar"
        }
        const updatedUser = (await service.updateMyProfile(postData)).data;
        this.userDetail.avatar = postImage.path;
        console.log("updateUserAvatar:fotolu:", updatedUser)
        return resolve(updatedUser)
      } catch (error) {
        console.error("UserStore updateUserAvatar ERROR !!", error.response)
        return reject(error)
      }
    })
  }

  //kullanıcı şikayet
  @action
  reportUser = async (sendData) => {
    try {
      const serverData = (await service.reportUser(sendData)).data;
      console.log("reportUser::", serverData)
      return Promise.resolve(serverData)
    } catch (error) {
      console.error("UserStore reportUser ERROR !!", error.response)
      return Promise.reject(error)
    }
  }


  //sistem ayarları inputlar tetiklendiğinde
  @action systemSettingsOnChangeIn(name, value) {
    this.systemSettings[name] = value
  }
  //sistem ayarları yabancılar tetiklendiğinde
  @action systemSettingsOnChangeForeign(status = true) {
    this.systemSettings.foreign = status
  }



  //online kullanıcıları set et {id,userId,room}
  @action setOnlineUsers(array = []) {
    const usersIds = array.map(v => { return v.userId })
    this.users.map((v, i) => {
      this.users[i].online = false
      if (usersIds.includes(v._id)) {
        this.users[i].online = true
      }
    })
    this.users = JSON.parse(JSON.stringify(this.users))
  }


  //kullanıcı durumunu güncelle
  @action setActive(id, active) {
    const url = `${API_URL}/users/update-active/${id}` // /api/users/update-active/:id
    // this.loading = true
    return new Promise((resolve, reject) => {
      axios.post(url, { active }, { headers: { Authorization: this.token } }).then(res => {
        console.log({ res })
        const user = _.find(this.users, { _id: id })
        user.active = active
        this.users = this.users
        return resolve(res.data)
      }).catch(err => {
        console.log({ err: err.response })
        return reject(err)
      }).finally(() => {
        //   this.loading = false
      })
    })
  }

  //yeni bildirim ekle
  @action pushNotification(item) {
    this.notifications.push(item)
    /*
       const fI = this.notifications.findIndex(v => v.qr === item.qr_id)
    if(fI===-1){
      this.notifications.push(item)
    }
    */
    //////////
    //  this.OrderStore = new OrderState(this)
    //  this.OrderStore.setIsCallStatus(item.qr,true)
    //////////
    return this.notifications
  }
  //bildirim sil
  @action removeNotification(item, type = null) {
    if (!type) {
      const fI = this.notifications.findIndex(v => v.qr === item.qr_id)
      this.notifications.splice(fI, 1);
      ////////////////
      //   this.OrderStore = new OrderState(this)
      //    this.OrderStore.setIsCallStatus(item.qr,false)
      ////////////////
    }
    else if (type == "deleteAll") {
      this.notifications = []
    }
    // this.notifications=this.notifications
    return
  }

  //belirlenen id bildirimlerde içeriyor mu ?
  @action isIncludesInNotication(qrId) {
    const finded = _.find(this.notifications, { qr: qrId })
    console.log("isIncludesInNotication", { finded })
    return finded
  }


  //sisteme giriş
  @action async login(data) {
    const url = `${API_URL}/users/login`
    return axios.post(url, data);
  }


  //apikey almak
  @computed get getCompanyCode() {
    const companycode = localStorage.getItem('companycode');
    return companycode
  }

  //userId almak
  @computed get getUserId() {
    return localStorage.getItem('userid');
  }

  //şifremi değiştir
  @action resetPassword(obj) {
    const url = `${API_URL}/users/reset-password`
    return axios.post(url, obj, { headers: { Authorization: this.token } });
  }

  //şifremi unuttum #1
  @action async forgotPassword({email}) {
    const url = `${API_URL}/users/forgot`
    return axios.post(url, { email,date:new Date(),client:1 });
  }

  //şifre kurtarma #2
  //
  @action async getUserWithResetPasswordKey(resetPasswordKey) {
    const url = `${API_URL}/users/forgot/${resetPasswordKey}`
    return axios.get(url);
  }
  //şifre kurtarma set #3
  @action async recoveryPassword(data) {
    const url = `${API_URL}/users/recoverypassword`
    return axios.post(url, data);
  }


  //kayıt ol
  @action async register(data) {
    const url = `${API_URL}/users`
    const toServerData = JSON.parse(JSON.stringify(data))
    this.loading = true
    //gereksizleri kaldır
    _.unset(toServerData, "userTypes")
    _.unset(toServerData, "workTypes")
    return new Promise((resolve, reject) => {
      axios.post(url, toServerData, { headers: { Authorization: this.token } }).then(res => {
        console.log({ res })
        return resolve(res.data)
      }).catch(err => {
        console.log({ err: err.response })
        return reject(err)
      }).finally(() => {
        this.loading = false
      })
    })
  }

  //sistemden çıkış
  @action async logout() {
    this.removeToken()
    //  this.props.history.push("/login");
  }
  // getOut Sistemden Atma 
  @action  async getOut(id) {
    let api = `${API_URL}/users/get-out/${id}`;
    return axios.get(api, { headers: { Authorization: this.token } });
  }


  //profilimi getir
  @action async getProfile(userId = null) {
    let api = `${API_URL}/users/me`;
    if (userId) api = `${API_URL}/users/${userId}`;
    try {
      const userProfile = await axios.get(api, { headers: { Authorization: this.token } });
      console.log({ userProfile })
      this.name = _.get(userProfile, "data.user.name")
      this.username = _.get(userProfile, "data.user.username")
      this.user = _.get(userProfile, "data.user")

      return userProfile
    } catch (error) {
      console.log("error getProfile", error)
      /////////////////////  this.removeToken()
      return Promise.reject(error)

    }
  }


  //kullanıcı bakiyesi set et
  @action setUserBalance(balance, id = null) {
    if (!id) {
      this.user.balance = balance
    }

  }

  //kullanıcı limit ata (aktif kullanıcı)
  @action async setUserLimit(credit = 0) {
    this.user.credit = credit
  }

  //kullanıcı adını al
  @action async getUsername() {
    let api = `${API_URL}/users/me`;
    return new Promise((resolve, reject) => {
      axios.get(api, this.getHeader()).then((res) => {
        let username = _.get(res, "data.user.username")
        return resolve(username)
      }).catch((err) => {
        return reject(err)
      })
    })
  }



  //iki kişi arasındaki mesajları getir
  @action async getPrivateMessage(userId = null) {
    //userId  : karşı kişinin id si
    let api = `${API_URL}/privatemessages`; ///api/privatemessages/:id'
    //  let api = `${API_URL}/channels/5d4ebce32a6a5825c81ca20f`; ///api/privatemessages/:id'
    if (userId) api = `${API_URL}/privatemessages/${userId}`;
    return axios.get(api, { headers: { Authorization: this.token } });
  }

  // mesajlarımı getir
  @action async getMessagesList(limit = 20, offset = 0) {
    let api = `${API_URL}/me/privatemessages`;
    if (limit != 20 && offset != 0) api = `${API_URL}/me/privatemessages?limit=${limit}&offset=${offset}`;
    return axios.get(api, { headers: { Authorization: this.token } });
  }

  //benim kullanıcı kimliği (id)
  @action getMyId = async () => {
    const api = `${API_URL}/users/me/id`;
    let resData = await axios.get(api, { headers: { Authorization: this.token } });
    return _.get(resData, "data._id")

  }
  // kullanıcı dilini set et
  @action setLanguage = async (lang) => {
    const api = `${API_URL}/users/me/lang`;
    return axios.post(api, { lang }, { headers: { Authorization: this.token } });
  }





  getHeader() {
    this.getToken();
    return { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
  }




  @action async saveCompanyCode(token) {
    return localStorage.setItem('companycode', token);
  }

  @action async saveUserId(id) {
    return localStorage.setItem('userid', id);
  }

  // TOKEN İŞLEMLERİ
  @action async saveToken(token) {
    this.token = token
    return localStorage.setItem('token', token);
  }

  @action async removeToken() {
    this.token = null;

    localStorage.removeItem('companycode');
    return localStorage.removeItem('token');
  }

  @action async getToken() {
    const token = localStorage.getItem('token');
    //#   console.log("User store getToken() :",token)
    this.token = token;
    if (!this.user && token) {
      //  console.log("USER STORE user YOK!!!!!!!!!!!!!!!!!")
      this.getProfile()
      /*
      setTimeout(() => {
        this.getProfile()
      }, 5000)
      */
    }
    return token;

  }




  //sistem ayarları getir
  @action async getSystemSettings(userId = "null") {
    let api = `${API_URL}/admin/system-settings?user=${userId}`;

    return new Promise(async (resolve, reject) => {
      try {
        let systemSettings = await axios.get(api, { headers: { Authorization: this.token } });
        systemSettings = _.get(systemSettings, "data")
        console.log({ systemSettings })
        this.systemSettings = systemSettings
        localStorage.setItem('systemSettings', JSON.stringify(systemSettings));
        return resolve(systemSettings)
      } catch (error) {
        return reject(error)

      }
    })
  }

  //sistem ayarları set et
  @action async setSystemSettings(body) {
    let api = `${API_URL}/admin/system-settings`;

    return new Promise(async (resolve, reject) => {
      try {
        let systemSettings = await axios.post(api, body, { headers: { Authorization: this.token } });
        systemSettings = _.get(systemSettings, "data")
        console.log({ systemSettings })
        this.systemSettings = systemSettings
        localStorage.setItem('systemSettings', JSON.stringify(systemSettings));
        return resolve(systemSettings)
      } catch (error) {
        return reject(error)

      }
    })
  }



}

/*
{"userId":"5d9cedefadc4e60028be935a","created":"2020-03-29T16:57:22.391Z","_id":"5e80d372ab2bb600287c0a91","user":{"_id":"5d9cedefadc4e60028be935a","name":"ferdi","email":"info@ferdiozer.com","gender":true,"username":"","avatar":"https://api.adorable.io/avatars/100/$2b$10$Q.rMwJbX6ckPTWzF3YN6LujedDU97AKnsEvnl4q6NfHUAi0MBq1wi.png","gift":[],"level":0,"flower":0,"year":null,"location":"Türkiye","created":"2019-10-08T20:13:35.471Z"}}

*/

