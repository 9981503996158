

import Resizer from "react-image-file-resizer";

import QRCode from 'qrcode'

import moment from 'moment'


export const serialize = (obj) =>{
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

export const qrb64 = async (text, width = 128) => {
    // color:{dark:"#293F68",light:"#FFF"}
    return new Promise((resolve, reject) => {
        QRCode.toDataURL(text, { width }).then(url => {
            return resolve(url)
        }).catch(err => {
            return reject(err)
        })
    })
}

export const qrCodeB64 = async (text, width = 128) => {
    const data = await QRCode.toDataURL(text, { width })
    return data
}


export const dateFormatedYMD = (customDate = null) => {
    let mainDate = new Date()
    if (customDate) mainDate = new Date(customDate)
    let thisMonth = mainDate.getMonth() + 1 // ("0" + (this.getMonth() + 1)).slice(-2)
    if (thisMonth < 10) thisMonth = `0${thisMonth}`
    let thisDay = mainDate.getDate()
    if (thisDay < 10) thisDay = `0${thisDay}`
    const todayFormated = `${mainDate.getFullYear()}${thisMonth}${thisDay}`

    return todayFormated
}

export const date2DD = ({ TARIH, SAAT, setMinute = null }) => {
    const moment1 = moment(`${TARIH} ${SAAT}`, "DD/MM/YYYY H:mm")
    const date = moment1.toDate()
    if (setMinute) {
        date.setMinutes(date.getMinutes() - 1)
        //date.setDate(runDate.getMinutes() - 1)
    }
    return date // moment1.toDate()
}

export const tarihToYYYYMMDD = ({ TARIH }) => {
    const moment1 = moment(`${TARIH}`, "DD/MM/YYYY")
    const date = moment1.toDate()
    return moment(date).format("YYYYMMDD")
}

export const formatMyMoney = (price) => {

    var currency_symbol = "₺"

    var formattedOutput = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2,
    });

    return formattedOutput.format(price).replace(currency_symbol, '')
}

const checkImageWidth = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
                resolve(image.width);
                return image.width;
            };
            reader.onerror = (err) => reject(err);
        };
    });
};

const resizeImage = (file) => {
    let quality = 100;
    //4MB image file
    if (file.size > 4000000) {
        quality = 90;
    }
    //8MB image file
    if (file.size > 8000000) {
        quality = 85;
    }
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            600,
            600,
            "JPEG",
            quality,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });
};
export { resizeImage, checkImageWidth };

const usernameRegex = /^[a-zA-Z0-9]+([a-zA-Z0-9](_|-| )[a-zA-Z0-9])*[a-zA-Z0-9]+$/;
