import React, { Component } from "react";

import _ from 'lodash';

//import HomeSlider from '../components/HomeSlider'


import { withTranslation } from 'react-i18next';


//import {openNotification} from "../components/Notification"

import { inject, observer } from 'mobx-react';


import HomeSlider from "../components/HomeSlider";
import { Col, Row } from "antd";
import DashItem from "../components/DashItem";
import PageHeader from "../components/PageHeader";

/*
import QrCodeContainer from "../components/QrCodeContainer";
import QrCode from "../components/QrCode";
*/


@inject('UserStore', 'AdminStore')
@observer
class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      data: [],
    }
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
  }


  render() {

    const { homeSliders, rooms } = this.props.AdminStore
    const { users } = this.props.UserStore

    const { t } = this.props

    return (
      <div>
        <PageHeader title="Kontol Paneli" subTitle="Anasayfa" noback />
        <Row>
          <Col span={12}>
            <HomeSlider data={homeSliders} />
          </Col>
          <Col span={3}>
            <DashItem title={users.length} subTitle="Kullanıcı" />
            <DashItem title={homeSliders.length} subTitle="Slider" />
            <DashItem title={rooms.length} subTitle="Oda" />
            <DashItem title={_.get(this.props.AdminStore, "feedbackList", []).length} subTitle="Geri Bildirim" />
          </Col>
        </Row>

      </div>
    )
  }

}

export default withTranslation()(Home)