import React, { Component } from "react";

import { HashRouter, Route, Link } from 'react-router-dom';

import { Carousel } from 'antd';

export default (
    {
        data = []
    }
) => {
    return (
        <div>
            <Carousel arrows={true} autoplay={true} style={{}}>
                {
                    data.map((v, i) => {
                        return (
                            <div key={i} style={{}}>
                                <img
                                    className="home-slider-img"
                                    height={300}
                                    style={{
                                        borderRadius: 10,
                                        borderRadius: '50px!important'
                                    }}
                                    src={v.img_url}
                                    alt="" />
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}



