import React, { Component } from "react";
import { Link, } from 'react-router-dom'; // Link,
import { Tabs, Tooltip, Table, Menu, Row, Col, Button, Badge, Modal, Checkbox, Input, Spin, message, List, Card, Avatar, Tag } from 'antd';

import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios'

import _ from 'lodash';
import moment from 'moment';



import { UserOutlined, SearchOutlined, UserAddOutlined, CloseCircleOutlined, CloseOutlined, MessageOutlined } from '@ant-design/icons';

import { inject, observer } from 'mobx-react';

import { goPage, goBack } from '../../historyConfig'
import { defaultImages } from "../../config";



@inject('AdminStore', 'UserStore')
@observer
export default class PaymentsList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false
        }
    }

    componentDidMount = () => {
        this.itemList();
    }


    itemList = async () => {
        this.setState({ loading: true })
        await this.props.AdminStore.getPayments()
        this.setState({ loading: false })
    }


    onChangeTabs = (activeTabsKey) => {
        console.log(activeTabsKey)
        this.setState({
            activeTabsKey
        })
    }




    render() {
        const { loading } = this.state
        const dataSource = _.get(this.props, "AdminStore.payments", [])


        const columns = [
            {
                title: 'Tarih',
                key: 'created',
                sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
                render: (text, record) => <Tooltip title={moment(record.created).fromNow()}>
                    {new Date(record.created).toLocaleDateString()}
                </Tooltip>
            },
            {
                title: 'Kullanıcı',
                key: 'user',
                width: '25%',
                render: (text, record) =>
                    <span>
                        <Link to={`/user/${_.get(record, "user._id")}`}>
                            <span>
                                <img style={{ height: 20, width: 20, borderRadius: 10 }} src={_.get(record, "user.avatar") ? _.get(record, "user.avatar") : defaultImages.userAvatar} alt={_.get(record, "user.username")} />
                                {_.get(record, "user.name")}
                            </span>
                        </Link>
                    </span>,
            },

            {
                title: 'Hediye',
                key: 'img_url',
                className: 'drag-visible',
                render: (text, record) =>
                    <span>
                        <Tag color="gold" title="Tutar">
                            {_.get(record, "product.originalPriceAndroid")}
                        </Tag>
                        <Tag title="Hediye Kodu">
                            {_.get(record, "productId")}
                        </Tag>
                        <span>
                            {_.get(record, "product.title")}
                            /
                            {_.get(record, "product.description")}
                        </span>

                    </span>,
            },
            {
                title: ' ',
                key: 'action',
                render: (text, record) =>
                    <span>
                        {_.get(JSON.parse(_.get(record, "product.originalJson", {})), "name", "")}
                    </span>
                ,
            },
        ];


        return (
            <div>
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey="_id"
                    loading={loading}
                />
            </div>
        )
    }

}