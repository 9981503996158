import { createHashHistory,createBrowserHistory } from 'history'


export const history = createBrowserHistory()


//const location = history.location;

export const goPage=(route="")=>{
 //   history.push(`/${route}`)
  //  history.go(`/${route}`)
 // history.replace(`/${route}`)
  // this.context.history.push(`/${route}`)
  window.location = `/${route}`   // SAÇMA OLDU ANT_MARK....

 }
 
 export const goBack=()=>{
    history.goBack()
 }

 /*

//import React from 'react'

//import {useHistory,Link,Redirect} from "react-router-dom"


export const goPage=(route="")=>{
   // history.push(`/${route}`)
  //  history.replace(`/${route}`)
 // window.location = `/${route}`
 // useHistory().push(`/${route}`)
 return (<Redirect to={`/${route}`}/>)
}

export const goBack=()=>{
   // history.goBack()
  // useHistory().goBack()
}

*/