import React, { useEffect, useRef, useState } from 'react';
import Compose from '../Compose';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import Message from '../Message';
import moment from 'moment';


import { inject, observer } from 'mobx-react';

import './MessageList.css';
import NotSelectConversation from '../NotSelectConversation';
import { LoadingTop } from '../../LoadingTop';

import { Link } from 'react-router-dom';



const MessageList = (props) => {

  const initialOffsetLimit = {
    offset: 0,
    limit: 20
  }

  const [offsetLimit, setOffsetLimit] = useState(initialOffsetLimit);

  const [scrollTop, setScrollTop] = useState(false);

  const messagesEndRef = useRef(null)
  const messagesDivRef = useRef(null)

  const MY_USER_ID = _.get(props, "UserStore.user._id")

  let { messages } = props.ChatStore
  const { loadingMessages, typing, selectedConversationItem } = props.ChatStore

  const scrollToBottom = () => {

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    // if (!scrollTop) {
    //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    // }

    // let areaHeight = messagesDivRef.current?.scrollHeight
    // areaHeight = areaHeight - (areaHeight / 2)

    ///messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    //messagesEndRef.current?.scrollIntoView(false)

    //  if (areaHeight < scrollTop) {   //if (areaHeight < scrollTop) {
    //    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    //    // messagesEndRef.current?.scrollIntoView(false)
    // messagesEndRef.current?.scrollIntoView(true)
    //    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    //  }

    //messagesEndRef.current?.scrollIntoView({
    //  behavior: 'smooth',
    //  block: 'end',
    //  inline: 'nearest'
    //})
    // if (scrollTop !== 0) {
    //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    // }

  }

  useEffect(scrollToBottom, [JSON.parse(JSON.stringify(messages))]);
  //useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])


  const onChangeTyping = (text) => {
    props.ChatStore.onChangeTyping(text)
  }
  const onPressSend = () => {
    props.ChatStore.sendMessage()
    // scrollToBottom()  //GEREK KALMADI

  }

  const loadMoreItems = async () => {
    props.ChatStore.getOtherMessagesInConversation(offsetLimit.offset, offsetLimit.limit)
    setOffsetLimit({ ...offsetLimit, offset: offsetLimit.offset + offsetLimit.limit })
    /*
    const dataLength = _.get(selectedConversationItem, "count", 0);
    if (dataLength > messages.length) {
      try {
        const messages = await props.ChatStore.getOtherMessagesInConversation(offsetLimit.offset, offsetLimit.limit)
        await props.ChatStore.concatMessages(messages)
        setOffsetLimit({ ...offsetLimit, offset: offsetLimit.offset + offsetLimit.limit })
      } catch (error) {
        console.log(error)
      }
    }
    */
  }

  const handleScroll = e => {
    let element = e.target;
    // const scrollHeight = messagesDivRef.scrollHeight;
    // const height = messagesDivRef.clientHeight;
    // const shouldScrollBottom = messagesDivRef.scrollTop + messagesDivRef.offsetHeight === messagesDivRef.scrollHeight;
    // console.log({ h: height, f: messagesDivRef.current.offsetTop, ff: messagesDivRef.current.scrollHeight, fff: element.scrollTop })
    // //  console.log({ height, scrollHeight, scrollTop: element.scrollTop, element: element.clientHeight })
    // setScrollTop(element.scrollTop)

    if (element.scrollTop === 0) {
      element.scrollTo(0, 20)
      loadMoreItems()
      setScrollTop(true)
    }

    // const dataLength = _.get(selectedConversationItem, "count", 0);
    //
    // let element = e.target;
    // console.log(":::handleScroll:0:::", element.scrollTop)
    // setScrollTop(element.scrollTop)
    // if ((element.scrollTop === 0) && dataLength > messages.length) {
    //   //fetch messages
    //   //  loadMoreItems()
    // }

    // if ((element.scrollTop === 0) && dataLength > messages.length) {
    //   //fetch messages
    //   loadMoreItems()
    // }
  }



  const renderMessages = () => {
    let i = 0;
    let messageCount = messages.length;
    let tempMessages = [];

    messages = messages.map(v => { return { ...v, author: v.fromUser, timestamp: v.created } })

    while (i < messageCount) {
      let previous = messages[i - 1];
      let current = messages[i];
      let next = messages[i + 1];
      let isMine = current.author === MY_USER_ID;
      let currentMoment = moment(current.timestamp);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.timestamp);
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.author === current.author;

        if (prevBySameAuthor && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }

        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.timestamp);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.author === current.author;

        if (nextBySameAuthor && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  }


  if (!selectedConversationItem) {
    return (
      <NotSelectConversation title="Mesajları Görüntülemek İçin Konuşmayı Seçiniz.." />
    )
  }



  return (
    <div className="message-list">
      <Toolbar
        user={selectedConversationItem.user}
        rightItems={[
          <Link key="info" to={`/user/${_.get(selectedConversationItem, "user._id", "")}`}><ToolbarButton icon="ion-ios-information-circle-outline" /></Link>
        ]}
      />

      <div ref={messagesDivRef} onScroll={handleScroll} className="message-list-container"
        style={{ height: 'calc(100vh - 127px)', overflowY: "scroll" }}
      >
        {loadingMessages && <LoadingTop />}
        {renderMessages()}
        <div ref={messagesEndRef} />
      </div>


      <Compose
        typing={typing}
        onChangeTyping={onChangeTyping}
        onPressSend={() => onPressSend()}
      />
    </div>
  );
}

export default inject((stores) => (stores))(observer(MessageList));