import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//dil
import './i18n'
import App from './App';
import Main from "./Main"



import { Provider } from 'mobx-react';

import stores from './stores';


import * as serviceWorker from './serviceWorker';


ReactDOM.render(
  <Provider {...stores}>
    <Main>
      <App />
    </Main>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//ben kaldrdım
//serviceWorker.unregister();
