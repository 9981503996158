
import React, {Component}from 'react';
import { Result, Button } from 'antd';

import {Link} from 'react-router-dom';

export default class NoFound extends Component{


    render(){

        return(
            <Result
    status="404"
    title="404"
    subTitle="Üzgünüm, aradığınız sayfa bulunamadı."
    extra={<span><Link to="/"><Button type="primary">Anasayfaya Git</Button></Link></span>}
  />
        )
    }
}