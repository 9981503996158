import React, { Component } from "react";
import { Link, } from 'react-router-dom'; // Link,
import { Tabs, Layout, Menu, Row, Col, Button, Badge, Modal, Checkbox, Input, Spin, message, List, Card, Avatar } from 'antd';

import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios'

import _ from 'lodash';


import { UserOutlined, SearchOutlined, UserAddOutlined, CloseCircleOutlined, CloseOutlined, MessageOutlined } from '@ant-design/icons';

import { inject, observer } from 'mobx-react';

import { goPage, goBack } from '../historyConfig'
import { defaultImages } from "../config";
import MyPageHeader from "../components/MyPageHeader";



const fakeDataUrl = 'https://randomuser.me/api/?results=15&inc=name,gender,email,nat&noinfo';




@inject('UserStore')
@observer
export default class Search extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      search: '',
      activeTabsKey: 'user',
      hasMore: true,
      limit: 20,
      offset: 0,
      initSearch: false,
      loading: false
    }
  }

  getItems = () => {

    //  this.setState({ loading: true })
    if (!this.state.initSearch) {
      this.setState({ initSearch: true })
    }

    const search = _.get(this.state, "search", "").trim()
    if (search.length < 3) {
      return
    }
    this.props.UserStore.search(search)

    // .then((res) => res.data)
    //   .then((data) => {
    //     console.log("search getItems", data)
    //     this.setState({
    //       data: data
    //     })
    //   }).finally(() => {
    //     this.setState({ loading: false })
    //   })
  }

  componentDidMount = async () => {
    //  this.getItems()

    /*
      if(data.data){
        this.setState({
          data:data.data
        })
      }
      */

    /*
  
    this.fetchData(res => {
      console.log(res)
      console.log("res")
    this.setState({
      data: res.data.results,
    });
  });
  
  */

  }

  onClickMessage = (item) => {
    const url = `messenger/${item._id}`

    goPage(url)

    //v1  HOOK
    // import { useHistory } from 'react-router-dom';
    // const history = useHistory();
    // history.push(`/message/${item._id}`);
  }


  fetchData = callback => {
    axios.get(fakeDataUrl).then((result) => {
      console.log(result)
      callback(result)

    })


  };


  handleInfiniteOnLoad = () => {
    let { data } = this.state;
    this.setState({
      loading: true,
    });
    if (data.length > 14) {
      //   message.warning('tümü yüklendi');
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }

    this.fetchData(res => {
      data = this.state.data.concat(res.data.results);
      this.setState({
        data,
        loading: false,
      });
    });
  };

  onChangeTabs = (activeTabsKey) => {
    console.log(activeTabsKey)
    this.setState({
      activeTabsKey
    })
  }


  render() {
    const { initSearch } = this.state
    const data = this.props.UserStore.searchUsers
    const { user } = this.props.UserStore
    const { loading } = this.props.UserStore
    return (
      <div>
        <MyPageHeader title="Ara" subTitle="Aramak istediğiniz kullanıcıyı burada bulabilirsiniz" />
        <Row>
          <Col span={24}>
            <Input
              value={this.state.search}
              prefix={
                this.state.search.length > 0 && <Button shape="circle" onClick={() => this.setState({ search: '' })}>
                  <CloseOutlined />
                </Button>
              }
              onPressEnter={() => this.getItems()}
              placeholder="Aramak istediğiniz kelimeyi buraya giriniz"
              onChange={e => this.setState({ search: e.target.value })}
              autoFocus={true}
              style={{
                borderRadius: 21
              }}
              suffix={<Button
                onClick={() => this.getItems()}
                shape="circle">
                <SearchOutlined />
              </Button>}
            />
          </Col>
        </Row>

        <Row
          style={{
            padding: '0 50px'
          }}
        >

        </Row>


        <div className="infinite-container" style={{
          //  padding: '0 50px',
          //    height: 400,
          scrollbarTrackColor: 'red',
          marginTop: 10
        }}>
          <InfiniteScroll

            initialLoad={false}
            pageStart={0}
            loadMore={() => this.handleInfiniteOnLoad()}
            hasMore={!this.state.loading && this.state.hasMore}
            useWindow={false}
          >
            <List

              loading={loading}
              dataSource={data}
              renderItem={item => (
                <List.Item key={item._id}>

                  <List.Item.Meta
                    avatar={
                      <Link to={`/user/${item._id}`}>
                        <Avatar src={item.avatar ? item.avatar : defaultImages.userAvatar} />
                      </Link>
                    }
                    title={<Link to={`/user/${item._id}`}>{item.name}</Link>}
                    description={item.username}
                  />
                  {
                    _.get(user, "_id") !== item._id
                    &&
                    <Link to={`/messenger/${item._id}`}><MessageOutlined /></Link>
                  }
                </List.Item>
              )}
            >

            </List>
          </InfiniteScroll>
        </div>

      </div>
    )
  }

}