import axios from "axios";

import { API_URL, cdnConfig, cdnUrl } from "../config";
import { serialize } from "../helper";
//const FormData = require('form-data')

export default class AdminServices {
    constructor(token) {
        this.axiosConfig = { headers: { Authorization: token, 'Content-Type': 'application/json' } }
    }


    /////////////////////USERS
    getUsers({page,limit,publicid}) {
        //const url = `${API_URL}/admin/usersv1?page=${page}&limit=${limit}`;
        const url = `${API_URL}/admin/usersv1?${serialize({page,limit,publicid})}`;
        return axios.get(url, this.axiosConfig);
    }
    /////////////////////USERS END

    /////////////////////USER TOKENS
    getUserTokens(userId) {
        //const url = `${API_URL}/admin/usersv1?page=${page}&limit=${limit}`;
        const url = `${API_URL}/admin/get-user-tokens/${userId}`;
        return axios.get(url, this.axiosConfig);
    }
    removeUserTokens(id) {
        const url = `${API_URL}/admin/get-user-tokens/${id}`;
        return axios.delete(url, this.axiosConfig);
    }
    removeUserAllTokens(userId) {
        const url = `${API_URL}/admin/remove-user-tokens-all/${userId}`;
        return axios.delete(url, this.axiosConfig);
    }
    /////////////////////USER TOKENS END


    

    getHomeSliders() {
        const url = `${API_URL}/slider`;
        return axios.get(url, this.axiosConfig);
    }

    uploadFile(file, key) {
        const FormData = require('form-data')
        const url = `${cdnUrl}`;
        const formData = new FormData();
        formData.append('token', cdnConfig.token);
        formData.append('key', cdnConfig.key);
        formData.append('subkey', key ? key : "slider");
        formData.append('file', file)
        const headers = { headers: { 'Content-Type': 'multipart/form-data' } }
        return axios.post(url, formData, headers);
    }
    ////////////////  -home slider - /////////////////////////////////////////////////////////
    createHomeSlider(data) {
        const url = `${API_URL}/admin/slider`;
        return axios.post(url, data, this.axiosConfig);
    }

    deleteHomeSlider(id) {
        const url = `${API_URL}/admin/slider/delete/${id}`;
        return axios.post(url, {}, this.axiosConfig);
    }
    updateHomeSliderStatus(id, data) {
        const url = `${API_URL}/admin/slider/update/${id}`;
        return axios.post(url, data, this.axiosConfig);
    }
    updateHomeSliderSort(data) {
        const url = `${API_URL}/admin/slider/update-sort`;
        return axios.post(url, data, this.axiosConfig);
    }
    ////////////////  -home slider -end
    deleteRoom(id) {
        const url = `${API_URL}/admin/room/delete/${id}`;
        return axios.post(url, {}, this.axiosConfig);
    }
    getRooms(page = 0,limit = 10) {
        const url = `${API_URL}/admin/rooms?limit=${limit}&page=${page}`;
        return axios.get(url, this.axiosConfig)
    }
    updateRoom(id, data) {
        const url = `${API_URL}/admin/room/update/${id}`;
        return axios.post(url, data, this.axiosConfig);
    }
    ////////////////////////////// # GERİ BİLDİRİMLER  /////////////////////////////////////////
    getFeedbacklist() {
        const url = `${API_URL}/admin/feedback`;
        return axios.get(url, this.axiosConfig)
    }

    deleteFeedback(id) {
        const url = `${API_URL}/admin/delete-feedback/${id}`;
        return axios.post(url, {}, this.axiosConfig);
    }
    ////////////////////////////// # GERİ BİLDİRİMLER -end- /////////////////////////////////////////
    createRoom(userId) {
        const url = `${API_URL}/users/${userId}`;
        return axios.get(url, this.axiosConfig).then(res => res.data);
    }

    postSliderImage(photo) {
        /*
        const file = {
            name: photo.fileName,
            type: photo.type,
            uri: photo.uri
        }
        */
        /*
     formData.append('file', {
         name: photo.fileName,
         type: photo.type,
         uri: photo.uri
     }
     );
 */
        const FormData = require('form-data')
        const url = `${cdnUrl}`;
        const formData = new FormData();
        formData.append('token', cdnConfig.token);
        formData.append('key', cdnConfig.key);
        formData.append('subkey', "slider");
        formData.append('file', photo)
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.post(url, formData, headers);
    }

    sendPushNotification(data) {
        const url = `${API_URL}/admin/notification/send`;
        return axios.post(url, data, this.axiosConfig);
    }

    ////////////////////////////// # HEDİYELER  /////////////////////////////////////////
    getGifts() {
        const url = `${API_URL}/admin/gifts`;
        return axios.get(url, this.axiosConfig);
    }
    createGift(data) {
        const url = `${API_URL}/admin/gifts`;
        return axios.post(url, data, this.axiosConfig);
    }

    updateGifts(id, data) {
        const url = `${API_URL}/admin/gifts/update/${id}`;
        return axios.post(url, data, this.axiosConfig);
    }
    deleteGift(id) {
        const url = `${API_URL}/admin/gifts/delete/${id}`;
        return axios.post(url, {}, this.axiosConfig);
    }
    ////////////////////////////// # HEDİYELER -end  /////////////////////////////////////////
    ////////////////////////////// # ÜRÜNLER  ////////////////////////////////////////////////
    getPayments() {
        const url = `${API_URL}/admin/payments`;
        return axios.get(url, this.axiosConfig);
    }

    getProducts() {
        const url = `${API_URL}/admin/products`;
        return axios.get(url, this.axiosConfig);
    }

    setProducts(data) {
        const url = `${API_URL}/admin/products`;
        return axios.post(url, data, this.axiosConfig);
    }
    deleteProducts(id) {
        const url = `${API_URL}/admin/products/delete/${id}`;
        return axios.post(url, {}, this.axiosConfig);
    }
    updateProducts(id, data) {
        const url = `${API_URL}/admin/products/update/${id}`;
        return axios.post(url, data, this.axiosConfig);
    }
    ////////////////////////////// # ÜRÜNLER -end  /////////////////////////////////////////
    ////////////////////////////// # TAGS  ////////////////////////////////////////////////
    getTagList() {
        const url = `${API_URL}/get-tag-list`;
        return axios.get(url, this.axiosConfig);
    }
    setTag(data) {
        const url = `${API_URL}/admin/get-tag-list`;
        return axios.post(url, data, this.axiosConfig);
    }
    updateTag(id, data) {
        const url = `${API_URL}/admin/get-tag-list/update/${id}`;
        return axios.post(url, data, this.axiosConfig);
    }
    deleteTag(id, data) {
        const url = `${API_URL}/admin/get-tag-list/delete/${id}`;
        return axios.post(url, data, this.axiosConfig);
    }
    ////////////////////////////// # TAGS -end  /////////////////////////////////////////





}