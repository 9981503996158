

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { inject, observer } from 'mobx-react';

import Loading from "./Loading"

import { Layout, Menu, Dropdown, message, Button, BackTop } from 'antd';
import { Link, Redirect } from 'react-router-dom';

@inject('UserStore')
@observer
class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        }
    }

    render() {
        const { loading } = this.state
        const token = this.props.UserStore.token

        const { children, user } = this.props

        if (loading) {
            return (<Loading />)
        }
        else {
            return (
                <div>

                    {
                        !token && <Redirect to="/login" />
                    }
                    {children}
                </div>
            )
        }
    }
}

export default withTranslation()(Container)
