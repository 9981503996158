import React, { Component } from "react";

import { Row, Col, message, Form, Table, Popconfirm, Divider, Upload, Switch, Button, Menu, Dropdown, Input } from 'antd'
import { inject, observer } from 'mobx-react';

import PageHeader from "../../components/PageHeader";


import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
    DeleteOutlined,
    ArrowLeftOutlined,
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
    MessageOutlined,
    EditOutlined,
    CheckOutlined,
    CameraOutlined,
    StopOutlined,
    CloseOutlined,
    MoreOutlined,
    MenuOutlined,
    PlusOutlined
} from '@ant-design/icons';
import _ from "lodash";


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

@inject('AdminStore', 'UserStore')
@observer
export default class HomeSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            show: false,
            isShortable: false
        };
    }


    componentDidMount = () => {
        this.itemList();
    }

    itemList = () => {
        this.props.AdminStore.getProducts()
    }



    delete = (item) => {
        this.props.AdminStore.deleteProducts(item._id)
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }



    submit = async () => {

        const { _id, productId, title, gold_count } = this.state
        console.log(this.state)
        //GÜNCELLE
        if (_id) {
            if (!productId || !title || !gold_count) {
                message.error("Lütfen tüm alanları doldurun!")
                return;
            }

            try {
                await this.props.AdminStore.updateProducts(this.state)
                this.resetState()
            } catch (error) {
                console.log({ error })
            }
        }
        //YENİ
        else {
            if (!productId || !title || !gold_count) {
                message.error("Lütfen tüm alanları doldurun!")
                return;
            }

            try {
                await this.props.AdminStore.setProducts(this.state)
                this.resetState()
            } catch (error) {
                console.log({ error })
            }
        }

    }

    resetState = () => {
        this.setState({
            public_id: null,
            title: null,
            price: null,
            img_url_file: null,
            animation_url_file: null,
            show: false,
            _id: ''
        })
    }


    render() {
        const { show, } = this.state;

        const data = _.get(this.props, "AdminStore.products", [])
        const { loading } = this.props.AdminStore


        const columns = [
            {
                title: '#ID',
                key: 'productId',
                dataIndex: 'productId',

            },
            {
                title: 'Başlık',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Altın Miktarı',
                dataIndex: 'gold_count',
                key: 'gold_count',
            },
            {
                title: 'İşlem',
                key: 'action',
                render: (text, record) =>
                    <span>

                        <Popconfirm okText="Evet" cancelText="Hayır" title="Silmek istediğinize emin misiniz ?" onConfirm={() => this.delete(record)}>
                            <Button size='small' type='danger'>
                                Sil
                            </Button>
                        </Popconfirm>
                        <Divider type="vertical" />
                        <Button size='small'
                            onClick={() => this.setState({ show: true, ...record })}
                        >
                            Düzenle
                        </Button>
                    </span>

            },
        ];


        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
        };
        const buttonItemLayout = {
            wrapperCol: { span: 14, offset: 4 },
        };

        return (
            <div>

                <PageHeader
                    subTitle={data.length > 0 ? `Toplam ${data.length} ürün bulundu` : 'Ürün bulunmadı'}
                    title="Ürün Listesi"
                    extra={
                        <span>
                            {show
                                &&
                                <Button onClick={() => this.resetState()} type="danger">Kapat</Button>
                            }

                            <Button
                                shape="circle-outline"
                                style={{ marginLeft: 5 }}
                                icon={<PlusOutlined />}
                                onClick={() => { this.setState({ show: !show }) }}
                            >
                                YENİ
                            </Button>

                        </span>

                    }
                />

                {
                    show
                    &&
                    <div style={{ paddingBottom: 20 }}>

                        <Form layout={"horizontal"}>

                            <Row>
                                <Col offset="4">
                                    <h4>
                                        {
                                            this.state._id ? "DÜZENLE" : "YENİ"
                                        }
                                    </h4>
                                </Col>
                            </Row>

                            <Form.Item label="Ürün Kimliği" {...formItemLayout}>
                                <Input value={this.state.productId} name="productId" onChange={this.onChange} />
                            </Form.Item>

                            <Form.Item label="İsim" {...formItemLayout}>
                                <Input value={this.state.title} name="title" onChange={this.onChange} />
                            </Form.Item>

                            <Form.Item label="Altın Miktarı" {...formItemLayout}>
                                <Input value={this.state.gold_count} name="gold_count" onChange={this.onChange} type="number" />
                            </Form.Item>

                            <Form.Item {...buttonItemLayout}>
                                <Button loading={loading} onClick={() => this.submit("submit")}>Kaydet</Button>
                                {show
                                    &&
                                    <Button onClick={() => this.resetState()} type="danger">Kapat</Button>
                                }
                            </Form.Item>

                        </Form>

                    </div>
                }


                <div style={{ height: 20 }} />
                <Table
                    rowKey={'_id'}
                    pagination={false}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                />


            </div>
        )
    }

}







