import { padEnd } from "lodash";
import React from "react";

export default ({ title, subTitle }) => {
    return (
        <div style={{ padding: 10, backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: 10, margin: '0 0 10px 10px' }}>
            <div style={{ fontSize: 16, fontWeight: 700 }}>{title}</div>
            <div style={{ fontSize: 12, fontWeight: 200, color: '#3d3d3d' }}>{subTitle}</div>
        </div>
    )
}