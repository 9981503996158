import React, { Component } from "react";
import { Link, } from 'react-router-dom'; // Link,
import { Tabs, Layout, Menu, Row, Col, Button, Badge, Modal, Checkbox, Input, Spin, message, List, Card, Avatar } from 'antd';

import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios'

import _ from 'lodash';



import { UserOutlined, SearchOutlined, UserAddOutlined, CloseCircleOutlined, CloseOutlined, MessageOutlined } from '@ant-design/icons';

import { inject, observer } from 'mobx-react';

import { goPage, goBack } from '../../historyConfig'
import Tags from "./Tags";


const fakeDataUrl = 'https://randomuser.me/api/?results=15&inc=name,gender,email,nat&noinfo';

const { TabPane } = Tabs;


@inject('UserStore')
@observer
export default class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      search: '',
      activeTabsKey: 'tags',
      loading: false,
      hasMore: true,
      limit: 20,
      offset: 0
    }
  }

  getItems = () => {
    const search = this.state.search
    this.props.UserStore.search(search).then((res) => res.data)
      .then((data) => {
        console.log("search getItems", data)
        this.setState({
          data: data
        })

      })
  }

  componentDidMount = async () => {

  }

  onClickMessage = (item) => {
    const url = `message/${item._id}`
    goPage(url)
  }


  fetchData = callback => {
    axios.get(fakeDataUrl).then((result) => {
      console.log(result)
      callback(result)

    })


  };


  handleInfiniteOnLoad = () => {
    let { data } = this.state;
    this.setState({
      loading: true,
    });
    if (data.length > 14) {
      //   message.warning('tümü yüklendi');
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }

    this.fetchData(res => {
      data = this.state.data.concat(res.data.results);
      this.setState({
        data,
        loading: false,
      });
    });
  };

  onChangeTabs = (activeTabsKey) => {
    console.log(activeTabsKey)
    this.setState({
      activeTabsKey
    })
  }


  render() {

    return (
      <div>



        <Row
          style={{
            padding: '0 50px'
          }}
        >
          <Tabs
            onChange={(e) => this.onChangeTabs(e)}
            //  tabPosition="bottom"
            style={{
              width: '100%'
            }}
            activeKey={this.state.activeTabsKey}>

            <TabPane tab="Etiketler" key="tags" >
              <Tags />
            </TabPane>

            <TabPane tab="Ödeme Hareketleri" key="payments">
              Genel Ayarlar
              -Google paly Linki
              - Güncel versiyon : Kodu ve numarası
              - Oda içindeki mesajlar (LOG)
            </TabPane>

            <TabPane tab="Kullanıcılar" key="users" >
              <p>
                tokens
              </p>
            </TabPane>

          </Tabs>
        </Row>

      </div >
    )
  }

}