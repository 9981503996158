import React from 'react';
import './ConversationSearch.css';

export default function ConversationSearch({ placeholder, onChange }) {
  return (
    <div className="conversation-search">
      <input
        type="search"
        className="conversation-search-input"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}