import React, { Component } from "react";

import { HashRouter, Route, Link } from 'react-router-dom';

import { Layout, Menu, Row, Col } from 'antd';

import { inject, observer } from 'mobx-react';


@inject('UserStore')
@observer
export default class NavbarPanel extends Component {

    render() {
        const page = this.props.page
        const { user } = this.props.UserStore
        const userType = _.get(user, "userType", 4)
        return (
            <>
                <div style={{
                    padding: '10px 50px',
                    fontSize: '1.1em',
                    textAlign: 'left',
                    color: '#FFF',
                    fontWeight: 400,
                    backgroundColor: 'rgba(0,0,0,0.6)',
                    //  flexDirection: 'row'
                }}>
                    <Link
                        className={page == "profile" ? "panelactivelink" : ""}
                        to="/panel/profile" style={{
                            color: '#FFF',
                            float: 'left'
                        }}>
                        Profil
                    </Link>

                </div>
            </>
        )
    }

}
