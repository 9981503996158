
import { observable, action } from 'mobx';



export default class Socket {


    @observable socket = null
    @observable onlineUsers = []
    @observable token = localStorage.getItem('token');

    //sisteme giriş
    @action async setSocket(socket) {
        this.socket = socket
    }

    //sisteme giriş
    @action async setOnlineUsers(socket) {
        this.onlineUsers = socket
    }





}