

import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { inject, observer } from 'mobx-react';

import Header from "./Header";
import Footer from "./Footer"

import Loading from "./Loading"

import { ConfigProvider } from 'antd';
import { Layout, Menu, Dropdown, message, Button, BackTop } from 'antd';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';

import { getDefaultLanguage, primaryColor, siteInfo } from "../config";

import localeEN from 'antd/es/locale/en_US'
import localeTR from 'antd/es/locale/tr_TR'

const { SubMenu } = Menu;
const { Content, Sider } = Layout;



@inject('UserStore')
@observer
class PublicContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            logged: false,
            collapsed: false,
        }
    }

    componentDidMount = () => {
        console.log("cmd Container")
        //   this.getProfile()
    }


    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({ collapsed });
    };


    getProfile = async () => {
        try {
            this.setState({ loading: true })
            let profile = await this.props.UserStore.getProfile()
            /////////// odaya gir
            const apikey = _.get(profile, "data.user.apikey")
            const token = _.get(profile, "data._id")
            //soket odaya koy
            //   joinRoom(token,apikey)
            this.setState({ logged: true })
        } catch (error) {
            console.log({ error })
            //   this.props.UserStore.removeToken()
            // goPage("login")
            this.setState({ logged: false })
        }
        finally {
            this.setState({ loading: false })
        }
    }


    render() {
        const mainLocale = getDefaultLanguage() == "en" ? localeEN : localeTR
        const { loading } = this.state
        const token = this.props.UserStore.token

        const { children, user } = this.props
        const { collapsed } = this.state;

        if (loading) {
            return (<Loading />)
        }
        else {
            return (
                <ConfigProvider locale={mainLocale} >
                    <Layout className="main-layout">
                        {
                            !token && <Redirect to="/login" />
                        }



                        <Layout>
                            <div style={{ width: '100%' }}><Header fixed={this.props.headType || undefined} /></div>

                            <Content
                                className="site-layout-background"
                                style={{
                                    // margin: '24px 16px',
                                    padding: 24,
                                    // minHeight: 360,
                                    minHeight: 560,
                                }}
                            >
                                <div style={{ padding: 24, background: '#fff' }}>
                                    {children}
                                </div>
                            </Content>
                            <Footer />

                        </Layout>
                    </Layout>
                </ConfigProvider >
            )
        }
    }
}

export default withTranslation()(PublicContainer)
