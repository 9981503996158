
import {observable, action, computed, toJS} from 'mobx';

//// bOŞTA!!

export default class PrivateMessage {



}
