import React from 'react';
import './Compose.css';

export default function Compose(props) {
  //  const onPressSend = () => props.onPressSend() //onPressSend = () => { } 

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      props.onPressSend()
    }
  }

  return (
    <div className="compose">
      <input
        autoFocus
        value={props.typing}
        onChange={(e) => props.onChangeTyping(e.target.value)}
        type="text"
        className="compose-input"
        placeholder="Mesajınızı buraya yazın..."
        onKeyDown={handleKeyDown}
      />
      {
        props.rightItems
      }
    </div>
  );
}