
import React, { Component, Suspense } from 'react';
import { HashRouter, BrowserRouter, Route, Link, Redirect, Switch } from 'react-router-dom';

import { isMobile } from 'react-device-detect';


import { goPage } from './historyConfig'


import Container from "./components/Container"



import LoginPage from './pages/Login'
import RegisterPage from './pages/Register'
import NoFoundPage from './pages/NoFound'

import ProfilePage from './pages/Profile'

import PolicyPage from './pages/Policy'


import Loading from './components/Loading';


import RecoveryPassword from './pages/RecoveryPassword'

import PushNotification from './pages/PushNotification'

import { history } from "./historyConfig"

import "./App.css"

import SystemSettings from './pages/SystemSettings'

import HomePage from './pages/Home'
import User from './pages/Admin/User';
import Users from './pages/Users';

import SettingsPage from './pages/Settings'


import CreateUser from './pages/CreateUser'

import SearchPage from './pages/Search'
import Panel from './pages/Panel';
import AdminLogin from './pages/AdminLogin'


import _ from 'lodash'

import { inject, observer } from 'mobx-react';
import HomeSlider from './pages/HomeSlider';
import Feedback from './pages/Feedback';
import Rooms from './pages/Rooms/index';
import Room from './pages/Room';
import Messenger from './pages/Messenger';

import 'moment/locale/tr';
import Login from './pages/Login';
import PublicContainer from './components/PublicContainer';
import MessengerContainer from './components/MessengerContainer';

//hediyeler
import Gifts from './pages/Gifts';
//finansal
import Financial from './pages/Financial'
//prfil
import AdminProfile from './pages/AdminProfile'

//SOCKET
///export const socket = io(apiBaseUrl,{timeout:0})

/// !!!! SONRA MUTLAKA AKTİFLEŞTİR ###

//   if (process.env.NODE_ENV !== "development") {
//     console.log = () => { };
//   }
//   else {
//     console.log("DEV: ", process.env.NODE_ENV)
//     // console.log = () => {};
//   }
let mydebugger = localStorage.getItem('pidebugger');
if (process.env.NODE_ENV === "development") {
  mydebugger = true
}
if (!mydebugger) {
  console.log = () => { };
}
else {
  console.log("DEV: ", process.env.NODE_ENV)
  // console.log = () => {};
}

//export default  React.createClass({
@inject('UserStore', 'SocketStore', 'ChatStore')
@observer
export default class App extends Component {


  componentDidMount() {

    const socket = this.props.socket

    this.props.SocketStore.setSocket(socket)

    //storeden kullanıcı datalarını getir

    const userId = this.props.UserStore.getUserId;//user

    //soket kullanılmaya hazır
    socket.emit("ready");

    socket.emit('setUserId', userId)


    // socket.emit('joinRoom', { room: "6040e060f982fbc2c43da22e" });




    // socket.emit('room-chat message', { message: "mesaj" });


    socket.on("test-10sc", (arg) => {
      console.log("test-10sc", arg)
    })

    socket.on("get-currentUser", (arg) => {
      console.log("get-currentUser", arg)
    })


    socket.on("mic_actions", (arg) => {
      console.log("mic_actions", arg)
    })

    socket.on("room_online_users_count", (arg) => {
      console.log("room_online_users_count", arg)
    })


    socket.on("online_users_in_room", (arg) => {
      console.log("online_users_in_room", arg)
    })




    socket.on("private-message", (arg) => {
      console.log("private-message!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", arg)
      this.props.ChatStore.onPrivateMessage(arg)
    })



    // online kullanıcılar online-users
    socket.on("online-users", (arg) => {
      console.log("online-users", arg)
      this.props.UserStore.setOnlineUsers(arg.users)
    })

    socket.on("get client id", (clientID) => {
      console.log("clientID:", clientID)
    })

    // socket.on("CCID", (arg) => {
    //   console.log("CCID", arg)
    // })




    //kullanıcıyı sistemden at
    socket.on("logout", (arg) => {
      console.log("socket.logout", arg)
      this.props.UserStore.logout();
      goPage("login")
    });


    // socket.emit("admin:room-chat message", { mesaage: "Hassna", user: { name: "Fadminn-1" }, room: "603bf8da38bce986da750b99", created: new Date() });


    // setTimeout(() => {
    //    socket.emit("leaveRoom");
    // }, 5000)

  }

  render() {
    return (
      <Suspense fallback={(<Loading />)}>
        <BrowserRouter
          // basename="/app"
          history={history}>
          <Switch>
            {
              //  <Route exact path="/"
              //  component={() => <MessengerContainer> <Messenger /></MessengerContainer>}
              ///>
            }

            <Route exact path="/"
              component={() => <Container
              //  headType="fixed"
              ><HomePage /></Container>}
            />
            <Route exact path="/admin/panel"
              component={() => <Container
              //  headType="fixed"
              ><HomePage /></Container>}
            />

            <Route exact path="/admin"
              component={() => <Container
              ><HomePage /></Container>}
            />

            <Route exact path="/admin/slider"
              component={() => <Container><HomeSlider /></Container>}
            />

            <Route exact path="/admin/push-notification"
              component={() => <Container
              //  headType="fixed"
              ><PushNotification /></Container>}
            />

            <Route exact path="/admin/settings"
              component={() => <Container
              ><SettingsPage /></Container>}
            />

            <Route exact path="/admin/login"
              component={AdminLogin}
            />

            <Route exact path="/admin/users"
              render={(props) => <Container><User {...props} /></Container>}
            />


            <Route exact path="/admin/financial"
              component={() => <Container><Financial /></Container>}
            />



            <Route exact path="/admin/rooms"
              component={() => <Container><Rooms /></Container>}
            />

            <Route exact path="/user/create"
              component={() => <Container><CreateUser /></Container>}
            />

            <Route exact path="/admin/feedback"
              component={() => <Container><Feedback /></Container>}
            />

            <Route exact path="/admin/profile/:id"
              render={(props) => <Container> <AdminProfile {...props} /></Container>}
            />

            <Route exact path="/admin/profile"
              render={(props) => <Container> <AdminProfile {...props} /></Container>}
            />

            <Route exact path="/admin/gifts"
              component={() => <Container><Gifts /></Container>}
            />









            <Route exact path="/messenger"
              component={() => <Messenger />}
            />

            <Route exact path="/messenger/:id"
              render={(props) => <Messenger {...props} />}
            />

            <Route exact exact path="/rooms/:id"
              render={(props) => <Room socket={this.props.socket}  {...props} />}
            />

            <Route exact path="/users/:id"
              //  component={() => <Container><User /></Container>}
              render={(props) => <Container> <ProfilePage {...props} /></Container>}
            />

            <Route exact path="/user/:id"
              //  component={() => <Container><User /></Container>}
              render={(props) => <PublicContainer><ProfilePage {...props} /></PublicContainer>}
            />

            <Route exact exact path="/panel/system-settings"
              component={() => <Container><SystemSettings /></Container>}
            />

            <Route exact exact path="/search"
              // component={SearchPage}
              component={() => <PublicContainer><SearchPage /></PublicContainer>}
            />



            {
              //        <Route exact exact path="/search"
              //        component={() => <Container><SearchPage /></Container>}
              //      />
            }

            <Route exact exact path="/create"
              component={() => <CreateOrder />}
            />


            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/recovery/:id" component={RecoveryPassword} />
            <Route exact path="/policy" component={PolicyPage} />
            <Route path="/404" component={NoFoundPage} />

            <Redirect
              from='*'
              to='/404' />
          </Switch>
          {
            //<ReactChatWidget/>
          }
        </BrowserRouter>
      </Suspense>
    )
  }
}
