
import { observable, action, computed, toJS } from 'mobx';
import Auth from "./Auth";
import _ from "lodash"

import ChatServices from '../services/Chat';
import UserServices from '../services/User';

class Chat {
    constructor() {
    }
    @observable loading = false
    @observable loadingMessages = false
    @observable loadingOtherMessages = false
    @observable typing = ""
    @observable messages = []
    @observable messagesOffet = 0
    @observable messagesLimit = 20
    @observable selectedConversationItem = null
    @observable conversations = []
    @observable scroolToEnd = false;


    @action reply = (msg) => {
        //id: Math.floor((Math.random() * 99999999999999999) + 1),
        this.messages.push({
            id: new Date().getTime(),
            me: true,
            msg: msg,
            created: new Date(),
            image: 'https://www.bootdey.com/img/Content/avatar/avatar6.png'
        });
        this.messages = this.messages
        return this.messages
    }


    @action setLoading = (status = false) => {
        this.loading = status;
    }
    @action onPrivateMessage = async (arg) => {
        const userId = arg.fromUser;
        const foundConvercation = _.find(this.conversations, (o) => {
            return _.get(o, "user._id") == userId
        })
        console.log("!!!!!!!!!!!!!!!!!!!!", toJS(foundConvercation))
        //gelen mesaj konuşma varsa
        if (foundConvercation) {
            foundConvercation.created = arg.created;
            foundConvercation.message = arg.message;
            this.conversations = _.orderBy(this.conversations, ['created'], ['desc'])
            this.conversations = JSON.parse(JSON.stringify(this.conversations))
        }
        //gelen aktif konuşma içindeki kişiyle konuşuluyorsa
        if (_.get(this.selectedConversationItem, "user._id") == userId) {
            const messageData = {
                user: _.get(this.selectedConversationItem, "user"),
                message: arg.message
            }
            this.messages.push(messageData)
            this.messages = this.messages
        }
        //ilk defa biri yazıyorsa
        if (!foundConvercation) {
            const token = await new Auth().myToken();
            const userService = new UserServices(token)
            const serverData = (await userService.getUserProfile(userId)).data;
            const conversation = {
                user: serverData,
                message: arg.message,
                created: arg.created
            }
            this.conversations = [conversation, ...this.conversations]
        }
        //  this.conversations = [{ ...arg }, ...this.conversations]
    }

    @action updateConversation = (userId, messageText) => {
        const foundConvercation = _.find(this.conversations, (o) => {
            return _.get(o, "user._id") == userId
        })
        if (foundConvercation) {
            foundConvercation.created = new Date()
            foundConvercation.message = messageText;
            this.conversations = JSON.parse(JSON.stringify(this.conversations))
        }

    }

    @action setConversations = async () => {
        console.log("setConversations")
        this.loading = true
        try {
            const token = await new Auth().myToken();
            const service = new ChatServices(token)
            const serverData = (await service.getConversations()).data;
            // this.conversations = [...this.conversations, ...serverData]
            this.conversations = serverData
            console.log("!!!!!!!!!!!!!!!conversations:", { co: toJS(this.conversations) })

        } catch (error) {
            console.log("ChatrStore setConversations ERROR!!", { error })
        }
        finally {
            this.loading = false
        }
        return this.conversations
    }

    @action
    setConversationItem = (item) => {
        this.selectedConversationItem = item
    }

    @action
    onClickConversationItem = (item) => {
        this.selectedConversationItem = item
        this.messages = []
        console.log("onClickConversationItem", toJS(item))
        this.getMessagesInConversation(item.user._id)
    }
    @action
    onClickConversationItemByUserId = async (userId) => {
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!onClickConversationItemByUserId!")
        this.loading = true;
        try {
            //    this.selectedConversationItem = userId
            this.messages = []
            await this.getMessagesInConversation(userId)
            const token = await new Auth().myToken();
            const userService = new UserServices(token)
            //seçilen kullanıcı konuşmalar içinde var mı
            const found = _.find(this.conversations, (o) => {
                return o.user._id == userId
            })
            if (found) {
                console.log("!!!!!!!!!!!!!!!", { found })
                this.selectedConversationItem = found
            }
            else {
                try {
                    const serverData = (await userService.getUserProfile(userId)).data;
                    this.conversations = [{ user: serverData }, ...this.conversations]
                    //  this.conversations.push({ user: serverData })
                    this.selectedConversationItem = { count: 0, user: serverData }

                } catch (error) {
                    console.log("!!!!error", error)
                }

            }

        } catch (error) {
            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!onClickConversationItemByUserId!error", error)
        } finally {
            this.loading = false;
        }

    }



    @action
    getMessagesInConversation = async (userId) => {
        // if (!userId) {
        //     userId = _.get(this.selectedConversationItem, "user._id")
        // }
        if (!userId) return
        this.loadingMessages = true
        try {
            const token = await new Auth().myToken();
            const service = new ChatServices(token)
            //  if (init) {
            //      this.messagesOffet = 0
            //      this.messagesLimit = 10
            //  }
            let messages = (await service.listMessageInConversation(userId, this.messagesLimit, this.messagesOffet)).data;
            // messages = messages.map(v => { return { ...v, me: myUserId === v.fromUser, msg: v.message } })
            console.log("Chat Store getMessagesInConversation", messages)
            this.messages = messages

            // if (!init) {
            //     this.messagesOffet = this.messagesOffet + this.messagesLimit
            //     this.messagesLimit = this.messagesLimit + this.messagesLimit
            // }
        } catch (error) {
            console.log(error)
        }
        finally {
            this.loadingMessages = false
        }
    }


    @action
    concatMessages = (messages) => {
        //      this.conversations = _.orderBy(this.conversations, ['created'], ['desc'])
        this.messages = [...messages, ...this.messages]
    }

    @action
    getOtherMessagesInConversation = async (offset = 0, limit = 100, userId = null) => {
        userId = _.get(this.selectedConversationItem, "user._id", null)
        this.loadingOtherMessages = true
        this.loadingMessages = true
        if (!userId) return
        try {
            const token = await new Auth().myToken();
            const service = new ChatServices(token)
            let messages = (await service.listMessageInConversation(userId, limit, offset)).data;
            this.messages = _.orderBy([...messages, ...this.messages], ['created'], ['asc'])
            // return Promise.resolve(messages)
            //  this.messages = [...messages, ...this.messages]
            console.log("Chat Store getOtherMessagesInConversation", { uzun: this.messages.length, messages: toJS(messages) })
        } catch (error) {
            console.log(error)
            //  return Promise.reject(error)
        }
        finally {
            this.loadingOtherMessages = false
            this.loadingMessages = false
        }
    }

    @action async sendMessage() {
        console.log("sendMessage11", { typing: this.typing, active: toJS(this.selectedConversationItem) })

        /*
        created: "2021-03-11T07:29:12.464Z"
fromUser: "60394c89ba1b055fd48c43af"
message: "Merhaba "
toUser: "60311a6a20d2473b6009b5e5"
user: {_id: "60311a6a20d2473b6009b5e5", name: "Ferdi özer", created: "2021-02-20T14:19:22.262Z", online: true, avatar: "https://pistatic.piyanos.com/uploads/chatx/useravatars/7bddc786865298f28e41e02a19688aff.jpg"}
_id: "6049c6c8dcfee50306b84c15"
*/
        try {
            if (this.typing.trim().length == 0) return;

            const token = await new Auth().myToken();
            const service = new ChatServices(token);
            const toUserId = _.get(this.selectedConversationItem, "user._id");
            const toServerData = {
                message: this.typing,
                toUserId
            }

            if (!toUserId) return

            this.updateConversation(toUserId, this.typing)

            let messageData = (await service.createMessage(toServerData)).data
            messageData.user = _.get(this.selectedConversationItem, "user");
            messageData.message = this.typing;
            this.messages.push(messageData)
            this.messages = this.messages
            this.typing = ""
            console.log("sendMessage", { messageData })

        } catch (error) {
            console.log("sendMessage:3", { error: error })
        }
    }
    @action
    onChangeTyping = (typing) => {
        this.typing = typing
    }

    @action async deleteMessage(id) {
        try {
            const token = await new Auth(token).myToken();
            let message = await new ChatServices().deleteMessage(id)
            const filteredData = this.messages.filter(item => item._id !== id);
            this.messages = filteredData;
            return
        } catch (error) {
            console.log(error)
        }
    }
    @action async deleteAllMessage(fromUserId, myUserId, item) {
        const token = await new Auth().myToken();
        console.log("ChatStore deleteAllMessage", fromUserId)
        try {
            let message = await new ChatServices(token).deleteAllMessage(fromUserId)

            // const filteredData = this.conversations.filter( item => ((item.toUser === fromUserId && item.fromUser === myUserId) || (item.toUser === myUserId && item.toUser === myUserId)) );
            const filteredData = this.conversations.filter(v => _.join(v._id) !== _.join(item._id));

            console.log("deleteAllMessage", toJS(filteredData))
            this.conversations = filteredData
        } catch (error) {
            console.log(error)
        }

    }


}
export default Chat;
