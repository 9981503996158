import React, { Component } from "react";

import { Link } from 'react-router-dom'; // Link,

import { goPage, goBack } from '../historyConfig'
import { Avatar, Layout, Menu, Dropdown, Row, Col, Button, message, Badge, Modal, Checkbox, Input, Carousel, notification, ConfigProvider, List, Card } from 'antd';

import { isMobile } from 'react-device-detect';

import { WarningOutlined, LogoutOutlined, UserOutlined, LineChartOutlined, MessageOutlined, NotificationOutlined, SmileOutlined, MenuOutlined } from '@ant-design/icons';

import { inject, observer } from 'mobx-react';
import { setDefaultLanguage, getDefaultLanguage } from "../config";

import i18n from 'i18next'
import { withTranslation } from "react-i18next";

import moment from "moment";

import Ticker from 'react-ticker'
import { formatMyMoney } from "../helper";

// import {joinRoom} from "../utils/socket"

const { Header: HeaderContent } = Layout;
const { SubMenu } = Menu;

@inject('UserStore')
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showHeaderMenu: false,
      user: null,
      avatar: "",
      displayName: "",

      notificationTypes: [
        { key: "callWaiter", url: "call-waiter", title: "Garson Çağır", color: "#ff4d4f" },
        { key: "newOrder", url: "new-order", title: "Yeni Sipariş", color: "#399893" }
      ],

    }
  }

  componentDidMount = async () => {


    //dil
    //  const finedLang = _.find(this.state.languages,{key:getDefaultLanguage()})
    //   this.setState({selectedLanguage:_.get(finedLang,"title")})

    //   this.getProfile()
    //   this.getOperators()
  }




  onClickUserMenu = (item, type = "default") => {
    console.log(item)
    if (item == "logout") {
      message.loading(undefined, 0)
      //   this.setState({loading:true})
      this.props.UserStore.logout();
      setTimeout(() => {
        message.destroy()
        goPage("login")
      }, 2000)
    }
    else if (item == "profile") {
      //  goPage("profile")
    }
    else if (type == "lang") {

      setDefaultLanguage(item)
      i18n.changeLanguage(item)
    }
    else {
      goBack()
    }
    /*
    if(item.length==24){
        goPage(`botmenu/${item}`)
    }
    */
  }

  onClickNotification = (v) => {
    console.log("on click notif", v)
  }

  render() {

    const { t } = this.props
    const { user } = this.props.UserStore

    const { notifications } = this.props.UserStore
    const { notificationTypes } = this.state

    const activeUserType = _.get(user, "userType", 4)


    const menuUser = (
      <Menu>
        <Menu.Item key="profile">
          <Link to={`/user/${_.get(user, "_id")}`}>
            <UserOutlined /> Profil
          </Link>
        </Menu.Item>
        {
          //  <Menu.Item key="profile">
          //    <Link to={`/messenger`}>
          //      <MessageOutlined /> Mesaj
          //    </Link>
          //  </Menu.Item>
        }
        <Menu.Divider />
        <Menu.Item key="logout" onClick={(e) => this.onClickUserMenu(e.key)}>
          <div>
            <LogoutOutlined /> {t('logout')}
          </div>

        </Menu.Item>
      </Menu>
    )


    const menuNotification = (
      notifications.length > 0
        ?
        <div>
          <Menu>
            {
              notifications.map((v, i) => {
                const nType = _.find(notificationTypes, { key: v.type })
                return (
                  <Menu.Item
                    onClick={() => this.onClickNotification(v)}
                    //danger={v.type === "callWaiter"} 
                    key={i}>
                    <Link to={`/order?qr=${_.get(v, "qr")}&type=${_.get(nType, "url", "")}`}>
                      <span>
                        <WarningOutlined
                        // color={_.get(nType,"color",undefined)}
                        />
                        {` `}
                        <b>{_.get(v, "title", "")}</b>
                        {` `}(
                        {moment(v.date).fromNow()}
                        )
                        {` `}
                        <small style={{ color: _.get(nType, "color", undefined) }}>
                          {_.get(_.find(notificationTypes, { key: v.type }), "title", "")}
                        </small>
                      </span>
                    </Link>
                  </Menu.Item>
                )
              })
            }
          </Menu>
          <Button onClick={() => this.props.UserStore.removeNotification(null, "deleteAll")} type="danger" block size="small">Tümünü Sil</Button>
        </div>
        :
        <div style={{ padding: 20, backgroundColor: '#FFF' }}>
          {`${t('notFoundNotification')} `}
          <SmileOutlined />
        </div>
    )

    return (
      <HeaderContent className="Header"
        style={this.props.fixed ? {
          backgroundColor: '#1e272e',
          position: 'fixed',
          zIndex: 100,
          width: '100%'
        } : {
          backgroundColor: '#2C3A47',
          // paddingTop: 20
        }}>

        <Row justify="space-between" type="flex">
          <Col />
          <Col>

            <Dropdown overlay={menuNotification}>
              <Badge
                count={notifications.length}>
                <Button title="Bildirim" style={{ color: '#fff' }} type="ghost" size="small" icon={<NotificationOutlined />} shape="circle-outline" />
              </Badge>
            </Dropdown>
            <Dropdown
              //trigger="click" 
              overlay={menuUser}>
              <span title={_.get(user, "name", "")}>

                <Button
                  icon={<UserOutlined />}
                  size="small" type="ghost"
                  style={{
                    // backgroundColor: "#000",
                    color: '#fff',
                    marginLeft: 5,
                    marginRight: 5
                  }}
                >
                  {_.get(user, "username", "")}
                </Button>
                {
                  /*
                   <span style={{ color: '#FFF' }}>
                    {_.get(user, "username", "")}
                  </span>
                  
                    <Avatar
                  //  shape="square"
                    icon={<UserOutlined />}
                    style={{
                      backgroundColor: "#000",
                      verticalAlign: 'middle',
                      cursor: 'pointer',
                      marginLeft: 5,
                      marginRight: 5
                    }}
                    size="default">
                  </Avatar>
                  */
                }
              </span>
            </Dropdown>

            <Button
              onClick={(e) => this.onClickUserMenu("logout")} type="danger" size="small" >Çıkış</Button>



          </Col>

        </Row>

      </HeaderContent>
    )
  }
}


export default withTranslation()(Header)