import React, { Component } from 'react'
import {
    Form,
    Input,
    Tooltip,
    Cascader,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    AutoComplete,
    message,
    Spin
} from 'antd';
import { goPage } from '../historyConfig'

import { Redirect, Link } from 'react-router-dom';

import _ from 'lodash'


import { inject } from 'mobx-react';
import { LoadingGlobal } from '../components/LoadingGlobal';

@inject('UserStore')
export default class RecoveryPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resetPasswordKey: '',
            password: '',
            captcha: '',
            loading: false,
            isLogin: false,
            err: false,
            randomNumber: Math.floor(Math.random() * 9999) + 1000
        };
    }

    componentDidMount = async () => {

        try {
            this.setState({ loading: true })
            //id
            const id = this.props.match.params.id
            const serverData = await this.props.UserStore.getUserWithResetPasswordKey(id)
            const resetPasswordKey = _.get(serverData, "data.resetPasswordKey")
            this.setState({ resetPasswordKey })
        } catch (error) {
            console.log(error)
            this.setState({ err: true })
        }
        finally {
            this.setState({ loading: false })
        }
    }


    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    newRandomNumber = () => {
        this.setState({
            randomNumber: Math.floor(Math.random() * 9999) + 1000
        })
    }

    onFinish = async values => {

        // eğer formdan başarılı geçilirse
        if (this.state.captcha != this.state.randomNumber) {
            message.error("Doğrulama kodu hatalı")
            return
        }

        this.setState({
            loading: true
        })
        //burdan apiye git


        let data = {
            resetPasswordKey: this.state.resetPasswordKey,
            password: this.state.password,
        }

        this.props.UserStore.recoveryPassword(data).then((res) => {
            message.success("Şifre sıfırlama başarılı. Şimdi giriş yapabilirsiniz.")
            goPage("login")

        }).catch((err) => {
            console.log(err)
            message.error()
        }).finally(() => this.setState({ loading: false }))
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        //  this.props.init();
    }



    //linear-gradient(87deg,#11cdef,#1171ef)!important;
    render() {
        const BackgroundImageUrl = 'https://source.unsplash.com/random/900*700/?social,friends'


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };



        return (
            <div
                style={{

                    backgroundImage: `url(${BackgroundImageUrl})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',

                }}

            >
                {
                    this.state.err
                    &&
                    <Redirect to="/404" />
                }
                <LoadingGlobal loading={this.state.loading} />
                <div style={{
                    background: 'linear-gradient(87deg,rgba(17, 205, 239,0.5),rgba(17, 113, 239, 0.5))',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
                >
                    <div style={
                        {
                            padding: '10px 40px',
                            backgroundColor: 'rgba(255,255,255,0.6)',
                            borderRadius: 10
                        }}>

                        {
                            this.state.loading &&
                            <div style={{ justifyContent: 'center', textAlign: 'center', padding: '0 10px' }}>
                                <Spin />
                            </div>
                        }

                        <Form
                            size="small"
                            {...formItemLayout}
                            name="register"
                            onFinish={() => this.onFinish()}
                            scrollToFirstError
                        >

                            <Form.Item
                                name="password"
                                label="Şifre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen şifrenizi giriniz!',
                                    },
                                    {
                                        min: 4,
                                        message: 'Şifre en az 4 karakter olmalı',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    name="password"
                                    onChange={(e) => this.onChange(e)}
                                />
                            </Form.Item>

                            <Form.Item
                                name="confirm"
                                label="Şifre Tekrarı"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen şifre tekrarı giriniz!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Şifreler uyuşmuyor!');
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item label="Doğrulama Kodu" extra="Robot olmadığınıza emin olmalıyız.">
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="captcha"
                                            noStyle
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Lütfen doğrulama kodunu giriniz'
                                                }
                                            ]}
                                        >
                                            <Input
                                                name="captcha"
                                                onChange={(e) => this.onChange(e)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Button block onClick={() => this.newRandomNumber()}>{this.state.randomNumber}</Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit" block>
                                    KAYDET
                                </Button>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </div>
        )
    }
}