import React from "react";
import { HashRouter, Route, Link } from 'react-router-dom';
import { Layout, Menu, Row, Col } from 'antd';
import { siteInfo } from "../config";

export default () => {
  return (
    <>
      <div>
        <Row style={{
          justifyContent: 'center',
          padding: ' 10px 50px'
        }}>
          <div>
            {`${siteInfo.copyright}`}
          </div>
        </Row>
      </div>
    </>
  )
}

