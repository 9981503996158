
import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';


import 'react-image-lightbox/style.css';
import { toJS } from 'mobx';

import MyProfile from './My'
import UserProfile from './User'


@inject('UserStore')
@observer
class AdminProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0)

        const userId = _.get(this.props, "match.params.id");
        const currentUserId = _.get(this.props, "UserStore.user._id")
        console.log("-------profilePage--------", { userId, currentUserId })
        // if (userId) {
        //     console.log({ userId })
        //     try {
        //         const user = await this.props.UserStore.getUserProfile({ _id: userId })
        //         console.log("componentDidMount user Profile!!!", { user: toJS(user) })
        //     } catch (error) {
        //         message.warning("Kullanıcı bulanamadı")
        //         this.setState({ isErr: true })
        //     }
        // }
        // else {
        //     console.log("userId!!!YOK")
        // }

    }

    render() {
        const userId = _.get(this.props, "match.params.id");
        const currentUserId = _.get(this.props, "UserStore.user._id");


        //   const me = currentUserId == userId


        if (!userId) {
            return (
                <MyProfile match={{ params: { id: currentUserId } }} />
            )
        }


        return (
            <div>
                <UserProfile match={{ params: { id: userId } }} />
            </div >
        )
    }
}

export default AdminProfile