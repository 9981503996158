


import React, { Component } from "react";
import { Link } from 'react-router-dom';
import NavbarMenu from "../components/NavbarMenu";
import NavbarPanel from "../components/NavbarPanel";


export default class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }
    render() {
        return (
            <div style={{ textAlign: 'center', color: '#FFF' }}>
                <NavbarPanel />
                <div style={{ padding: 50, fontSize: 50, textAlign: 'center', color: '#FFF', fontWeight: 800, border: '' }}>
                    Yönetim Paneli
                </div>
                <p style={{ fontSize: 24 }}>
                    Bu bölümde sistem ayarları yapabilir ve detayları görüntüleyebilirsiniz
                </p>
            </div>
        )
    }
}
