import axios from "axios";
import { API_URL, cdnConfig } from "../config";


export default class UserServices {
  constructor(token) {
    this.token = token
    this.axiosConfig = { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
  }

  myProfile() {
    return new Promise((resolve, reject) => {
      ///api/me/conversation
      const url = `${API_URL}/users/me`;
      axios.get(url, this.axiosConfig).then(res => {
        return resolve(res.data)
      }).catch(err => {
        return reject(err)
      })
    })
  }

  getUserProfile(userId) {
    const url = `${API_URL}/users/${userId}`;
    return axios.get(url, this.axiosConfig)
  }

  postUserAvatarImage(photo) {
    const url = `${cdnConfig.full_url}`;
    const formData = new FormData();
    formData.append('token', cdnConfig.token);
    formData.append('key', cdnConfig.key);
    formData.append('subkey', "useravatars");
    formData.append('file', photo);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } }
    return axios.post(url, formData, headers)
  }
  updateMyProfile(data) {
    const url = `${API_URL}/users/me/update`;
    return axios.post(url, data, this.axiosConfig)
  }
  reportUser(data) {
    const url = `${API_URL}/home/feedback`;
    return axios.post(url, data, this.axiosConfig)
  }

  search(search) {
    const url = `${API_URL}/users/search`
    return axios.post(url, { search });
  }

  //////////  ADMİN

  adminLogin(data) {
    const url = `${API_URL}/users/admin/login`;
    return axios.post(url, data, this.axiosConfig);//.then(res => res.data);
  }

  getUsers() {
    const url = `${API_URL}/admin/users`;
    return axios.get(url, this.axiosConfig);
  }


  getUserBanOperations(userId) {
    const url = `${API_URL}/admin/get-ban/${userId}`;
    return axios.get(url, this.axiosConfig)
  }
  updateUserFromAdmin(data) {
    const url = `${API_URL}/admin/update-user`;
    return axios.post(url, data, this.axiosConfig)
  }
  // Kullanıcı banla
  setUserBanOperations({ id, status }) {
    const url = `${API_URL}/admin/set-ban`;
    return axios.post(url, { status, id }, this.axiosConfig)
  }



}