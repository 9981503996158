import _ from 'lodash';
import React, { useEffect } from 'react';
import shave from 'shave';
import moment from 'moment'
import { Badge } from "antd";
import './ConversationListItem.css';
import { defaultImages } from "../../../config";

export default function ConversationListItem(props) {
  useEffect(() => {
    shave('.conversation-snippet', 40);
  })

  //const { photo, name, text } = props.data;

  const photo = _.get(props.data, "user.avatar")
  const name = _.get(props.data, "user.name")
  const online = _.get(props.data, "user.online", false)
  const text = props.data.message
  const created = props.data.created
  const selectedConversationItem = props.selectedConversationItem

  const isSelected = _.join(props.data._id, ",") === _.join(_.get(selectedConversationItem, "_id", []), ",");

  return (
    <div onClick={() => props.onClick(props.data)} className={`conversation-list-item ${isSelected ? "active-conversation" : ""}`}>
      <img className="conversation-photo" src={photo ? photo : defaultImages.userAvatar} alt={name} />
      <div className="conversation-info">
        <Badge status={online ? "success" : undefined}>
          <h2 className="conversation-title">{name}</h2>
        </Badge>
        <p className="conversation-snippet">{text}</p>
        <p>{moment(created).fromNow()}</p>
      </div>
    </div>

  );
}