import React, { Component } from 'react'
import { Form, Spin, Input, Button, message, Checkbox, Row, Col } from 'antd';
import { ReactSVG } from 'react-svg'
import { siteInfo, facebookAppId, googleClientId, appName } from '../config'


import { UserOutlined, LockOutlined, GoogleCircleFilled, GoogleOutlined, GooglePlusCircleFilled } from '@ant-design/icons';

import { inject } from 'mobx-react';

import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { GoogleLogin } from 'react-google-login';

const LoginWithTwitter = require('login-with-twitter')

const tw = new LoginWithTwitter({
    consumerKey: 'XOOpAjbFonX412tl5OiwlNWob',
    consumerSecret: 'rDDwVQ42IX8Js7erc5OclOAdcqrGvI5zDsoOvO7ImqpiFQSDfc',
    callbackUrl: 'https://www.sssosyal.com/twitter/callback'
})


@inject('UserStore')
class AdminLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false,
            formtype: "login"
        };
    }


    async componentDidMount() {
        const token = await this.props.UserStore.getToken();
        if (token) {
            // this.props.history.push("/");
            message.warning("Zaten giriş yaptınız.")
            console.log("VAR")
        }
        else {
            //  console.log("YOK")
        }
        // console.log("token",token)
    }


    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        //  this.props.init();
    }

    onSubmit = async () => {

        if (this.state.formtype == "login") {
            this.setState({
                loading: true
            })
            const data = {
                password: this.state.password,
                email: this.state.email
            }

            if (!this.state.password || !this.state.email) {
                message.warn("Gerekli alanları doldurunuz")
                this.setState({
                    loading: false
                })
            }
            else {
                const login = this.props.UserStore.adminLogin(data);

                login.then((res) => {
                    // Giriş başarılı
                    console.log(res)
                    const token = res.data._id;
                    this.props.UserStore.saveToken(token);
                    this.props.UserStore.saveCompanyCode(_.get(res, "data.user.apikey"));
                    this.props.UserStore.saveUserId(_.get(res, "data.user._id"));
                    message.loading("", 0)
                    setTimeout(() => {
                        this.setState({ loading: false })
                        message.destroy()
                        this.props.history.push("/admin/panel");
                        window.location.reload(false);
                        //    joinRoom(token,res.data.user.apikey)
                    }, 2000)

                })
                    .catch((err) => {
                        console.log(err.response.data)
                        message.error("Giriş bilgileriniz doğrulanmıyor. Lütfen daha sonra tekrar deneyiniz")
                        this.setState({ loading: false })
                    })


            }
        }
        // şifre sıfırlama on click
        else {
            console.log("şifre sıfırlama on click")
            this.setState({
                loading: true
            })

            const { email } = this.state
            if (!email) {
                return
            }

            try {

                this.props.UserStore.forgotPassword(email).then((res) => {
                    message.success()
                    console.log(res)
                }).catch((err) => {
                    message.error()
                    console.log(err.response)
                })

            } catch (error) {
                console.log(error)

            }
            finally {
                this.setState({
                    loading: false
                })
            }

        }

    }

    //linear-gradient(87deg,#11cdef,#1171ef)!important;
    render() {
        const BackgroundImageUrl = 'https://source.unsplash.com/random/900*700/?social,friends'
        const { t } = this.props
        return (
            <div>
                <div style={{
                    background: 'linear-gradient(87deg,rgba(32, 1, 34,0.5),rgba(111, 0, 0, 0.5))',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
                >
                    <Row>
                        <Col lg={12} xs={24} sm={24} md={12}>
                            <div style={
                                {
                                    width: 400,
                                    height: 320,
                                    padding: 10,
                                    backgroundColor: 'rgba(0,0,0,0.6)',
                                    borderTopRightRadius: 20,
                                    borderBottomRightRadius: 20,
                                    marginTop: 82
                                }}>
                                <div
                                >
                                    <div
                                        className="loginlogo">
                                        {appName}
                                    </div>

                                </div>

                                <Form
                                    name="normal_login"
                                    className="login-form"
                                    initialValues={{ remember: true }}
                                    onFinish={() => this.onSubmit()}
                                >

                                    <Input
                                        name="email"
                                        autoFocus={true}
                                        defaultValue={this.state.email}
                                        placeholder={this.state.formtype == "login" ? t("usernameOrMail") : t("email")}
                                        onChange={(e) => this.onChange(e)}
                                        prefix={<UserOutlined />}
                                        style={{ marginBottom: 10 }}
                                    />

                                    {
                                        (this.state.formtype == "login")
                                        &&
                                        <Form.Item>
                                            <Input
                                                name="password"
                                                defaultValue={this.state.password}
                                                placeholder={t("password")}
                                                type="password"
                                                onChange={(e) => this.onChange(e)}
                                                onPressEnter={() => this.onSubmit()}
                                                style={{ marginBottom: 10 }}
                                                prefix={<LockOutlined />}
                                            />
                                        </Form.Item>
                                    }
                                    <Form.Item>
                                        <Button
                                            block={true}
                                            type="primary"
                                            onClick={() => this.onSubmit()}
                                            disabled={this.state.loading}
                                            loading={this.state.loading}
                                        >
                                            {
                                                this.state.formtype == "login"
                                                    ?
                                                    t("login")
                                                    :
                                                    t("resetPassword")
                                            }
                                        </Button>
                                        {
                                            //  {t("or")} <Link to="/register"> {t("register")} </Link>
                                        }

                                        <p style={{ color: 'rgba(255,255,255,1)', fontSize: 14, textAlign: 'center', paddingTop: 10 }}>
                                            {siteInfo.description}
                                        </p>

                                        <p style={{
                                            color: 'rgba(255,255,255,0.4)',
                                            fontSize: 12,
                                            textAlign: 'center',
                                            marginTop: '-10px'
                                        }}>
                                            {siteInfo.copyright}</p>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        )
    }
}


export default withTranslation()(AdminLogin)