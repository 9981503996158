
import React, { Component } from 'react';
import {
    message,
    Table,
    Select,
    Drawer,
    Switch,
    Row,
    Button,
    Input,
    Form,
    Modal,
    Popconfirm,
    Tooltip,
    notification,
    Radio,
    Col,
    Divider,
    Card,
    Upload,
    Avatar,
    Typography,
    Tag,
    Slider,
    InputNumber,
    Badge,
    Skeleton,
    Checkbox

} from 'antd';


import {

    CloseOutlined,

} from '@ant-design/icons';


import { inject, observer } from 'mobx-react';

import moment from 'moment'

import axios from "axios"

import _ from "lodash"



import NavbarPanel from '../components/NavbarPanel';
import { LoadingGlobal } from '../components/LoadingGlobal';
import { toJS } from 'mobx';

const CheckboxGroup = Checkbox.Group;

const queryString = require('query-string');

const { Search, TextArea } = Input;

const { Option } = Select;
const { Text } = Typography;


@inject('UserStore', 'HourseBetStore')
@observer
export default class SystemSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            indeterminate: false,
            checkAll: false,
            user: null
        }
    }


    componentDidMount = async () => {
        this.initSettings()
        if (this.props.page != "createUser") {
            let data = _.get(this.props.UserStore, "users", [])
            console.log({ data: toJS(data) })
            if (data.length == 0) {
                try {
                    this.props.UserStore.getUsers();
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    onChangeSelect = (e, type) => {

        const selectedUser = _.find(this.props.UserStore.users, { _id: e })

        this.initSettings(e)

        this.setState({
            [type]: selectedUser
        })

    }


    initSettings = (userId = null) => {
        // getSystemSettings
        this.setState({ loading: true })
        this.props.UserStore.getSystemSettings(userId).then(res => {
            //   res = res.data
            console.log("initSettings", { res })

            this.setState({
                ...res
            })

            console.log("componentDidMount getSystemSettings res ", res)
        }).catch(err => {
            console.log("componentDidMount getSystemSettings err ", err)
            this.setFirstSetting()
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    //default olarak yapılacak ilk ayar
    setFirstSetting = () => {
        this.props.UserStore.setFirstSetting()
    }




    //#kaydet
    handleCreate = async () => {
        console.log({ vvvv: this.state })
        //   return
        const toServerData = this.props.UserStore.systemSettings
        toServerData.user = this.state.user
        try {
            await this.props.UserStore.setSystemSettings(toServerData)//(this.state)
            message.success()
        } catch (error) {
            console.log({ error })
            message.error(_.get(error, "response.data.message", ""))
        }
    }



    onChange(e) {
        //  this.setState({ [e.target.name]: e.target.value })
        this.props.UserStore.systemSettingsOnChangeIn(e.target.name, e.target.value)
    }
    onChangeCredit = (e) => {
        this.setState({ credit: e })
    }



    onBlurPercent = (percent) => {
        if (percent > 100) {
            this.setState({
                percent: 100
            })
        }
        if (percent < 0) {
            this.setState({
                percent: 0
            })
        }

    }

    onChangeCb = checkedList => {


        this.props.UserStore.systemSettingsChangeCb(checkedList)
        // this.props.HourseBetStore.avaiableBahisler
        //  const { plainOptions } = this.state

        const { avaiableBahisler } = this.props.HourseBetStore
        const plainOptions = _.map(avaiableBahisler, v => { return ({ label: v.text, value: v.value }) })
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
            checkAll: checkedList.length === plainOptions.length,
        });

    };

    onCheckAllChange = e => {
        const { avaiableBahislerAll } = this.props.HourseBetStore
        this.props.UserStore.systemSettingsChangeCb(e.target.checked ? _.map(avaiableBahislerAll, v => { return v.value }) : [])
        /*
        // systemSettings
        const checkedListStore = this.props.UserStore.systemSettings.checkedList
        //const { plainOptions } = this.state
        const { avaiableBahislerAll } = this.props.HourseBetStore
        this.setState({
            //   checkedList: e.target.checked ? _.map(plainOptions, v => { return v.value }) : [],
            checkedList: e.target.checked ? _.map(avaiableBahislerAll, v => { return v.value }) : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        */
        this.setState({
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };

    onChangeForeignCb = (e) => {
        e = e.target.checked
        this.props.UserStore.systemSettingsOnChangeForeign(e)
    }

    /**
     *             maxGain: 0,
            minCouponPrice: 0,
            checkedList: [],
     */
    render() {
        const { user, users } = this.props.UserStore
        const { loading } = this.state

        const { avaiableBahisler } = this.props.HourseBetStore

        const { maxGain, checkedList, minCouponPrice, maxCouponPrice, foreign } = this.props.UserStore.systemSettings


        const plainOptions = _.map(avaiableBahisler, v => { return ({ label: v.text, value: v.value }) })

        // const { loading } = this.props.UserStore

        const handleFocus = (event) => event.target.select();

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16, offset: 0 },
            },
        };


        const page = _.get(this.props, "page", "")

        const renderBody =
            <div>

                <Form {...formItemLayout}>
                    <Form.Item
                        label="Oynatılabilir Bahis Türleri"
                        required
                    >
                        <>
                            <div className="site-checkbox-all-wrapper">
                                <Checkbox
                                    indeterminate={this.state.indeterminate}
                                    onChange={this.onCheckAllChange}
                                    checked={this.state.checkAll}
                                >
                                    Tümünü Seç
  </Checkbox>
                                <Checkbox
                                    onChange={(e) => this.onChangeForeignCb(e)}
                                    checked={foreign}
                                >
                                    Yabancı Koşular
  </Checkbox>
                            </div>


                            <br />
                            <CheckboxGroup
                                options={plainOptions}
                                value={checkedList}
                                onChange={this.onChangeCb}
                            />
                        </>
                    </Form.Item>



                    <Form.Item
                        label="Max Ödül Tutarı"
                        help="Kazanılabilecek en fazla kupon değeri.(örneğin 100 ₺ dediğinizde, tutan kupon değeri 2000 ₺ ise bu değer 100₺'ye yuvarlanır. Yani hiçbir kazanan kupon max ödül değerinden fazla olamaz.)"
                        required
                    >
                        <Input
                            onFocus={handleFocus}
                            value={maxGain}
                            onChange={(e) => this.onChange(e)}
                            type="number"
                            name="maxGain"
                            suffix={<span>₺</span>}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Minimum Kupon Tutarı"
                        help="Kupon tutarı bu değere ulaşmadığı taktirde kupon oluşturulamaz."
                        required
                    >
                        <Input
                            onFocus={handleFocus}
                            value={minCouponPrice}
                            onChange={(e) => this.onChange(e)}
                            type="number"
                            name="minCouponPrice"
                            suffix={<span>₺</span>}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Maksimum Kupon Tutarı"
                        help="Kupon tutarı bu değerden fazla olduğunda, kupon oluşturulamaz."
                        required
                    >
                        <Input
                            onFocus={handleFocus}
                            value={maxCouponPrice}
                            onChange={(e) => this.onChange(e)}
                            type="number"
                            name="maxCouponPrice"
                            suffix={<span>₺</span>}
                        />
                    </Form.Item>


                </Form>


                {
                    page != "createUser"
                    &&
                    <div
                        style={{
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 0',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => this.handleCreate()} type="primary">
                            Kaydet
                       </Button>
                    </div>

                }





            </div>

        return (
            <div>
                {
                    page === "createUser"
                        ?
                        renderBody
                        :
                        <>
                            <NavbarPanel page="systemSettings" />
                            <LoadingGlobal loading={loading} />
                            <Card
                                loading={loading}
                                title={
                                    <span>
                                        Sistem Ayarları
                            {
                                            `  (${!this.state.user ? "Geçerli ayarlar" : _.get(this.state, "user.username", "") + " isimli kullanıcının ayarları"}) `
                                        }

                                        {
                                            this.state.user
                                            &&
                                            <Button
                                                onClick={() => this.setState({ user: null }, () => { this.initSettings() })}
                                                size="small"
                                                icon={<CloseOutlined />} shape="circle-outline" type="danger" />
                                        }
                                    </span>
                                }
                                extra={<span>
                                    <Select
                                        style={{ minWidth: 200 }}
                                        // showSearch={true}
                                        className={"w100"} value={_.get(this.state, "user._id", "all")} onChange={(e) => this.onChangeSelect(e, "user")} >
                                        <Option value={"all"}>Tüm Kullanıcılar</Option>
                                        {
                                            users.map((v, i) => {
                                                return (
                                                    <Option key={i} value={v._id}>{v.username}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </span>}
                            >
                                {renderBody}
                            </Card>
                        </>
                }




            </div >
        )
    }
}
