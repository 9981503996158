import axios from "axios";
import { API_URL, testtoken, cdnConfig } from "../config";

const FormData = require('form-data')



export default class RoomServices {
    constructor(token = testtoken) {
        this.axiosConfig = { headers: { Authorization: token, 'Content-Type': 'application/json' } }
    }

    getHomeRooms(limit = 100, offset = 0) {
        const url = `${API_URL}/rooms?limit=${limit}&offset=${offset}`;
        return axios.get(url, this.axiosConfig)
    }

    deleteRoom(id) {
        const url = `${API_URL}/rooms/delete`;
        axios.post(url, { _id: id }, this.axiosConfig)
    }

    createRoom(data) {
        const url = `${API_URL}/rooms`;
        return axios.post(url, data, this.axiosConfig)
    }

    updateRoom(id, data) {
        const url = `${API_URL}/rooms/update/${id}`;
        return axios.post(url, data, this.axiosConfig)
    }

    getRoom(id) {
        const url = `${API_URL}/rooms/${id}`;
        return axios.get(url, this.axiosConfig)
    }
    getRoomDetail(id) {
        const url = `${API_URL}/room-detail/${id}`;
        return axios.get(url, this.axiosConfig)
    }

    postRoomImage(photo) {
        const url = `${cdnConfig.full_url}`;
        const formData = new FormData();
        formData.append('token', cdnConfig.token);
        formData.append('key', cdnConfig.key);
        formData.append('subkey', "room");
        formData.append('file', photo);
        const headers = { headers: { 'Content-Type': 'multipart/form-data' } }
        return axios.post(url, formData, headers)
    }

    getRoomItem(id, password) {
        const url = `${API_URL}/v1/room-detail/${id}${password ? `?password=${password}` : ""}`;
        return axios.get(url, this.axiosConfig)
    }



}

/*
formData.append('file', {
    name: photo.fileName,
    type: photo.type,
    uri:
        Platform.OS === "android" ? photo.uri : photo.uri.replace("file://", "")
});
*/