
import React, { Component } from 'react';
import {
    message,
    Table,
    Select,
    Drawer,
    Switch,
    Row,
    Button,
    Input,
    Form,
    Modal,
    Popconfirm,
    Tooltip,
    notification,
    Radio,
    Col,
    Divider,
    Card,
    Upload,
    Avatar,
    Typography,
    Tag,
    Slider,
    InputNumber,
    Badge,
    Skeleton,
    Checkbox,
    Alert,

} from 'antd';

import {
    PlusOutlined,
    DeleteOutlined,
    ReloadOutlined,
    InfoOutlined,
    CheckOutlined,
    CloseOutlined,
    EyeOutlined,
    EyeInvisibleOutlined
} from '@ant-design/icons';


import { inject, observer } from 'mobx-react';

import moment from 'moment'

import axios from "axios"

import _ from "lodash"
import MyPageHeader from '../components/MyPageHeader';


const { TextArea } = Input;


@inject('UserStore', 'AdminStore')
@observer
export default class PushNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            title: "",
            description: ""
        }
    }

    componentDidMount = async () => {

    }

    resetState() {
        this.setState({
            title: '',
            description: '',
        })
    }

    //#ekle
    handleCreate = async () => {

        let toServerData = {
            title: this.state.title,
            description: this.state.description
        }
        if (!toServerData.title) return

        try {
            await this.props.AdminStore.sendNotification(toServerData)
            message.success()
            this.resetState()
        } catch (error) {
            console.log({ error })
        }

    }


    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }


    render() {

        const handleFocus = (event) => event.target.select();

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16, offset: 0 },
            },
        };





        return (
            <div>
                <MyPageHeader title={"Bildirim Gönder"} />
                <Alert message="Bildirim; bütün kullanıcılara gönderilecektir. Emin değilseniz bu bölümü kullanmayınız.!" type="warning" />
                <div style={{ height: 50 }} />
                <Form {...formItemLayout}>
                    <Form.Item
                        label="Başlık"
                    >
                        <Input
                            onFocus={handleFocus}
                            value={this.state.title}
                            onChange={(e) => this.onChange(e)}
                            name="title"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Açıklama"
                    >
                        <TextArea
                            onFocus={handleFocus}
                            onChange={(e) => this.setState({ description: e.target.value })}
                            name="description"
                            value={this.state.description}
                            autosize={{ minRows: 2, maxRows: 6 }}
                        />

                    </Form.Item>

                    <Form.Item
                        label={`Bütün kullanıcılar için`}
                    >  <Button onClick={() => this.handleCreate()} type="primary">
                            Gönder
                        </Button>
                    </Form.Item>
                </Form>






            </div >
        )

    }
}
