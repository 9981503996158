import React, { Component } from 'react'
import { Spin } from 'antd';

export default () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'rgba(0,0,0,0.1)' }}>
            <Spin size="large" />
        </div>
    )
}

