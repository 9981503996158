import React, { useState, useEffect } from 'react';
import ConversationSearch from '../ConversationSearch';
import ConversationListItem from '../ConversationListItem';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';

import { Link } from 'react-router-dom';

import { Button } from 'antd';

import './ConversationList.css';
import Loading from '../../Loading';
import { inject, observer } from 'mobx-react';
import { defaultImages } from '../../../config';

@inject('ChatStore', 'UserStore')
@observer
export default class ConversationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ''
    }
  }
  componentDidMount = async () => {
    await this.props.ChatStore.setConversations();

    const userId = _.get(this.props, "userId");
    if (userId) {
      this.props.ChatStore.onClickConversationItemByUserId(userId)
    }

  }


  render() {
    let { conversations } = this.props.ChatStore
    const { loading } = this.props.ChatStore
    const { selectedConversationItem } = this.props.ChatStore

    const onclickConversation = (item) => {
      //this.props.ChatStore.setConversationItem(item)
      this.props.ChatStore.onClickConversationItem(item)
    }

    let filteredItems;
    if (this.state.search) {
      filteredItems = conversations.filter(v => {
        return _.get(v, "user.name", "").toLowerCase().indexOf(this.state.search) !== -1;
      })
      conversations = filteredItems
    }
    else {
      conversations = conversations
    }


    return (
      <div className="conversation-list">
        {loading ? <Loading />
          :
          <>
            <Toolbar
              title="Mesajlar"
              leftItems={[
                <Link to={`/user/${_.get(this.props, "UserStore.user._id")}`}
                  title={_.get(this.props, "UserStore.user.name", "")}

                >
                  <img
                    key={"image"}
                    alt={_.get(this.props, "UserStore.user.name", "")}
                    className="conversation-photo"
                    src={_.get(this.props, "UserStore.user.avatar", null) ? _.get(this.props, "UserStore.user.avatar") : defaultImages.userAvatar} />
                </Link>

              ]}
              rightItems={[
                <Link key="add" to="/search">
                  <ToolbarButton icon="ion-ios-add-circle-outline" />
                </Link>

              ]}
            />
            <ConversationSearch onChange={(e) => this.setState({ search: e.target.value })} placeholder="Mesajlarda ara.." />
            {
              !loading && conversations.length
                ?
                conversations.map((conversation, i) =>
                  <ConversationListItem
                    onClick={(item) => onclickConversation(item)}
                    key={i}
                    selectedConversationItem={selectedConversationItem}
                    data={conversation}
                  />
                )
                :
                <div style={{ marginTop: 10, padding: 10 }}>
                  <p style={{ textAlign: 'center' }}>Konuşma bulunmadı</p>
                  <Link key="add" to="/search">
                    <Button block type="primary" shape="round">
                      HEMEN OLUŞTUR
                    </Button>
                  </Link>
                </div>
            }
          </>
        }
      </div>
    )
  }
}
