
import React from 'react';
import ConversationList from '../components/messenger/ConversationList';
import MessageList from '../components/messenger/MessageList';
import '../components/messenger/Messenger/Messenger.css';

import { Helmet } from "react-helmet";

export default (props) => {
    return (
        <div className="messenger" >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mesajlar</title>
                <meta name="description" content="Chat" />
            </Helmet>
            <div className="scrollable sidebar">
                <ConversationList userId={_.get(props, "match.params.id")} />
            </div>

            <div className="scrollable content">

                <MessageList />
            </div>
        </div >
    );
}






/*




import React from 'react';
import ConversationList from '../components/messenger/ConversationList';
import MessageList from '../components/messenger/MessageList';
import '../components/messenger/Messenger/Messenger.css';

import { inject, observer } from 'mobx-react';

@inject('UserStore', 'ChatStore')
@observer
export default class Messenger extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            search: '',
            activeTabsKey: 'user',
            loading: false,
            hasMore: true,
            limit: 20,
            offset: 0
        }
    }


    componentDidMount() {
        // this.props.ChatStore.setConversations();

        const userId = _.get(this.props, "match.params.id");
        console.log("-------Messenger PAGE--------", { userId })
        if (userId) {
            //  onclickConversation({ _id: userId })
        }

    }


    render() {

        return (
            <div className="messenger">


                <div className="scrollable sidebar">
                    <ConversationList userId={_.get(this.props, "match.params.id")} />
                </div>

                <div className="scrollable content">

                    <MessageList />
                </div>
            </div>
        );
    }

}







  <MessageList
                        selectedConversationItem={_.get(this.props.ChatStore, "selectedConversationItem")}
                        messages={this.props.ChatStore.messages}
                        loadingMessages={this.props.ChatStore.loadingMessages}
                        typing={this.props.ChatStore.typing}
                        onChangeTyping={this.props.ChatStore.onChangeTyping}
                        onPressSend={() => this.props.ChatStore.sendMessage()}
                    />


import React from 'react';
import MessengerCom from '../components/messenger/Messenger';

export default function Messenger(props) {
    //{ navigation }     const userId = _.get(this.props, "match.params.id");
    const userId = _.get(props, "match.params.id");
    console.log("-------Messenger--------", { userId })

    return (
        <div>
            <MessengerCom />
        </div>
    );
}
*/