
import React, { Component } from 'react';
import {
    Table,
    Tooltip,
    Badge,
    Skeleton,
    Popconfirm,
    Button,
    Tag
} from 'antd';

import { inject, observer } from 'mobx-react';
import moment from 'moment'
import _ from "lodash"

import { Link } from "react-router-dom";
import MyPageHeader from '../components/MyPageHeader';

const queryString = require('query-string');


@inject('AdminStore', 'UserStore')
@observer
export default class Feedback extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = async () => {
        this.getItems()
    }

    //#liste
    getItems = async () => {
        this.props.AdminStore.getFeedback();
    }


    //sil
    delete = (item) => {
        this.props.AdminStore.deleteFeedback(item._id)
    }


    render() {
        const { loading } = this.props.AdminStore
        const data = this.props.AdminStore.feedbackList;// []// this.props.UserStore.users


        const columns = [
            {
                title: 'Kullanıcı',
                key: 'username',
                render: (text, record) => <span>
                    <Badge status={record.online ? "success" : undefined}>
                        <Link to={`/user/${_.get(record, "userId")}`}>
                            {_.get(record, "user.username", "")}
                        </Link>
                    </Badge>

                    {
                        record.type == "report"
                        &&
                        <div>
                            <Link style={{ color: 'red' }} to={`/user/${_.get(record, "reportUser")}`}>
                                görüntüle
                            </Link>
                        </div>
                    }


                </span>
            },
            {
                title: 'Konu',
                key: 'title',
                render: (text, record) => <span>
                    {record.title}
                    {` `}
                    {record.type == "report" && <Tag color="red">ŞİKAYET</Tag>}
                </span>
            },
            {
                title: 'Mesaj',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Oluşturma',
                key: 'created',
                render: (text, record) => <Tooltip title={new Date(record.created).toLocaleDateString()}>
                    <span > {moment(record.created).format("DD.MM.YYYY")} ({moment(record.created).fromNow()})</span>

                </Tooltip>
            },
            {
                title: ' ',
                key: 'action',
                render: (text, record) => <span>
                    <Popconfirm okText="Evet" cancelText="Hayır" title="Silmek istediğinize emin misiniz ?" onConfirm={() => this.delete(record)}>
                        <Button size='small' type='danger'>
                            Sil
                        </Button>
                    </Popconfirm>
                </span>
            },
        ];


        return (
            <div>
                <MyPageHeader title="Geri Bildirimler" subTitle={data.length} />
                <Skeleton loading={loading}>
                    <Table
                        bordered={true}
                        rowKey={'_id'}
                        columns={columns}
                        pagination={false}
                        size="small"
                        dataSource={data}
                        loading={loading}
                    />
                </Skeleton>
            </div >
        )
    }
}
