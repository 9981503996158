
import { observable, action, computed, toJS, autorun, runInAction, intercept } from 'mobx';

import _ from "lodash"


import axios from 'axios';

import { API_URL } from '../config'



export default class Order {

    constructor() {
        //    this.init();
        autorun(() => {
            //  this.init();
            //#	console.log(`User token : ${this.token}  `);
            this.data = this.data
        })
    }

    @observable loading = false
    @observable data = []
    @observable newNotification = ''
    @observable isChanged = false



    //yeni sipariş geldiğinde (socket için)
    @action async pushOrder(item) {
        this.data.push(item)
        this.data = _.orderBy(this.data, ['created'], ['desc'])
        return item
    }

    //yeni sipariş geldiğinde (socket için)
    @action async deleteOrderTemp(item) {
        const findedIndex = _.find(this.data, (o) => {
            return o.id = "temp"
        })
        this.data.splice(findedIndex, 1);
        //  this.data = _.orderBy(this.data, ['created'], ['desc'])
        return item
    }


    //#liste
    @action async getItemList() {
        //  this.init();
        //  console.log("getItemList!!!!!!")
        //eğer state de data varsa getirme
        if (this.data.length > 0) return
        try {
            //   let serverData = await this.get();
            const url = `${API_URL}/order`;
            axios.get(url, this.getHeader()).then((res) => {
                const items = _.get(res, "data")
                // this.data = items

                runInAction(() => {
                    //  Object.assign(store.observableObject, response.data);
                    this.data = items
                });

                console.log("Order store getItemList", items)
            })

        } catch (error) {
            console.log(error)
        }
    }


    @action async getItemListAll(query="") {
            const url = `${API_URL}/order?${query}`;
           return axios.get(url, this.getHeader())
    }

    //qr id ye göre silme işlemi
    @action async deleteOrderByQrId(qrId) {
        /*
                const filtered = _.filter(this.data, (o) => {
                    return o.qrId = qrId
                })
                filtered.map((v, i) => {
                    const fIndex = _.findIndex(filtered, (o) => {
                        return o._id = v._id
                    })
                    this.data.splice(fIndex, 1);
                })
                */
        const fIndex = _.findIndex(filtered, (o) => {
            return o._id = v._id
        })
        this.data.splice(0, 1);
        this.data = this.data
        return
    }

    //id ye göre sil
    @action async deleteOrderById(_id) {
        const fIndex = _.findIndex(this.data, { _id: _id })
        this.data.splice(fIndex, 1);
        this.data = this.data
        return this.data
    }

    //sipariş tamamlandı
    @action async setCompleted(_id, qrId) {
        const finded = _.find(this.data, (o) => {
            return o._id == _id
        })
        if (finded) {
            finded.completed = true
        }

        return
    }

    //sipariş durumunu güncelleme işlemi
    @action async onChangeStatus({ e, item, type }) {

        //  item = toJS(item)
        const finded = _.find(this.data, (o) => {
            return o._id == item._id
        })
        console.log("onChangeStatus", finded)
        if (finded) {
            finded[type] = e

            // this.data=this.data

            //server işlemi
            /*
            this.onChangeStatusServer({e,item,type}).then((res)=>{
                console.log("başarılı onChangeStatusServer",res)
            }).catch((err)=>{
                console.error(err)
            })
            */
        }
        return

    }

    //sipariş durum server
    @action async onChangeStatusServer(data) {
        const url = `${API_URL}/order/status`;
        return axios.post(url, data, this.getHeader())
    }

    //sipariş KAPAT server
    @action async setCompletedServer(qrId) {
        const url = `${API_URL}/order/completed`;
        return axios.post(url, { qrId }, this.getHeader())
    }


    //siparişleri getir
    @action async get() {
        //   let url = `${API_URL}/order`;
        const url = `${API_URL}/order`;
        return axios.get(url, this.getHeader());

    }

    @computed get getThisData() {
        return this.data
    }

    //siparişteki toplam tutarı getir
    @action getBasketTotalSum(items) {
        let totalPrice = _.sumBy(items, (o) => {
            return o.price * o.count;
        })
        return totalPrice
    }


    //garson çağırma yaşandığında ilgili qr kod durumunu güncelle
    @action async setIsCallStatus(qrId, isCall = true) {
        const finded = _.find(this.data, { qrId: qrId })
        console.log("Order Store : setIsCallStatus", { qrId, isCall, finded })
        if (finded) {
            finded.isCall = isCall

            finded.id = new Date().getTime()

            //   abc.push({})
            //  this.isChanged=!this.isChanged
            //  this.data.splice(0, 1);
            //   Object.assign(finded,{isCall:isCall})
            runInAction(() => {
                this.data = this.data
            })

            console.log("finded", toJS(this.data))
        }

        //   return this.data
        /*

      //  this.data=[]
        
        //  this.init()
        //    const finded = _.find(this.getThisData,{qrId:qrId})
        const myJs = toJS(this.data)
        const finded = _.find(myJs, (o) => {
            return o.qrId == qrId
        })
        console.log("setIsCallStatus", {
            qrId, finded, data: this.data, myJs,
            length: this.data.length
        })
        if (finded) {
            finded.isCall = isCall
            this.data = this.data
        }
        return this.data
        */

    }





    // TOKEN İŞLEMLERİ


    @action async init() {
        const token = localStorage.getItem('token');
        this.token = token;
        if (this.data.length == 0) {
            this.getItemList()
        }
        return token;

    }

    getHeader() {
        return { headers: { Authorization: localStorage.getItem('token'), 'Content-Type': 'application/json' } }
    }


}
